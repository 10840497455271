import React from "react";
import { Table, Alert } from "antd";

const EmployeeLocationTable = ({ locations }) => {
  const columns = [
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
    },
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      render: (text) => text || "N/A",
    },
  ];

  return locations.length > 0 ? (
    <Table
      columns={columns}
      dataSource={locations}
      rowKey="id"
      pagination={{ pageSize: 5 }}
    />
  ) : (
    <Alert
      message="No location data available for the selected date"
      type="warning"
      showIcon
    />
  );
};

export default EmployeeLocationTable;
