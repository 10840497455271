import React from "react";
import { Card, Button } from "react-bootstrap";

const EmployeeCard = ({ employee, onClick }) => (
  <Card className="mb-3" onClick={() => onClick(employee)}>
    <Card.Body>
      <Card.Title>
        {employee.first_name} {employee.last_name}
      </Card.Title>
    </Card.Body>
  </Card>
);

export default EmployeeCard;
