import React from "react";
import {
  convertIntoHRMMSS,
  convertIntoHRMMSSOverTime,
  formatDate2,
  formatDate3,
  formatDateFullMonth,
  formatDateToFullDateString,
  getTimeInAMPMFormat,
  isDateMatch,
} from "../../validation";
import moment from "moment";
import { formatTo12HourTime } from "../../utils/formatDateInTimezone";
import axios from "axios";
import config from "../../config";
import { capitalizeFirstLetter } from "../../utils/basicFunctions";
import { companyID } from "../../auth";

const AttendenceModelPopup = ({
  activityTransformed,
  employeeAttendanceDetail,
  currentDate,
  activityDetail,
  colorCode,
  setActivityDetail,
  setEmployeeAttendanceDetail,
  setColorCode,
  setCurrentDate,
  setRenderAttendanceData,
  noDetails,
  setNoDetails,
  isHiddenApprovalButton
}) => {
  console.log(
    "cweudgewgfuy employeeAttendanceDetail",
    employeeAttendanceDetail
  );

  //   const parseDateTime = (dateTimeString) => new Date(dateTimeString.replace(' ', 'T'));
  //   let totalWorkTime = 0;

  //   let loginTime = null;

  //   for (let activity of activityDetail) {
  //     if (activity.activity_type === 'Login') {
  //         loginTime = parseDateTime(activity.activity_time);
  //     } else if (activity.activity_type === 'Logout' && loginTime) {
  //         const logoutTime = parseDateTime(activity.activity_time);
  //         totalWorkTime += logoutTime - loginTime;
  //         loginTime = null;
  //     }
  // }

  let formattedTotalHours = 0;
  let formattedOvertimeHours = 0;

  // Calculate total work time in milliseconds
  if (
    employeeAttendanceDetail.length > 0 &&
    employeeAttendanceDetail[0]?.punchOut
  ) {
    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return new Date(1970, 0, 1, hours, minutes, seconds); // Using a dummy date
    };

    const punchInTime = new Date(employeeAttendanceDetail[0]?.punchIn);
    const punchOutTime = new Date(employeeAttendanceDetail[0]?.punchOut);

    if (!isNaN(punchInTime.getTime()) && !isNaN(punchOutTime.getTime())) {
      // Only proceed if both times are valid
      const totalWorkTime = punchOutTime - punchInTime;

      let seconds = Math.floor((totalWorkTime / 1000) % 60);
      let minutes = Math.floor((totalWorkTime / (1000 * 60)) % 60);
      let hours = Math.floor((totalWorkTime / (1000 * 60 * 60)) % 24);

      let overtimeHours = 0;
      if (hours > 8) {
        overtimeHours = hours - 8;
        hours = 8;
      }

      const formatTime = (num) => num.toString().padStart(2, "0");
      formattedTotalHours = `${formatTime(hours)}:${formatTime(
        minutes
      )}:${formatTime(seconds)}`;
      formattedOvertimeHours = `${formatTime(overtimeHours)}`;
    } else {
      console.log("Invalid PunchIn or PunchOut time.");
    }
  } else {
    const punchInTime = new Date(employeeAttendanceDetail[0]?.punchIn);
    if (!isNaN(punchInTime.getTime())) {
      // Check if punchInTime is valid
      const punchInDate = punchInTime.toISOString().split("T")[0]; // Get punchIn date in YYYY-MM-DD format
      const currentDate = new Date().toISOString().split("T")[0]; // Get today's date

      if (
        !employeeAttendanceDetail[0]?.punchOut &&
        punchInDate !== currentDate
      ) {
        formattedTotalHours = "00:00:00";
        formattedOvertimeHours = "00"; // Overtime is also zero
      } else {
        // Case when punchOut is missing but punchIn is today
        const punchOutTime = new Date(); // Current time (no punchOut time)
        const totalWorkTime = punchOutTime - punchInTime;

        let seconds = Math.floor((totalWorkTime / 1000) % 60);
        let minutes = Math.floor((totalWorkTime / (1000 * 60)) % 60);
        let hours = Math.floor((totalWorkTime / (1000 * 60 * 60)) % 24);

        let overtimeHours = 0;
        if (hours > 8) {
          overtimeHours = hours - 8;
        }

        const formatTime = (num) => num.toString().padStart(2, "0");
        formattedTotalHours = `${formatTime(hours)}:${formatTime(
          minutes
        )}:${formatTime(seconds)}`;
        formattedOvertimeHours = `${formatTime(overtimeHours)}`;
      }
    } else {
      console.log("Invalid PunchIn time.");
      formattedTotalHours = "00:00:00";
      formattedOvertimeHours = "00";
    }
  }

  console.log("formated hours", formattedTotalHours);
  // const totalWorkTime =0;

  // Convert total work time from milliseconds to hours, minutes, and seconds

  const employeeAttendanceHandler = async (date, employee_id) => {
    setColorCode("#2aad40");
    setCurrentDate(date);
    const response = await axios.get(
      `${config.API_BASE_URL}api/attendance/attendace/${employee_id}/${date}`
    );
    if (response?.data?.result) {
      // fix me ----
      setEmployeeAttendanceDetail({...response?.data?.result });
    }
    if (response?.data?.results) {
      console.log("activity detail s  2", response?.data?.results);
      setActivityDetail(response?.data?.results);
    }
  };

  const handleFullAttendanceApproval = (date, employee_id ,first_name ,last_name,marked_id) => {
    console.log("revoke", date, employee_id ,first_name,last_name, marked_id );
    const company_id = companyID();
    const approved_by = localStorage.getItem("employeeID");
    if (employee_id === undefined && isNaN(date) && marked_id === undefined ) {
      console.log("No Data found");
      //----------- mark attendance when it is absent -------------------
      const employee_ID = noDetails[1];
      const noDetail_date = noDetails[0];
      
      axios
        .post(
          `${config.API_BASE_URL}api/attendance/attendace/${employee_ID}/${noDetail_date}/${company_id}/${approved_by}`
        )
        .then((res) => {
          console.log("response from approval attendance", res);
          employeeAttendanceHandler(noDetail_date, employee_ID);
          setRenderAttendanceData(true);
          setNoDetails([noDetail_date,employee_ID , first_name ,last_name ]);
        })
        .catch((err) => console.log(err));
    } else if(marked_id == 1 && employee_id && date){
      //--------------- for revoke attendance -----
      axios
      .put(
        `${config.API_BASE_URL}api/attendance/attendace-revoke/${employee_id}/${date}/${company_id}/${approved_by}`
      )
      .then((res) => {
        console.log("response from revoke attendance", res);
        employeeAttendanceHandler(date, employee_id);
        setRenderAttendanceData(true);
        setNoDetails([date,employee_id , first_name ,last_name ]);
      })
      .catch((err) => console.log(err));
    }
    else {
      //----------- mark attendance when it is present but can not done whole day work ----------------
      axios
        .put(
          `${config.API_BASE_URL}api/attendance/attendace/${employee_id}/${date}/${company_id}/${approved_by}`
        )
        .then((res) => {
          console.log("response from approval attendance", res);
          employeeAttendanceHandler(date, employee_id);
          setRenderAttendanceData(true);
          setNoDetails([date , employee_id, first_name ,last_name]);
        })
        .catch((err) => console.log(err));
    }
  };

  console.log("employmentDAta" ,isDateMatch(noDetails[0]));

  console.log("employmentDAta" ,(noDetails[0]));

  function calculateEmployeeTotalHours(punchIn, punchOut) {
    const punchInTime = new Date(punchIn);
    const punchOutTime = new Date(punchOut);
  
    const diffBwPunchInAndPunchOut = punchOutTime - punchInTime;
  
    if (diffBwPunchInAndPunchOut < 0) return '00:00:00';
  
    const hours = String(Math.floor(diffBwPunchInAndPunchOut / (1000 * 60 * 60))).padStart(2, '0');
    const minutes = String(Math.floor((diffBwPunchInAndPunchOut % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
    const seconds = String(Math.floor((diffBwPunchInAndPunchOut % (1000 * 60)) / 1000)).padStart(2, '0');
  
    return `${hours}:${minutes}:${seconds}`;
  }
  

  return (
    <>
      <div
        className="modal custom-modal fade"
        id="attendance_info"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Attendance Detail of{" "}
                {employeeAttendanceDetail[0]?.date
                  ? formatDate2(employeeAttendanceDetail[0]?.date)
                  : noDetails.length > 0
                  ? noDetails[0]
                  : "No Data Found"}{" "}
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setNoDetails([]);
                  setRenderAttendanceData(true);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              {/* {(employeeAttendanceDetail[0]?.marked_full_day_attendance == 1) && ( */}
             {(isHiddenApprovalButton == false && (!isDateMatch(noDetails[0]) || noDetails[0]=== undefined) ) &&(
               <div
               className="mb-2"
               style={{
                 display: "flex",
                 justifyContent: "flex-start",
                 alignItems: "center",
               }}
             >
               <h5 style={{color:employeeAttendanceDetail[0]?.marked_full_day_attendance === 1
                     ? "#508D4E"
                     : "#FF7D29"}}>
                 {employeeAttendanceDetail[0]?.marked_full_day_attendance !==
                   1 && "Mark"}{" "}
                 {noDetails[2]
                   ? capitalizeFirstLetter(noDetails[2])
                   : capitalizeFirstLetter(
                       employeeAttendanceDetail?.first_name
                     )}{" "}
                 {noDetails[3]
                   ? capitalizeFirstLetter(noDetails[3])
                   : capitalizeFirstLetter(
                       employeeAttendanceDetail?.last_name
                     )}
                 {"'s "}
                 {employeeAttendanceDetail[0]?.marked_full_day_attendance === 1
                   ? `Attendance is Approved`
                   : "Attendance as Present"}
               </h5>

              {isDateMatch(employeeAttendanceDetail[0]?.updated_at) && (
                 <button 
                 className="approved-by-reportees"
                 style={{
                   border: employeeAttendanceDetail[0]?.marked_full_day_attendance === 1
                     ? "1.7px solid #e0462b"
                     : "1.7px solid #2aad40",
                   width: 'auto',
                   marginLeft: "10px",
                   borderRadius: "10px",
                   color: employeeAttendanceDetail[0]?.marked_full_day_attendance === 1
                     ? "#e0462b"
                     : "#2aad40",
                   backgroundColor: "transparent",
                   fontSize:"12px"
                 }}
                 onClick={() =>
                   handleFullAttendanceApproval(
                     formatDate3(employeeAttendanceDetail[0]?.date),
                     employeeAttendanceDetail[0]?.employee_id,
                     noDetails[2]
                   ? capitalizeFirstLetter(noDetails[2])
                   : capitalizeFirstLetter(
                       employeeAttendanceDetail?.first_name
                     ), noDetails[3]
                     ? capitalizeFirstLetter(noDetails[3])
                     : capitalizeFirstLetter(
                         employeeAttendanceDetail?.last_name
                       ),
                       employeeAttendanceDetail[0]?.marked_full_day_attendance
                   )
                 }
               >
                 {employeeAttendanceDetail[0]?.marked_full_day_attendance === 1
                   ? `Revoke`
                   : "Approve"}
               </button>
              )}
                
               
              {/*  )} */}
             </div>
             )}

              {/* )} */}
              <div className="row">
                <div className="col-md-6">
                  <div className="card punch-status">
                    <div className="card-body">
                      {/* <h5 className="card-title">
                        Timesheet{" "}
                        <small className="text-muted">
                          {employeeAttendanceDetail[0]?.date &&
                            formatDate2(employeeAttendanceDetail[0]?.date)}
                        </small>
                      </h5> */}
                      <div className="punch-det">
                        <h6>Punch In at</h6>
                        <p>
                          {employeeAttendanceDetail[0]?.date
                            ? formatDateToFullDateString(
                                employeeAttendanceDetail[0]?.date
                              )
                            : noDetails.length > 0
                            ? noDetails[0]
                            : "No Data Found"}{" "}
                          {", "}
                          {employeeAttendanceDetail[0]?.punchIn
                            ? moment(
                                employeeAttendanceDetail[0]?.punchIn
                              ).format("hh:mm:ss A")
                            : "00:00:00 hrs"}
                        </p>
                      </div>
                      <div className="punch-info">
                        <p style={{textAlign:'center'}}>Total hours</p>
                        <div className="punch-hours">
                          <span>
                          {
                             employeeAttendanceDetail[0]?.punchIn && employeeAttendanceDetail[0]?.punchOut
                               ? calculateEmployeeTotalHours(employeeAttendanceDetail[0]?.punchIn,employeeAttendanceDetail[0]?.punchOut )
                               : employeeAttendanceDetail[0]?.punchIn
                               ? formattedTotalHours
                               : "00:00:00"
                           }

                            hrs
                          </span>
                        </div>
                      </div>
                      <div className="punch-det">
                        <h6>Punch Out at</h6>
                        <p>
                          {employeeAttendanceDetail[0]?.date
                            ? formatDateToFullDateString(
                                employeeAttendanceDetail[0]?.date
                              )
                            : noDetails.length > 0
                            ? noDetails[0]
                            : `No Data Found`}
                          {"  "}
                          {employeeAttendanceDetail[0]?.punchOut
                            ? moment(
                                employeeAttendanceDetail[0]?.punchOut
                              ).format("hh:mm A")
                            : ` , 00:00:00 hr`}
                        </p>
                      </div>
                      {/* <div className="statistics">
                        <div className="row">
                          <div className="col-md-6 col-6 text-center">
                            <div className="stats-box">
                              <p>Break</p>
                              <h6>1:00 hrs</h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 text-center">
                            <div className="stats-box">
                              <p>Overtime</p>
                              <h6>
                                {employeeAttendanceDetail[0]?.punchOut
                                  ? formattedOvertimeHours
                                  : "00:00:00"}
                                hrs
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card recent-activity">
                    <div className="card-body">
                      <h5 className="card-title">Activity</h5>
                      <ul className="res-activity-list">
                        {activityTransformed?.length > 0 ? (
                          activityTransformed[0]?.activityStatus.map(
                            (punch, index) => {
                              return (
                                <li key={index}>
                                  <p className="mb-0">
                                    {punch?.activity_type == "Login"
                                      ? "Punch In at"
                                      : "Punch Out at"}{" "}
                                  </p>
                                  <p className="res-activity-time">
                                    <i className="fa fa-clock-o" />
                                    {getTimeInAMPMFormat(punch.activity_time)}
                                  </p>
                                </li>
                              );
                            }
                          )
                        ) : (
                          <li> No Activity Found</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendenceModelPopup;
