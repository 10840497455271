import React, { useEffect, useState } from "react";
import { getData } from "../../../utils/api"; // Import your custom getData function
import Breadcrumbs from "../../../components/Breadcrumbs";
import AddandEditPolicy from "../../../components/modelpopup/AddandEditPolicy";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import "../../../assets/css/policies.css";
import config from "../../../config";
import GenerateDocument from "./GenerateDocument";
import Pdf from "../../../assets/img/icons/pdf.png";
import axios from "axios";
import { Table } from "antd";
import { Link, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/basicFunctions";
import DeleteModal from "../../../components/modelpopup/deletePopup";
import { companyID } from "../../../auth";

const MyDocuments = () => {
  const [documentData, setdocumentData] = useState([]);
  const [filtereddocumentData, setFiltereddocumentData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [basicDetails, setBasicsDetails] = useState({});
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [deletedID, setDeletedID] = useState(null);
  const [lengthDocumnet, setLengthDocument] = useState(0);
  const [countAllPolicy , setAllPolicityCount] = useState(0);
  const [documnetTypeData , setDocumentTypeData] = useState([]);
  const [documentType, setDocumnetType] = useState("");
  // useEffect(()=>{
  //   const updatedStatsData = statsData?.map((stat) => ({
  //     ...stat,
  //     active: stat.title === 'Requested Document',
  //     value :stat.title === 'Requested Document' ?lengthDocumnet:countAllPolicy

  //   }));
  //   setStatsData(updatedStatsData);
  // },[lengthDocumnet])

  useEffect(()=>{
    const fetchDocumnetTypes = async() =>{
      const company_id  = companyID();
      try{
        const response =  await axios.get(`${config.API_BASE_URL}api/documents/documnet-type/${company_id}`);
        console.log("reponse of documnet type" , response);
        if(response?.data?.success== true){
          setDocumentTypeData(response?.data?.result)
          setDocumnetType("");
        }
      }
      catch(err){
        console.log(err);
      }
    }
    fetchDocumnetTypes();
    },[])
  
const documnetedData = documnetTypeData?.map((item)=>{
  return ({
    id:item?.id,
    value:item?.document
  })
})

const id = localStorage.getItem("employeeID");
    let token1 = localStorage.getItem("token");
    const configuration1 = {
      headers: {
        Authorization: `Bearer ${token1}`,
      },
    };
    const fetchdocumentData = async () => {
      try {
        const res = await axios.get(
          `${config.API_BASE_URL}hr/my-documents/${id}`,
          configuration1
        );
        console.log("data response received -->", res);
        const filteredData = res?.data?.result.filter(
          (document) => document.status === 1
        );
        setdocumentData(filteredData);
        setFiltereddocumentData(filteredData);
        fetchRequestedDocument();

        // const userManualCount = filteredData.filter(policy => policy.policy_type === "User Manual").length;
        const allPoliciesCount = filteredData.length;
        setAllPolicityCount(allPoliciesCount);
        // const companyPoliciesCount = filteredData.filter(policy => policy.policy_type === "Company Policies").length;

        setStatsData([
          {
            id: 1,
            title: "All Documents",
            value: allPoliciesCount.toString(),
            active: true,
          },
          {
            id: 2,
            title: "Requested Document",
            value: lengthDocumnet.toString(),
            active: false,
          },
          // {
          //   id: 2,
          //   title: "User Manual",
          //   value: userManualCount.toString(),
          //   active: false,
          // },
          // {
          //   id: 3,
          //   title: "Company Policies",
          //   value: companyPoliciesCount.toString(),
          //   active: false,
          // },
        ]);
      } catch (error) {
        console.error("Error fetching policy data:", error);
      }
    };

  useEffect(() => {
    

    fetchdocumentData();
  }, []);

  const [requestedDocumnetState, setRequestedDocumentState] = useState(false);
  const filterPolicies = (policyType) => {
    console.log("click", policyType);
    if (policyType === "All Documents") {
      setRequestedDocumentState(false);
      fetchdocumentData();
      setFiltereddocumentData(documentData);
      
    } else if (policyType === "Requested Document") {
      setRequestedDocumentState(true);
      fetchRequestedDocument();
      console.log("requested documnet..");
    } else {
      console.log("this running..");
      const filteredPolicies = documentData.filter(
        (policy) => policy.policy_type === policyType
      );
      setFiltereddocumentData(filteredPolicies);
      setRequestedDocumentState(false);
    }

    const updatedStatsData = statsData?.map((stat) => ({
      ...stat,
      active: stat.title === policyType,

    }));
    setStatsData(updatedStatsData);
  };
  useEffect(()=>{
    
  })

  const handleDownload = async (filePath) => {
    try {
      const a = document.createElement("a");
      a.href = filePath;
      a.target = "_blank";
      a.download = "policy_document.jpg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // const openModal = (imageUrl) => {
  //   console.log("calling................");
  //   setModalImage(imageUrl);
  //   setShowModal(true);
  // };
  console.log("set show mail state-->", showModal);

  const employeeID = localStorage.getItem("employeeID");

  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}employees/${employeeID}`, configuration)
      .then((res) => {
        console.log("reponese in documeng ", res);
        setBasicsDetails(res?.data?.result?.basicDetails);
        setEmploymentDetails(res?.data?.result?.employmentDetails[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  //--------------------- requested documnet -----------------------

  const [requestedData, setRequestedData] = useState([]);
  const [editedDocumnet, setEditedDocumnet] = useState({});

  const fetchRequestedDocument = async () => {
    const employee_id = localStorage.getItem("employeeID");
    try {
      const request = await axios(
        `${config.API_BASE_URL}hr/requested-for-document/${employee_id}`
      );
      console.log("response policies", request);
      if (request?.data?.success == true) {
        setRequestedData(request?.data?.result);
        setEditedDocumnet({});
        //-------
        setLengthDocument(request?.data?.result?.length);
      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchRequestedDocument();
  }, []);
  const documentRequest = requestedData?.map((data, index) => {
    console.log("Nitesh : ", data?.status, data);
    return {
      id: index + 1,
      requested_document: data?.document_type,
      status: data?.status,
      employee_id: data?.employee_id,
      requested_document_id: data?.id,
      document_id: data?.document_id,
      visible: data?.visible,
      document: data?.document,
      document_value : data?.document_value
    };
  });


  console.log("editedDocumnet",editedDocumnet);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Requested document",
      dataIndex: "document_value",
      sorter: (a, b) =>
        a.document_value.length - b.document_value.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => (
        <span style={{ color: record == "Pending" ? "#ffb41b" : "#1b9928" }}>
          {record}
        </span>
      ),
    },
    {
      title: "Action",
      render: (record) => (
        <span style={{ cursor: "pointer" }}>
          {record.document ? (
            <span
              onClick={() =>
                handleDownload(`${config.IMAGE_URL}${record?.document}`)
              }
            >
              <i class="fa-solid fa-eye  text-primary"></i> <span>View</span>
            </span>
          ) : (
            <span className="d-flex align-item-center">
              <Link
                // className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete"
              >
                <i
                  className="fa fa-trash  text-danger "
                  onClick={() => setDeletedID(record.requested_document_id)}
                />
              </Link>
              <span
                style={{
                  marginLeft: "25px",
                  marginRight: "25px",
                  color: "#B5C0D0",
                }}
              >
                |
              </span>
              <Link
                className="dropdown-item"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  setEditedDocumnet({
                    employee_id: record.employee_id,
                    requested_id: record.requested_document_id,
                    requested_document: record.requested_document,
                  });
                }}
              >
                <i class="fa-solid fa-pen "></i>
              </Link>
            </span>
          )}
        </span>
      ),
    },
  ];



  const confirmDelete = async () => {
    if (deletedID) {
      try {
        const response = await axios.put(
          `${config.API_BASE_URL}hr/delete-requested-documnet/${deletedID}`
        );
        if (response?.data?.success == true) {
          console.log("deleted successully ");
          fetchRequestedDocument();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  console.log("editedDocumnet", editedDocumnet);

  const location = useLocation();
  const { modalOpen } = location.state || {};
 
  const [isModalOpen, setIsModalOpen] = useState(modalOpen||false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
    setEditedDocumnet({})
    setDocumnetType("");
    const dropDown = document.getElementById('document_dropdown');
    dropDown.value=''
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="My Documents"
            title="Dashboard"
            subtitle="My Documnets"
            // modal="#generate_document"
            name="Generate Document"
            page="my-document"
            action="create"
            openModal={openModal}
          />

          <div className="row">
            {statsData.map((stat) => (
              <div
                className={`col-md-4 d-flex`}
                key={stat.id}
                onClick={() => filterPolicies(stat.title)}
                style={{cursor:'pointer'}}
              >
                <div
                  className={`stats-info w-100 ${
                    stat.active ? "orange-bg text-white" : ""
                  }`}
                >
                  <h6 className={` ${stat.active ? "text-white" : ""}`}>{stat.title}</h6>
                  <h4  className={` ${stat.active ? "text-white" : ""}`}>{stat.title==="Requested Document" ?lengthDocumnet:stat.value}</h4>
                </div>
              </div>
            ))}
          </div>

          {!requestedDocumnetState &&
            filtereddocumentData.map((item) => (
              <div
                className={`card shadow p-3 mb-5 bg-white rounded  blue-left-border ${
                  item.status === 1 ? "orange-bg" : ""
                }`}
                key={item.id}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-1">
                      <img src={Pdf} alt="pdf icon" />
                    </div>
                    <div className="col-md-4">
                      <div className="card-text pt-2">
                        <div className="name">{item.document}</div>
                        {/* <div className="class mt-2 mb-2"><span className="p-1" style={{ backgroundColor: item.status === 1 ? 'orange' : 'initial' }}>{item.policy_type}</span></div> */}
                        <div className="date text-muted">
                          Last update on :{" "}
                          {formatDateInTimezone(item.created_at)}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Options"
                      >
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() =>
                            handleDownload(
                              `${config.IMAGE_URL}${item.document}`
                            )
                          }
                        >
                          <i className="fas fa-download"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() =>
                            handleDownload(
                              `${config.IMAGE_URL}${item.document}`
                            )
                          }
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {requestedDocumnetState && (
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <Table
                    className="table-striped"
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    dataSource={documentRequest}
                    rowKey={(record) => record?.id}
                    scroll={{ x: true }}
                    tableLayout="auto"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for viewing image */}
      {/* {showModal && (
        <div class="modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Modal body text goes here.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
      <GenerateDocument
        departmentID={employmentDetails?.department_id}
        employeeID={employeeID}
        editedDocumnet={editedDocumnet}
        fetchRequestedDocument={fetchRequestedDocument}
        documnetedData={documnetedData}
        setEditedDocumnet={setEditedDocumnet}
        documentType={documentType}
        setDocumnetType={setDocumnetType}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <DeleteModal Name="Delete Policy" confirmDelete={confirmDelete} />
      {isModalOpen && <div className="modal-backdrop fade show"></div>}

      {/* <AddandEditPolicy /> */}
    </>
  );
};

export default MyDocuments;
