import * as Yup from "yup";
const today = new Date();

const baseSchemas = {
  text: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Only letters and spaces are allowed")
    .required("This field is required"),

  email: Yup.string()
    .trim()
    // .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),

  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),

  // number: Yup.number()
  //   .typeError("Must be a number")
  //   .required("This field is required"),

  number: Yup.string() // Fixed
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("This field is required"),
  
  decimalNumber: Yup.string()
  // .transform((value) => {
  //   if (value) {
  //     value = value.replace(/^0+/, ''); 
  //     if (value.startsWith('.')) {
  //       value = '0' + value; 
  //     }
  //   }
  //   return value;
  // })
    .matches(/^[0-9.]+$/, "Must be only digits or a decimal")
    .required("This field is required"),

  address: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s\-',]+$/,
      "Invalid Address Details"
    )
    .min(5, "Address must be at least 5 characters")
    .required("Address is required"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),

  date: Yup.date()
    .required("Date is required")
    .typeError("Invalid date format"),
    // .test(
    //   "not-today",
    //   "Current date is not allowed",
    //   (value) => value && value.toDateString() !== today.toDateString()
    // )
    // .test(
    //   "not-current-year",
    //   "Dates in the current year are not allowed",
    //   (value) => value && value.getFullYear() !== today.getFullYear()
    // ),

  //   dropdown: Yup.string()
  //     .oneOf(["option1", "option2", "option3"], "Invalid selection")
  //     .required("Selection is required"),

  dropdown: Yup.string().required("Selection is required"),

  file: Yup.mixed()
    .required("File is required")
    .test(
      "fileSize",
      "File size is too large",
      (value) =>
        typeof value === "string" || (value && value.size <= 50 * 1024)
    )
    //---------------changes added----------------------
    .test(
      "fileFormat",
      "Unsupported file format. Only JPEG and PNG are allowed",
      (value) =>
        typeof value === "string" || 
        (value && ["image/jpeg", "image/png" , "application/pdf"].includes(value.type))
    )
    .test(
      "fileName",
      "Unsupported file format. Only JPEG and PNG are allowed",
      (value) => {
        if (typeof value === "string") {
          const validExtensions = [".jpeg", ".jpg", ".png", ".pdf"];
          const fileExtension = value.slice(value.lastIndexOf(".")).toLowerCase();
          return validExtensions.includes(fileExtension);
        }
        return true; 
      }
    ),
    //----------- new added---------------
    imageFile :
    Yup.mixed()
    .required("File is required")
    .test(
      "fileSize",
      "File size is too large",
      (value) =>
        typeof value === "string" || (value && value.size <= 50 * 1024)
    )
    .test(
      "fileFormat",
      "Unsupported file format. Only JPEG and PNG are allowed",
      (value) =>
        typeof value === "string" || 
        (value && ["image/jpeg", "image/png"].includes(value.type))
    )
    .test(
      "fileName",
      "Unsupported file format. Only JPEG and PNG are allowed",
      (value) => {
        if (typeof value === "string") {
          const validExtensions = [".jpeg", ".jpg", ".png"];
          const fileExtension = value.slice(value.lastIndexOf(".")).toLowerCase();
          return validExtensions.includes(fileExtension);
        }
        return true; 
      }
    ),
    // .test(
    //   "fileSize",
    //   "File size is too large",
    //   (value) => value && value.size <= 50 * 1024 
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported file format. Only JPEG and PNG are allowed",
    //   (value) =>
    //     value &&
    //     ["image/jpeg", "image/png"].includes(value.type)
    // ),

  alphanumeric: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Only letters, numbers, and spaces are allowed"
    )
    .required("This field is required"),

    paragraph: Yup.string()
    .required("This field is required"),
};

const createCustomSchema = (type, { min, max, message, oneOf } = {}) => {
  let schema = baseSchemas[type];

  if (!schema) {
    throw new Error(`Unsupported schema type: ${type}`);
  }

  if (min !== undefined) {
    schema = schema.min(min, `Minimum ${min} characters required`);
  }

  if (max !== undefined) {
    schema = schema.max(max, `Maximum ${max} characters allowed`);
  }

  if (message !== undefined) {
    schema = schema.required(message);
  }

  if (oneOf !== undefined && type === "dropdown") {
    schema = schema.oneOf(oneOf, "Invalid selection");
  }

  return schema;
};

export default createCustomSchema;
