import React, { useEffect, useState } from "react";
import "../../../../assets/css/employeeAddEditModal.css"
import DatePicker from "react-datepicker";
import Select from "react-select";
import axios from "axios";
import config from "./../../../../config";
import * as Yup from "yup";
import {
  validateEmail,
  validatePhoneNumber,
  validatorName,
  formatDate,
} from "../../../../validation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import createCustomSchema from "../../../../fieldValidator/fieldValidator";
import { useRefresh } from "../../../../context/RefreshContext";
import { validateDateFields } from "../../../../utils/validateDateFields";

const EditCandidatesModal = (props) => {
  const location = useLocation();
  console.log("location .path name", location.pathname);
  console.log("props ---->", props);
  // const { triggerRefresh } = useRefresh();
  const [closeModal, setCloseModal] = useState(false);
  // const [fieldOne, setFieldOne] = useState(true); // fix me 1:08
  const [fieldTwo, setFieldTwo] = useState();
  const [fieldThree, setFieldThree] = useState();
  // const [fieldFour, props.setFieldFour] = useState();
  const [fieldFive, setFieldFive] = useState();
  const [allEmployees, seAllEmployee] = useState([]);
  const [selectedReportee, setSelectedReportee] = useState("");
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const companyID = JSON.parse(user);
  const company_id = companyID.company.company_id;

  const validationSchema = Yup.object({
    first_name: createCustomSchema("text", {
      message: "Enter First Name!",
      min: 3,
    }),
    middle_name: createCustomSchema("text", {
      message: "Enter middle Name!",
      min: 3,
    }),
    last_name: createCustomSchema("text", {
      message: "Enter last Name!",
      min: 3,
    }),
    personal_email: createCustomSchema("email", {
      message: "Enter Personal Email!",
    }),
    official_email: createCustomSchema("email", {
      message: "Enter Official Email!",
    }),
    alternate_number: createCustomSchema("phone", {
      message: "Enter Alternate Number!",
    }),
    number: createCustomSchema("phone", {
      message: "Enter Phone Number!",
    }),
    guardian_name: createCustomSchema("text", {
      message: "Enter Guardiuan Name!",
      min: 3,
    }),
    date_of_birth: createCustomSchema("date", {
      message: "Date of Birth is Required!",
    }),
    marital_status: createCustomSchema("dropdown", {
      message: "Select Marital Status!",
    }),
    married_date: createCustomSchema("date", {
      message: "Married Date is Required!",
    }),
    spouse_name: createCustomSchema("text", {
      message: "Select spouse Name!",
    }),
    nationality: createCustomSchema("dropdown", {
      message: "Select Nationality!",
    }),
    gender: createCustomSchema("dropdown", {
      message: "Select Gender !",
    }),
    aadhar: createCustomSchema("file", { message: "Select  Aadhar !" }),
    pan: createCustomSchema("file", { message: "Select Pan !" }),
    experience_letter: createCustomSchema("file", {
      message: "Select Experience Letter!",
    }),
    resignation_letter: createCustomSchema("file", {
      message: "Select Reileiving Letter!",
    }),
    photo: createCustomSchema("imageFile", { message: "Select Photo !" }),
  });

  const validateAddress = Yup.object({
    current_address: createCustomSchema("address"),
    current_city:  createCustomSchema("dropdown", {
      message: "Please Select a City !",
    }),
    current_state:  createCustomSchema("dropdown", {
      message: "Please Select a State !",
    }),
    current_country: createCustomSchema("dropdown", {
      message: "Please Select a Country !",
    }),
    current_pincode: createCustomSchema("number", {
      message: "Maximun 6 digit Allowed!",
      max: 6,
    }),
    permanent_address: createCustomSchema("text"),
    permanent_city: createCustomSchema("dropdown", {
      message: "Please Select a City !",
    }),
    permanent_state:  createCustomSchema("dropdown", {
      message: "Please Select a State !",
    }),
    permanent_country: createCustomSchema("dropdown", {
      message: "Please Select a Country !",
    }),
    permanent_pincode: createCustomSchema("number", {
      message: "Maximun 6 digit Allowed!",
      max: 6,
    }),
  });

  const validateAccountDetail = Yup.object({
    account_number: createCustomSchema("number", { max: 16, min: 11 ,message :"Please Enter Account Number !" }),
    ifsc_code: createCustomSchema("alphanumeric", { max: 11, min: 11 , message :"Please Enter IFSC Code !" }),
    account_holder_name: createCustomSchema("text",{min:3 , message:"Please Enter Account Holder Name !"}),
    branch_name: createCustomSchema("text",{min:3 , message :"Please Enter Branch Name !" }),
    uan_number: createCustomSchema("number" , {max : 16 , min: 16 , message :"Please Enter UAN Number!"}),
    bank_name: createCustomSchema("text" ,{min:3, message : "Please Enter Bank Name!"}),
    passbook_photo: createCustomSchema("imageFile" , {message :"Please Upload Passbook Photo!" }),
  });

  const validateQualification = Yup.object({
    qualification: createCustomSchema("text", {
      message: "Qualification is Required!",
      min:3,
    }),
    university: createCustomSchema("text", {
      message: "University/Board is Required!",
      min:3,
    }),
    passing_year: createCustomSchema("number", { max: 4 }),
    percentage: createCustomSchema("decimalNumber" , {message :"Please Enter Percentage !"}),
    // document: createCustomSchema("number", {message : "Maximun 6 digit Allowed!" ,  max: 6 }),
  });

  const validateEmploymentDetail = Yup.object({
    experience: createCustomSchema("decimalNumber", {
      message: "Experience  is Required!",
    }),
    joining_date: createCustomSchema("date", {
      message: "*Joining Date is Required!",
    }),
    slot: createCustomSchema("dropdown"),
    department_id: createCustomSchema("dropdown"),
    designation_id: createCustomSchema("dropdown"),
    salary: createCustomSchema("number", {
      message: "Salary is Required!",
      max: 10,
    }),
    // cv: createCustomSchema("file"),
  });

  const [basicsDetailsError, setBasicsDetailsError] = useState({});
  const [addressDetailsError, setAddressDetailError] = useState({});
  const [employmentDetailsError, setEmploymentDetailError] = useState({});

  let token = localStorage.getItem("token");
  const configuer = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const nationality = [
    { value: 1, label: "Indian" },
    { value: 2, label: "Albanian" },
    { value: 3, label: "Algerian" },
    { value: 4, label: "American" },
    { value: 5, label: "Andorran" },
    { value: 6, label: "Angolan" },
    { value: 7, label: "Antiguans" },
    { value: 8, label: "Argentinean" },
    { value: 9, label: "Armenian" },
    { value: 10, label: "Australian" },
    { value: 11, label: "Austrian" },
    { value: 12, label: "Azerbaijani" },
    { value: 13, label: "Bahamian" },
  ];

  const [nationalityLabel, setNationalityLabel] = useState("");
  const employeeDataHandler = () => {
    axios
      .get(`${config.API_BASE_URL}employees/`, configuer)
      .then((res) => props.setEmployeeData(res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("nationality--=>", nationalityLabel);

  

  const FieldsetTwo = () => {
    // if(employeeData.first_name && employeeData.last_name && employeeData.personal_email && employeeData.number && employeeData.gender && employeeData.guardian_name && employeeData.marital_status && employeeData.date_of_birth && employeeData.nationality ){
    //   setFieldTwo(true);
    //   setFieldOne(false);

    // }else{
    //   setFieldOne(true);
    //   setFieldTwo(false);
    // }

    setFieldTwo(true);
    props.setFieldOne(false);

    setFieldThree(false);
    props.setFieldFour(false);
    setFieldFive(false);
  };

  const FieldsetThree = () => {
    setFieldThree(true);
    setFieldTwo(false);
    props.setFieldOne(false);
    props.setFieldFour(false);
    setFieldFive(false);
  };
  const FieldsetFour = () => {
    setFieldThree(false);
    props.setFieldFour(true);
    props.setFieldOne(false);
    setFieldTwo(false);
    setFieldFive(false);
  };
  const FieldsetFive = () => {
    props.setFieldOne(false);
    setFieldTwo(false);
    setFieldThree(false);
    props.setFieldFour(false);
    setFieldFive(true);
  };
  const countrylist = [
    // { value: 1, label: "Select Country" },
    { value: 1, label: "India" },
    { value: 2, label: "China" },
    { value: 3, label: "America" },
    { value: 4, label: "Japan" },
  ];
  const gender = [
    // { value: 1, label: "Select Gender" },
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
  ];
  const slot = [
    // { value: 1, label: "Select slot" },
    { value: 1, label: "Morning Shift" },
    { value: 2, label: "Evening Shift" },
  ];
  const marital_status = [
    // { value: 1, label: "Select Status" },
    { value: 1, label: "Single" },
    { value: 2, label: "Married" },
    { value: 3, label: "Unmarried" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const [selectedDate1, setSelectedDate1] = useState(null);
  const [slotShift, setSlotShift] = useState(null);
  const [departmentID, setDepartmentID] = useState(null);
  const [designationID, setDesignationID] = useState(null);
  const [genderHandler, setGenderHandler] = useState(null);
  const [selectedDob, setSelectedDob] = useState(null);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [marriedDate, setMarrieddate] = useState(null);
  const [selectedCurrentCountry, setSelectedCurrentCountry] = useState(null);
  const [selectedPermanentCountry, setSelectedPermanentCountry] =
    useState(null);

  const handleDateChange1 = async (date) => {
    setSelectedDate1(formatDate(date));
    if(!validateDateFields(date)){
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        ["joining_date"]: "You cannot select Future date",
      }));
      return;
    }else{
      console.log("selected Date", selectedDate1);
      setEmployementData({
        ...employmentData,
        joining_date: formatDate(date),
      });
      const newEmploymentDetail = {
        ...employmentData,
        joining_date: formatDate(date),
      };
      console.log("selected Date", selectedDate1);
      try {
        await validateEmploymentDetail.validateAt(
          "joining_date",
          newEmploymentDetail
        );
        setEmploymentDetailError((prevErrors) => ({
          ...prevErrors,
          ["joining_date"]: "",
        }));
      } catch (err) {
        setEmploymentDetailError((prevErrors) => ({
          ...prevErrors,
          ["joining_date"]: err.message,
        }));
      }
    }
  };
  const [employeeData, setEmployeeData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    personal_email: "",
    official_email: "",
    number: "",
    alternate_number: "",
    guardian_name: "",
    date_of_birth: null,
    // gender: "",
    photo: null,
    company_id: "",
    marital_status: "",
    married_date: null,
    spouse_name: "",
    nationality: "",
    aadhar: null,
    pan: null,
    experience_letter: null,
    resignation_letter: null,
    created_by: "",
    updated_by: "",
  });
  const designationHandler = (id) => {
    console.log("id ->", id);
    axios
      .get(`${config.API_BASE_URL}department/designation/${id}`, configuer)
      .then((res) => {
        const designation = res.data.result.map((des) => ({
          value: des.id,
          label: des.designation_name,
        }));
        console.log(designation);
        setDesignation(designation);
      })
      .catch((err) => console.log(err));
  };

  

  const inputHandleChnage = async (e) => {
    const { name, value, files } = e.target;
    console.log("midddlename->" , name  ,value);
    let id = localStorage.getItem("employeeID");
    const newEmployeeDetail = {
      ...employeeData,
      [name]: files ? files[0] : value,
      date_of_birth: selectedDob ? selectedDob : employeeData.date_of_birth,
      company_id: company_id,
      marital_status: maritalStatus,
      married_date: marriedDate ? marriedDate : "",
      // created_by: id,
      updated_by: id,
    };
    setEmployeeData({
      ...employeeData,
      [name]: files ? files[0] : value,
      date_of_birth: selectedDob ? selectedDob : employeeData.date_of_birth,
      company_id: company_id,
      marital_status: maritalStatus,
      married_date: marriedDate ? marriedDate : "",
      // created_by: id,
      updated_by: id,
    });

    try {
      await validationSchema.validateAt(name, newEmployeeDetail);
      setBasicsDetailsError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      setBasicsDetailsError((prevErrors) => ({
        ...prevErrors,
        [name]: err.message,
      }));
    }
  };
  const configuration = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}department/${company_id}`, configuer)
      .then((res) => {
        const departments = res.data.result.map((dept) => ({
          value: dept.id,
          label: dept.department_name,
        }));
        setDepartment(departments);
      })
      .catch((err) => console.log(err));

    console.log(department);
  }, []);

  useEffect(() => {
    if (departmentID) {
      designationHandler(departmentID);
    }
  }, [departmentID]);

  useEffect(() => {
    if(departmentID && company_id){
      axios
      .get(`${config.API_BASE_URL}employees/reportees/${company_id}/${departmentID}`, configuer)
      .then((res) => seAllEmployee(res?.data?.result))
      .catch((err) => {
        console.log(err);
      });
    console.log("all employees ---.", allEmployees);
    }
  }, [departmentID]);

  //---------------------- address feild feild-2  handling--------------------------------
  const [addressData, setAddressData] = useState({
    employee_id: "",
    current_address: "",
    current_city: "",
    current_state: "",
    current_country: null,
    current_pincode: "",
    permanent_address: "",
    permanent_city: "",
    permanent_state: "",
    permanent_country: null,
    permanent_pincode: "",
    isSameAddress: false,
  });

  const onChangeValidator = async (name, value) => {
    const newAddress = {
      ...addressData,
      [name]: value,
    };
    try {
      await validateAddress.validateAt(name, newAddress);
      setAddressDetailError((prevError) => ({ ...prevError, [name]: "" }));
    } catch (err) {
      setAddressDetailError((prevError) => ({
        ...prevError,
        [name]: err.message,
      }));
      console.log("affress error", addressDetailsError);
    }
  };

  const permanentAddressHandler = (name, value) => {
    switch (name) {
      case "current_address":
        onChangeValidator(name, value);
        return setAddressData({
          ...addressData,
          [name]: value,
          permanent_address: value,
        });
      case "current_city":
        onChangeValidator(name, value);
        return setAddressData({
          ...addressData,
          [name]: value,
          permanent_city: value,
        });

      case "current_country":
        onChangeValidator(name, value);
        return setAddressData({
          ...addressData,
          [name]: value,
          permanent_country: value,
        });
      case "current_pincode":
        onChangeValidator(name, value);
        return setAddressData({
          ...addressData,
          [name]: value,
          permanent_pincode: value,
        });
      case "current_state":
        onChangeValidator(name, value);
        return setAddressData({
          ...addressData,
          [name]: value,
          permanent_state: value,
        });
    }
  };
  const [selectedCountryID , setSelectedCountryID] = useState("");
  const [selectedStateID , setSelectedStateID ] = useState("");
  const [selectedCityID , setSelectedCityeID ] = useState("");
  const [selectedPermanentCountryID , setSelectedPermanentCountryID] = useState("");
  const [selectedPermanentStateID , setSelectedPermanentStateID ] = useState("");
  const [selectedPermanentCityID , setSelectedPermanentCityeID ] = useState("");
  const [countries , setCountries] = useState([]);
  const [states ,setSatates] = useState([]);
  const [cities ,setCities] = useState([])
  const [permanentStates ,setPermanentSatates] = useState([]);
  const [permanentCities ,setPermanentCities] = useState([])
  

  const addressDataHandler = async (e) => {
    const { name, value } = e.target;
    if(name === "current_country"){
      setSelectedCityeID("");
      setSelectedStateID("");
      setAddressData({...addressData , current_city:"", current_state:""})
      setSatates([]);
      setSelectedCountryID(value);
      console.log("selectedCountryID" , addressData);
    }
    if(name === "permanent_country"){
      setSelectedPermanentCountryID(value);
      console.log("selectedCountryID" ,value);
    }
   
    if(name === "current_state"){
      setSelectedStateID(value);
      console.log("selectedCountryID" ,value);
    }
    if(name === "permanent_state"){
      setSelectedPermanentStateID(value);
      console.log("selectedCountryID" ,value);
    }
    if(name === "current_city"){
      setSelectedCityeID(value);
      console.log("selectedCountryID" ,value);
    }
    if(name === "permanent_city"){
      setSelectedPermanentCityeID(value);
      console.log("selectedCountryID" ,value);
    }
    console.log("working....", name, value);
    if (check) {
      permanentAddressHandler(name, value);
    } else {
      setAddressData({
        ...addressData,
        [name]: value,
      });
      const newAddress = {
        ...addressData,
        [name]: value,
        // current_country: selectedCurrentCountry,
        // permanent_country: selectedPermanentCountry,
      };
      try {
        await validateAddress.validateAt(name, newAddress);
        setAddressDetailError((prevError) => ({ ...prevError, [name]: "" }));
      } catch (err) {
        setAddressDetailError((prevError) => ({
          ...prevError,
          [name]: err.message,
        }));
        console.log("affress error", addressDetailsError);
      }
    }
  };
  console.log("selected current country", addressData.current_country);

  const handleCheckBoxChanged = (e) => {
    const { checked } = e.target;
    if (checked) {
      setCheck(true);
      setAddressData((prevData) => ({
        ...prevData,
        isSameAddress: checked,
        permanent_address: prevData.current_address,
        permanent_city: prevData.current_city,
        permanent_state: prevData.current_state,
        permanent_country: prevData.current_country,
        permanent_pincode: prevData.current_pincode,
      }));
    } else {
      setCheck(false);
      setAddressData((prevData) => ({
        ...prevData,
        isSameAddress: checked,
        permanent_address: "",
        permanent_city: "",
        permanent_state: "",
        permanent_country: "",
        permanent_pincode: "",
      }));
    }
  };

  // ------------------- Employees Qualification --------------------------

  const [qualifications, setQualification] = useState([
    {
      employee_id: "",
      qualification: "",
      university: "",
      passing_year: "",
      percentage: "",
      document: null,
    },
  ]);
  const handleAddQualification = () => {
    setQualification([
      ...qualifications,
      {
        employee_id: "",
        qualification: "",
        university: "",
        passing_year: "",
        percentage: "",
        document: null,
      },
    ]);
  };
  const [qualificationError, setQualificationErrors] = useState(
    qualifications.map(() => ({
      employee_id: "",
      qualification: "",
      university: "",
      passing_year: "",
      percentage: "",
      document: "",
    }))
  );
  const handleRoveQualification = async (index, emp_id, q_id) => {
    console.log("qualificationId", q_id);
    try {
      if (q_id) {
        await axios
          .put(`${config.API_BASE_URL}employees/delete-qualification/${q_id}`)
          .then((res) => console.log("deleted qualification", res))
          .catch((err) => console.log(err));
      }
    } catch (err) {
      console.log("qualification delete error", err);
    }
    if (qualifications.length > 1) {
      const newQualification = qualifications.filter((_, i) => i !== index);
      setQualification(newQualification);
    }
  };

  const handleChangeQualification = async (index, field, value) => {
    const newQualification = [...qualifications];
    newQualification[index][field] = value;
    setQualification(newQualification);

    try {
      await validateQualification.validateAt(field, newQualification[index]);
      // No error, update any field error state if needed
      setQualificationErrors((prevErrors) => ({
        ...prevErrors,
        [index]: { ...prevErrors[index], [field]: "" },
      }));
    } catch (validationError) {
      // Update error state for the field
      setQualificationErrors((prevErrors) => ({
        ...prevErrors,
        [index]: { ...prevErrors[index], [field]: validationError.message },
      }));
    }
  };
  const handleFileChange = (index, field, file) => {
    const newQualification = [...qualifications];
    newQualification[index][field] = file;
    setQualification(newQualification);
  };

  //------------------------ Employees Employment Details --------------------------------
  const [selectedEndingDate, setSelectedEndingDate] = useState(null);
  const [selectedJoiningDate, setSelectedJoiningDate] = useState(null);
  const [employmentData, setEmployementData] = useState({
    slot: "",
    joining_date: null,
    department_id: "",
    designation_id: "",
    salary: "",
    cv: null,
  });

  const employmentDetailsHandler = async (e) => {
    const { name, value, files } = e.target;
    const newEmploymentDetail = {
      ...employmentData,
      [name]: files ? files[0] : value,
    };
    setEmployementData({
      ...employmentData,
      [name]: files ? files[0] : value,
      // slot: slotShift,
      // department_id: departmentID,
      // designation_id: designationID,
      // joining_date: selectedDate1,
    });
    try {
      await validateEmploymentDetail.validateAt(name, newEmploymentDetail);
      setEmploymentDetailError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        [name]: err.message,
      }));
    }
  };
  console.log("desigation--->", designationID);
  //------------------------ Employee Account Details  --------------------------------------

  const [accountDetails, setAccountDetails] = useState({
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
    branch_name: "",
    bank_name: "",
    uan_number: "",
    passbook_photo: null,
  });
  const [accountData, setAccountData] = useState(true);
  const [accountDetailError, setAccountDetailError] = useState({});
  const accountDetailsHandler = async (e) => {
    const { name, value, files } = e.target;
    const newAccountData = {
      ...accountDetails,
      [name]: files ? files[0] : value,
    };
    setAccountDetails({ ...accountDetails, [name]: files ? files[0] : value });
    try {
      await validateAccountDetail.validateAt(name, newAccountData);
      setAccountDetailError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (err) {
      setAccountDetailError((prevErrors) => ({
        ...prevErrors,
        [name]: err.message,
      }));
    }
  };

  const handleBack = (item) => {
    if (props.fieldOne && item == "fieldTwo") {
      props.setFieldOne(false);
      setFieldTwo(true);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (props.fieldOne && item == "fieldThree") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(true);
      props.setFieldFour(false);
      setFieldFive(false);
      // setAccountData(!accountData);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (props.fieldOne && item == "fieldFour") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(true);
      setFieldFive(false);
      setQualificationErrors("");
      handlePreviousQualification();
    }
    if (props.fieldOne && item == "fieldFive") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(true);
      setAddressDetailError("");
      // setAccountDetailError({});
      handlePreviousAddress();
    }
    if (fieldTwo && item == "fieldThree") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(true);
      props.setFieldFour(false);
      setFieldFive(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (fieldTwo && item == "fieldFour") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(true);
      setFieldFive(false);
      setQualificationErrors("");
      handlePreviousQualification();
    }
    if (fieldTwo && item == "fieldFive") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(true);
      setAddressDetailError("");
      handlePreviousAddress();
    }

    if (fieldTwo && item == "fieldOne") {
      console.log("feild one calling...1");
      props.setFieldOne(true);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }

    if (fieldThree && item == "fieldTwo") {
      props.setFieldOne(false);
      setFieldTwo(true);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }

    if (fieldThree && item == "fieldFour") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(true);
      setFieldFive(false);
      setQualificationErrors("");
      handlePreviousQualification();
    }
    if (fieldThree && item == "fieldFive") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(true);
      setAddressDetailError("");
      handlePreviousAddress();
    }

    if (fieldFive && item == "fieldTwo") {
      props.setFieldOne(false);
      setFieldTwo(true);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (fieldFive && item == "fieldThree") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(true);
      props.setFieldFour(false);
      setFieldFive(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (fieldFive && item == "fieldFour") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(true);
      setFieldFive(false);
      setQualificationErrors("");
      handlePreviousQualification();
    }

    if (fieldThree && item == "fieldOne") {
      console.log("feild one calling...2");
      props.setFieldOne(true);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }

    if (props?.fieldFour && item == "fieldTwo") {
      props.setFieldOne(false);
      setFieldTwo(true);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (props?.fieldFour && item == "fieldThree") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(true);
      props.setFieldFour(false);
      setFieldFive(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (props?.fieldFour && item == "fieldFive") {
      props.setFieldOne(false);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(true);
      setAddressDetailError("");
      handlePreviousAddress();
    }

    if (props?.fieldFour && item == "fieldOne") {
      console.log("feild one calling...3");
      props.setFieldOne(true);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }
    if (fieldFive && item == "fieldOne") {
      console.log("feild one calling...4");
      props.setFieldOne(true);
      setFieldTwo(false);
      setFieldThree(false);
      props.setFieldFour(false);
      setFieldFive(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }

    if (fieldThree && item == "fieldTwo") {
      setFieldTwo(true);
      setFieldThree(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (fieldThree && item == "fieldOne") {
      console.log("feild one calling...5");
      props.setFieldOne(true);
      setFieldThree(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }
    if (props?.fieldFour && item == "fieldThree") {
      setFieldThree(true);
      props.setFieldFour(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (props?.fieldFour && item == "fieldTwo") {
      setFieldTwo(true);
      props.setFieldFour(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (props?.fieldFour && item == "fieldOne") {
      console.log("feild one calling..6");
      props.setFieldOne(true);
      props.setFieldFour(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }
    if (fieldFive && item == "fieldFour") {
      props.setFieldFour(true);
      setFieldFive(false);
      setQualificationErrors("");
      handlePreviousQualification();
    }
    if (fieldFive && item == "fieldThree") {
      setFieldThree(true);
      setFieldFive(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (fieldFive && item == "fieldTwo") {
      setFieldTwo(true);
      setFieldFive(false);
      setEmploymentDetailError("");
      handlePreviousEmploymentDetail();
    }
    if (fieldFive && item == "fieldOne") {
      console.log("feild one calling...7");
      props.setFieldOne(true);
      setFieldFive(false);
      setBasicsDetailsError("");
      handleBasicsBack();
    }
    if (fieldTwo && item === "fieldThree") {
      setFieldThree(true);
      setFieldTwo(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
    if (props.fieldOne && item === "fieldThree") {
      setFieldThree(true);
      props.setFieldOne(false);
      setAccountDetailError("");
      handlePreviousAccountDetail();
    }
  };

  const configure = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //----------------------------- Basics Employee Details Submit Handling ---------------------------------
  // const [firstNameError, setFirstNameError] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [addressEmployeeID, setAddressEmployeeID] = useState("");
  const [qualificationEmployeeID, setQualificationEmployeeID] = useState("");
  const [employeeEmployementID, setemployeeEmployementID] = useState("");
  const [employeeAccountID, setEmployeeAccountID] = useState("");
  const [isUniqueEmail ,setIsUniqueEmail] = useState("");

  const basicsEmployeeDetailHandler = async (e) => {
    e.preventDefault();
    if(selectedDob){
  
     if(!validateDateFields(selectedDob)){
    return ;
  }
}
  
    // Define mandatory fields that must always be validated
    const mandatoryFields = {
      first_name: validationSchema.fields.first_name,
      last_name: validationSchema.fields.last_name,
      personal_email: validationSchema.fields.personal_email,
      official_email: validationSchema.fields.official_email,
      number: validationSchema.fields.number,
    };
  
    // Build the dynamic schema with mandatory fields included
    const dynamicSchema = Yup.object().shape(
      Object.keys(employeeData).reduce((acc, key) => {
        // Include mandatory fields
        if (mandatoryFields[key]) {
          acc[key] = mandatoryFields[key];
        } else if (employeeData[key]) {
          // Dynamically include other filled fields
          acc[key] = validationSchema.fields[key];
        }
        return acc;
      }, {})
    );
  
    try {
      await dynamicSchema.validate(employeeData, {
        abortEarly: false,
      });
      
      // Validation passed, proceed with submission logic
      const personalData = new FormData();
  
      for (const key in employeeData) {
        if (
          key !== "nationality" &&
          key !== "gender" &&
          key !== "marital_status" &&
          key !== "date_of_birth" &&
          key !== "married_date"
        ) {
          personalData.append(key, employeeData[key]);
        }
      }
  
      personalData.append("nationality", nationalityLabel);
      personalData.append("gender", genderHandler);
      personalData.append("date_of_birth", selectedDob);
      personalData.append("married_date", marriedDate);
      personalData.append("marital_status", maritalStatus);
  
      if (props.employID) {
        const { data } = await axios.put(
          `${config.API_BASE_URL}employees/${props.employID}`,
          personalData,
          configuration
        );
        if (data ) {
          FieldsetTwo();
        }
      } else {
        const { data } = await axios.post(
          `${config.API_BASE_URL}employees`,
          personalData,
          configuration
        );
        if(data?.result?.success == false){
          console.log("data?.result?.message" ,data?.result?.success )
          setIsUniqueEmail(data?.result?.message);
          return;
        }
        if (data?.result?.insertId) {
          setEmployeeId(data?.result?.insertId);
          FieldsetTwo();
        }
      }
    } catch (error) {
      if(error?.response?.status == 409){
        console.log("data?.result?.message"  ,error?.response?.data?.message )
        setIsUniqueEmail(error?.response?.data?.message);
        return;
      }
      const newError = {};
      error?.inner?.forEach((err) => {
        newError[err.path] = err.message;
      });
      setBasicsDetailsError(newError);
      console.log("yup error : ", newError);
    }
  };
  
  const employeeAddressDetailHandler = async (e) => {
    e.preventDefault();
    if (addressEmployeeID) {
      console.log("employee ID===>", addressData);
      try {
        await validateAddress.validate(addressData, { abortEarly: false });
        console.log("Form submit  address data: ", addressData);
        try {
          const {
            permanent_id,
            current_id,
            employee_id,
            current_address,
            current_city,
            current_country,
            current_state,
            current_pincode,
            permanent_address,
            permanent_city,
            permanent_country,
            permanent_pincode,
            permanent_state,
            isSameAddress,
          } = addressData;
          console.log(addressData, "addressData");

          const currentAddressData = {
            id: current_id,
            employee_id: addressEmployeeID,
            address: current_address,
            city: current_city,
            country: current_country,
            state: current_state,
            pincode: current_pincode,
            type: "current_address",
          };
          const permanentAddressData = {
            id: permanent_id,
            employee_id: addressEmployeeID,
            address: permanent_address,
            city: permanent_city,
            country: permanent_country,
            state: permanent_state,
            pincode: permanent_pincode,
            type: "permanent_address",
          };
          const addressDatares = await axios.put(
            `${config.API_BASE_URL}employees/address/${addressEmployeeID}`,
            [currentAddressData, permanentAddressData],
            configure
          );
          if (addressDatares) {
            // FieldsetThree();
            setCloseModal(true);
            setEmployeeData({
              first_name: "",
              middle_name: "",
              last_name: "",
              personal_email: "",
              official_email: "",
              number: "",
              alternate_number: "",
              guardian_name: "",
              // date_of_birth: null,
              // gender: "",
              photo: null,
              company_id: "",
              marital_status: "",
              married_date: null,
              spouse_name: "",
              nationality: "",
              aadhar: null,
              pan: null,
              experience_letter: null,
              resignation_letter: null,
            });
            setAddressData({
              current_address: "",
              current_city: "",
              current_state: "",
              current_country: selectedCurrentCountry
                ? selectedCurrentCountry
                : null,
              current_pincode: "",
              permanent_address: "",
              permanent_city: "",
              permanent_state: "",
              permanent_country: null,
              permanent_pincode: "",
              isSameAddress: false,
            });
            setQualification([
              {
                employee_id: "",
                qualification: "",
                university: "",
                passing_year: "",
                percentage: "",
                document: null,
              },
            ]);
            setEmployementData({
              offical_email: "",
              experience: "",
              slot: "",
              joining_date: "",
              department_id: "",
              designation_id: "",
              salary: "",
              cv: null,
            });
            setAccountDetails({
              account_number: "",
              ifsc_code: "",
              account_holder_name: "",
              branch_name: "",
              bank_name: "",
              uan_number: "",
              passbook_photo: null,
            });
            navigate(`${location?.pathname}`);
            props.setIsEditModalOpen(false);
          }
          console.log("update our address data ->", addressDatares);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setAddressDetailError(newError);
        console.log("yup error : ", newError);
      }
    } else {
      try {
        await validateAddress.validate(addressData, { abortEarly: false });
        console.log("Form submit  address data: ", addressData);
        try {
          const {
            employee_id,
            current_address,
            current_city,
            current_country,
            current_state,
            current_pincode,
            permanent_address,
            permanent_city,
            permanent_country,
            permanent_pincode,
            permanent_state,
            isSameAddress,
          } = addressData;

          const currentAddressData = {
            employee_id: props.employID,
            address: current_address,
            city: current_city,
            country: current_country,
            state: current_state,
            pincode: current_pincode,
            type: "current_address",
          };
          const permanentAddressData = {
            employee_id: props.employID,
            address: permanent_address,
            city: permanent_city,
            country: permanent_country,
            state: permanent_state,
            pincode: permanent_pincode,
            type: "permanent_address",
          };
          const addressDatares = await axios.post(
            `${config.API_BASE_URL}employees/address`,
            [currentAddressData, permanentAddressData],
            configuer
          );
          if (addressDatares) {
            // FieldsetThree();
            setCloseModal(true);
            setEmployeeData({
              first_name: "",
              middle_name: "",
              last_name: "",
              personal_email: "",
              official_email: "",
              number: "",
              alternate_number: "",
              guardian_name: "",
              // date_of_birth: null,
              // gender: "",
              photo: null,
              company_id: "",
              marital_status: "",
              married_date: null,
              spouse_name: "",
              nationality: "",
              aadhar: null,
              pan: null,
              experience_letter: null,
              resignation_letter: null,
            });
            setAddressData({
              current_address: "",
              current_city: "",
              current_state: "",
              current_country: selectedCurrentCountry
                ? selectedCurrentCountry
                : null,
              current_pincode: "",
              permanent_address: "",
              permanent_city: "",
              permanent_state: "",
              permanent_country: null,
              permanent_pincode: "",
              isSameAddress: false,
            });
            setQualification([
              {
                employee_id: "",
                qualification: "",
                university: "",
                passing_year: "",
                percentage: "",
                document: null,
              },
            ]);
            setEmployementData({
              offical_email: "",
              experience: "",
              slot: "",
              joining_date: "",
              department_id: "",
              designation_id: "",
              salary: "",
              cv: null,
            });
            setAccountDetails({
              account_number: "",
              ifsc_code: "",
              account_holder_name: "",
              branch_name: "",
              bank_name: "",
              uan_number: "",
              passbook_photo: null,
            });
            //  props?.setRenderState(true)
            navigate(`${location?.pathname}`);
            props.setIsEditModalOpen(false);
          }
          console.log("post our address data ->", addressDatares);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setAddressDetailError(newError);
        console.log("yup error : ", newError);
      }
    }
  };
  const qualificationsSchema = Yup.array().of(validateQualification);
  const employeeQualificationDetailHandler = async (e) => {
    e.preventDefault();
    if (qualificationEmployeeID) {
      try {
        await qualificationsSchema.validate(qualifications, {
          abortEarly: false,
        });
        console.log("Valid qualifications:", qualifications);
        try {
          const qualicatonFormData = new FormData();
          console.log(":our qualifications----> :", qualifications);
          qualifications.forEach((qual, index) => {
            qualicatonFormData.append(
              // newly added
              `qualifications[${index}][id]`,
              qual?.id
            );
            qualicatonFormData.append(
              `qualifications[${index}][employee_id]`,
              qualificationEmployeeID
            );
            qualicatonFormData.append(
              `qualifications[${index}][qualification]`,
              qual.qualification
            );
            qualicatonFormData.append(
              `qualifications[${index}][university]`,
              qual.university
            );
            qualicatonFormData.append(
              `qualifications[${index}][passing_year]`,
              qual.passing_year
            );
            qualicatonFormData.append(
              `qualifications[${index}][percentage]`,
              qual.percentage
            );
            if (qual.document) {
              qualicatonFormData.append(
                `qualifications[${index}][document]`,
                qual.document
              );
            }
          });
          const qualificationData = await axios.put(
            `${config.API_BASE_URL}employees/qualification/${qualificationEmployeeID}`,
            qualicatonFormData,
            configuration
          );
          if (qualificationData) {
            FieldsetFive();
          }
          console.log("updated qualification Data->", qualificationData);
        } catch (err) {
          console.log("update Qualification", err);
        }
      } catch (error) {
        const validationErrors = error?.inner?.reduce((errors, err) => {
          const pathParts = err.path.split(".");
          const index = parseInt(pathParts[0].replace("[", "").replace("]", ""), 10);
          const field = pathParts[1];

          if (!errors[index]) {
            errors[index] = {};
          }

          errors[index][field] = err.message;
          return errors;
        }, {});

        setQualificationErrors(validationErrors);
        console.log("Validation errors:", validationErrors);
      }
    } else {
      console.log("working....");
      try {
        await qualificationsSchema.validate(qualifications, {
          abortEarly: false,
        });
        console.log("Valid qualifications:", qualifications);
        try {
          const qualicatonFormData = new FormData();
          qualifications.forEach((qual, index) => {
            qualicatonFormData.append(
              `qualifications[${index}][employee_id]`,
              props.employID
            );
            qualicatonFormData.append(
              `qualifications[${index}][qualification]`,
              qual.qualification
            );
            qualicatonFormData.append(
              `qualifications[${index}][university]`,
              qual.university
            );
            qualicatonFormData.append(
              `qualifications[${index}][passing_year]`,
              qual.passing_year
            );
            qualicatonFormData.append(
              `qualifications[${index}][percentage]`,
              qual.percentage
            );
            if (qual.document) {
              qualicatonFormData.append(
                `qualifications[${index}][document]`,
                qual.document
              );
            }
          });
          const qualificationData = await axios.post(
            `${config.API_BASE_URL}employees/qualification`,
            qualicatonFormData,
            configuration
          );
          if (qualificationData?.data) {
            FieldsetFive();
          }

          console.log("post qualification Data->", qualificationData);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        
        const validationErrors = error?.inner?.reduce((errors, err) => {
          const pathParts = err.path.split(".");
          const index = parseInt(pathParts[0].replace("[", "").replace("]", ""), 10);
          const field = pathParts[1];

          if (!errors[index]) {
            errors[index] = {};
          }

          errors[index][field] = err.message;
          return errors;
        }, {});

        setQualificationErrors(validationErrors);
        console.log("Validation errors:", validationErrors);
      }
    }
  };
  console.log("Qualification Errors", qualificationError);
  const employeeEmploymentDetailHandler = async (e) => {
    e.preventDefault();
    if (employeeEmployementID) {
      try {
        await validateEmploymentDetail.validate(employmentData, {
          abortEarly: false,
        });
        console.log("Form submit   data: ", employmentData);
        if( !validateDateFields(selectedDate1)){
          return ;
        }
        try {
          const emplymentformData = new FormData();
          console.log("emplyment dat -->", employmentData);
          for (const key in employmentData) {
            if (
              key !== "joining_date" &&
              key !== "department_id" &&
              key !== "designation_id" &&
              key !== "slot" &&
              key !== "reporter_id"
            ) {
              emplymentformData.append(key, employmentData[key]);
            }
          }
          emplymentformData.append("employee_id", employeeEmployementID);
          emplymentformData.append("department_id", departmentID);
          emplymentformData.append("designation_id", designationID);
          emplymentformData.append("joining_date", selectedDate1);
          emplymentformData.append("slot", slotShift);
          emplymentformData.append("reporter_id", selectedReportee);

          const employmentDatares = await axios.put(
            `${config.API_BASE_URL}employees/employment/${employeeEmployementID}`,
            emplymentformData,
            configuration
          );
          if (employmentDatares) {
            FieldsetThree();
          }
          console.log("update employmentvv Data ->", employmentDatares);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setEmploymentDetailError(newError);
        console.log("yup error : ", newError);
      }
    } else {
      try {
        await validateEmploymentDetail.validate(employmentData, {
          abortEarly: false,
        });
        console.log("Form submit  address data: ", employmentData);
        try {
          if( !validateDateFields(selectedDate1)){
            return ;
          }
          const emplymentformData = new FormData();
          console.log("employmentData", employmentData);
          for (const key in employmentData) {
            if (
              key !== "joining_date" &&
              key !== "department_id" &&
              key !== "designation_id" &&
              key !== "slot" &&
              key !== "reporter_id"
            ) {
              emplymentformData.append(key, employmentData[key]);
            }
          }
          emplymentformData.append("employee_id", props.employID);
          emplymentformData.append("department_id", departmentID);
          emplymentformData.append("designation_id", designationID);
          emplymentformData.append("joining_date", selectedDate1);
          emplymentformData.append("slot", slotShift);
          emplymentformData.append("reporter_id", selectedReportee);

          const employmentDatares = await axios.post(
            `${config.API_BASE_URL}employees/employment_details`,
            emplymentformData,
            configuration
          );
          if (employmentDatares) {
            FieldsetThree();
            //  props?.setRenderState(true)
          }
          console.log("post employmentvv Data ->", employmentDatares);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        console.log("Nitesh : ", error);
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setEmploymentDetailError(newError);
        console.log("yup error : ", newError);
      }
    }
  };
  const employeeAccountDetails = async (e) => {
    e.preventDefault();
    if (employeeAccountID) { 
      try {
        const acountDataVerify = {
          account_holder_name: validateAccountDetail.fields.account_holder_name,
          bank_name: validateAccountDetail.fields.bank_name,
          branch_name: validateAccountDetail.fields.branch_name,
          ifsc_code: validateAccountDetail.fields.ifsc_code,
          account_number: validateAccountDetail.fields.account_number,
        };
  
        const dynamicSchema = Yup?.object()?.shape(
          Object?.keys(accountDetails)?.reduce((acc, key) => {
            if (acountDataVerify[key]) {
              acc[key] = acountDataVerify[key];
            } else if (accountDetails[key]) {
              acc[key] = validateAccountDetail.fields[key];
            }
            return acc;
          }, {})
        );
        await dynamicSchema?.validate(accountDetails, {
          abortEarly: false,
        });
        console.log("Form submit  account data: ", accountDetails);
        try {
          const formData = new FormData();
          console.log("account details --->", accountDetails);
          for (const key in accountDetails) {
            formData.append(key, accountDetails[key]);
          }
          formData.append("employee_id", employeeAccountID);
          const AccountData = await axios.put(
            `${config.API_BASE_URL}employees/account/${employeeAccountID}`,
            formData,
            configuration
          );
          console.log("AcountData->", AccountData);
          if (AccountData.data.success === true) {
            FieldsetFour(true);
          }
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        console.log("Nitesh : ", error);
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setAccountDetailError(newError);
        console.log("yup error Nitesh : ", newError);
      }
    } else {
      

      try {

        if (!accountDetails || typeof accountDetails !== 'object' || Array.isArray(accountDetails)) {
          // Define a schema to throw a validation error if accountDetails is not valid
          const dummySchema = Yup.object().shape({
            account_holder_name: Yup.string().required("Account holder name is required"),
            bank_name: Yup.string().required("Bank name is required"),
            branch_name: Yup.string().required("Branch name is required"),
            ifsc_code: Yup.string().required("IFSC code is required"),
            account_number: Yup.string().required("Account number is required"),
          });
      
          // Validate with dummy schema to throw error for missing accountDetails
          await dummySchema.validate({}, { abortEarly: false });
        }

        const acountDataVerify = {
          account_holder_name: validateAccountDetail.fields.account_holder_name,
          bank_name: validateAccountDetail.fields.bank_name,
          branch_name: validateAccountDetail.fields.branch_name,
          ifsc_code: validateAccountDetail.fields.ifsc_code,
          account_number: validateAccountDetail.fields.account_number,
        };
  
        const dynamicSchema = Yup?.object()?.shape(
          Object?.keys(accountDetails)?.reduce((acc, key) => {
            if (acountDataVerify[key]) {
              acc[key] = acountDataVerify[key];
            } else if (accountDetails[key]) {
              acc[key] = validateAccountDetail.fields[key];
            }
            return acc;
          }, {})
        );
        await dynamicSchema?.validate(accountDetails, {
          abortEarly: false,
        });
        
        try {
          const formData = new FormData();
          for (const key in accountDetails) {
            formData.append(key, accountDetails[key]);
          }
          formData.append("employee_id", props.employID);
          const AccountData = await axios.post(
            `${config.API_BASE_URL}employees/account_details`,
            formData,
            configuration
          );
          console.log("Form submit  afteer response: " ,  AccountData);
          if (AccountData?.data) {
            FieldsetFour(true);
          }
          console.log("post AcountData->", AccountData);
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        const newError = {};
        console.log("Nitesh Error hai : ", error);
        error?.inner?.forEach((err) => {
          newError[err.path] = err.message;
        });
        setAccountDetailError(newError);
        console.log("yup error Nitesh : ", newError);
      }
    }
  };
  //------------------ previous form handler ------------------------------
  const handleBasicsBack = async (item) => {
    // console.log("handle baiscs nbanm-?", item);
    if (props.employID) {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/employee-id/${props.employID}`
        );
        console.log("basics previous --->", response);
        if (response?.data?.result) {
          console.log("basics previous --->", response?.data?.result[0]);
          setEmployeeData(response?.data?.result[0]);
          setSelectedDob(response?.data?.result[0]?.date_of_birth);
          setMaritalStatus(response?.data?.result[0]?.marital_status);
          setGenderHandler(response?.data?.result[0]?.gender);
          setNationalityLabel(response?.data?.result[0]?.nationality);
          setMarrieddate(response?.data?.result[0]?.married_date);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handlePreviousAddress = async (item) => {
    console.log("item is --->", item);
    if (props.employID) {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/address/${props.employID}`
        );
        console.log("response fron address", response);
        if (response?.data?.success == true) {
          let addresses = response?.data?.result;
          setAddressEmployeeID(addresses[0].employee_id);
          setAddressData({
            current_id: addresses[0].id,
            employee_id: addresses[0].employee_id,
            current_address: addresses[0].address,
            current_city: addresses[0].city,
            current_state: addresses[0].state,
            current_country: addresses[0].country,
            current_pincode: addresses[0].pincode,
            permanent_id: addresses[1].id,
            permanent_address: addresses[1].address,
            permanent_city: addresses[1].city,
            permanent_state: addresses[1].state,
            permanent_country: addresses[1].country,
            permanent_pincode: addresses[1].pincode,
          });
          setSelectedCountryID(addresses[0].country);
          setSelectedCityeID(addresses[0].city);
          setSelectedStateID(addresses[0].state)
          setSelectedPermanentCountryID(addresses[1].country)
          setSelectedPermanentCityeID(addresses[1].city);
          setSelectedPermanentStateID(addresses[1].state)
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handlePreviousQualification = async (item) => {
    if (props.employID) {
      // if (props?.fieldFour && item == "fieldThree") {
      //   setFieldThree(true);
      //   props.setFieldFour(false);
      //   setFieldFive(false);
      //   props.setFieldOne(false);
      //   setFieldTwo(false);
      // }
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/qualification/${props.employID}`
        );
        console.log("response from qualification", response);
        setQualificationEmployeeID(response?.data?.result[0]?.employee_id);
        const qualificationData = response?.data?.result?.filter(
          (qualification) => qualification.status === 1
        );
        console.log("qualificationData nitesh : ", qualificationData);
        if (qualificationData.length != 0) {
          setQualification(qualificationData);
        }
        // if(response?.data?.result.length){
        //   setQualification(response?.data?.result);
        // }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handlePreviousEmploymentDetail = async (item) => {
    try {
      if (props.employID) {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/employment/${props.employID}`
        );
        console.log("response from employment detail", response);
        setemployeeEmployementID(response?.data?.result[0]?.employee_id);
        setEmployementData(response?.data?.result[0]);
        setEmployeeData({
          ...response?.data?.result[0],
          joining_date: response?.data?.result[0]?.joining_date,
        });
        setSelectedDate1(response?.data?.result[0]?.joining_date);
        setDepartmentName(response?.data?.result[0]?.department_name);
        setDesignationID(response?.data?.result[0]?.designation_id);
        setDesignationName(response?.data?.result[0]?.designation_name);
        setSlotShift(response?.data?.result[0]?.slot);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePreviousAccountDetail = async () => {
    console.log("response from account data");
    try {
      if (props.employID) {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/account-data/${props.employID}`
        );
        console.log("response from account data", response);

        if (response?.data?.success == true) {
          setAccountDetails(response?.data?.result[0]);
        }

        // setemployeeEmployementID(response?.data?.result[0]?.employee_id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //----------------------- useEffect Handler -------------------------------------
  // setEmployeeId(props.employID);

  // useEffect(()=>{
  //   setEmployeeId(props.employID);
  //   console.log(":ejbedcjkbdebhkj", props.employID)
  //     const fetchDetails = async ()=>{
  //         try {
  //           console.log("going to work----------------------------------------------->")
  //           const response = await axios.get(
  //             `${config.API_BASE_URL}employees/employee/${props.employID}`
  //           );
  //           if (response.data.success == true) {
  //             console.log("basics previous --->", response.data.result[0]);
  //             setEmployeeData(response.data.result[0]);
  //           }
  //         } catch (err) {
  //           console.log(err);
  //         }
  //     }
  //     fetchDetails();

  // },[props.employID])

  //--------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    // setEmployeeId(props.employID);
    if (props?.res && props?.employID) {
      const fetchData = async () => {
        const res = await axios.get(
          `${config.API_BASE_URL}employees/${props.employID}`,
          configure
        );
        console.log("employee details response-->", res);
        const response = res?.data?.result?.basicDetails;
        console.log("response data", response);
        setEmployeeData({ ...response });
        setMaritalStatus(res?.data?.result?.basicDetails?.marital_status);
        setSelectedDob(res?.data?.result?.basicDetails?.date_of_birth);
        setGenderHandler(res?.data?.result?.basicDetails?.gender);
        setNationalityLabel(res?.data?.result?.basicDetails?.nationality);
        setMarrieddate(res?.data?.result?.basicDetails?.married_date);
        let addresses = res?.data?.result?.addresses;
        console.log("addresess", addresses);
        // if(addressData.length >0){
        console.log("addresess data", addresses);
        setAddressEmployeeID(addresses[0]?.employee_id);
        setAddressData({
          current_id: addresses[0]?.id,
          employee_id: addresses[0]?.employee_id,
          current_address: addresses[0]?.address,
          current_city: addresses[0]?.city,
          current_state: addresses[0]?.state,
          current_country: addresses[0]?.country,
          current_pincode: addresses[0]?.pincode,
          permanent_id: addresses[1]?.id,
          permanent_address: addresses[1]?.address,
          permanent_city: addresses[1]?.city,
          permanent_state: addresses[1]?.state,
          permanent_country: addresses[1]?.country,
          permanent_pincode: addresses[1]?.pincode,
        });
        if (res?.data?.result?.qualifications.length > 0) {
          setQualificationEmployeeID(
            res?.data?.result?.qualifications[0]?.employee_id
          );
          setQualification(res?.data?.result?.qualifications);
        }
        if (res?.data?.result?.employmentDetails.length > 0) {
          setemployeeEmployementID(
            res?.data?.result?.employmentDetails[0]?.employee_id
          );
          setEmployementData(res?.data?.result?.employmentDetails[0]);
        }
        if (res?.data?.result?.accountDetails?.length > 0) {
          setAccountDetails(res?.data?.result?.accountDetails[0]);
          setEmployeeAccountID(
            res?.data?.result?.accountDetails[0]?.employee_id
          );
        }
        console.log("employ empoloyment id--->", employeeEmployementID);
        setDepartmentID(res?.data?.result?.employmentDetails[0]?.department_id);
      };

      fetchData();
      console.log("account details->", accountDetails);
    }
  }, [props?.res]);

  const [designationName, setDesignationName] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const inputChangeHandlerDesignation = async (event, id) => {
    const { name, value } = event.target;
    console.log("value ---->", value, "id ---->", id);
    setDesignationName(value);
    setDesignationID(id);
    setEmployementData({
      ...employmentData,
      designation_id: id,
    });
    const newEmploymentDetail = {
      ...employmentData,
      designation_id: id,
    };
    try {
      await validateEmploymentDetail.validateAt(
        "designation_id",
        newEmploymentDetail
      );
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        ["designation_id"]: "",
      }));
    } catch (err) {
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        ["designation_id"]: err.message,
      }));
    }
  };

  const inputChangeHandlerDepartment = async (event, id) => {
    const { name, value } = event.target;
    console.log("value ---->", value, "id ---->", id);
    setDepartmentName(value);
    setDepartmentID(id);
    setEmployementData({
      ...employmentData,
      department_id: id,
    });
    const newEmploymentDetail = {
      ...employmentData,
      department_id: id,
    };
    try {
      await validateEmploymentDetail.validateAt(
        "department_id",
        newEmploymentDetail
      );
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        ["department_id"]: "",
      }));
    } catch (err) {
      setEmploymentDetailError((prevErrors) => ({
        ...prevErrors,
        ["department_id"]: err.message,
      }));
    }
  };
  const handleDiscardChange = () => {
    navigate(`${location?.pathname}`);
    props.setIsEditModalOpen(false);
  };

  //-------------------------  Country , State , City ------------------------------//
  
  

  useEffect(()=>{
      axios.get(`${config.API_BASE_URL}countries`).then((res)=> setCountries(res?.data?.result)).catch(err=>console.log(err));
  },[props?.res]);

  useEffect(()=>{
    setSelectedCityeID("");
    setSelectedStateID("");
    setCities([]);
    // setAddressData({...addressData, current_city:""});
    axios.get(`${config.API_BASE_URL}states/${selectedCountryID}`).then((res)=> setSatates(res?.data?.result)).catch(err=>console.log(err));
  },[selectedCountryID])
  
  useEffect(()=>{ 
    axios.get(`${config.API_BASE_URL}city/${selectedStateID}`).then((res)=> setCities(res?.data?.result)).catch(err=>console.log(err));
  },[selectedStateID ])

  useEffect(()=>{

    setSelectedPermanentCityeID("");
    setSelectedPermanentStateID("");
    setPermanentCities([]);
    axios.get(`${config.API_BASE_URL}states/${selectedPermanentCountryID}`).then((res)=> setPermanentSatates(res?.data?.result)).catch(err=>console.log(err));
  },[selectedPermanentCountryID])

  useEffect(()=>{
    axios.get(`${config.API_BASE_URL}city/${selectedPermanentStateID}`).then((res)=> setPermanentCities(res?.data?.result)).catch(err=>console.log(err));
  },[selectedPermanentStateID])

  console.log("address Details of countries" , addressData)

  console.log("selected country ID" , selectedCountryID)



  return (
    <>
      <div id="edit_employee" className={`modal custom-modal fade ${
          props.isEditModalOpen ? "show d-block" : ""
        }`}role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              {/* <Link to  = {`../employees/profile/${props?.employID}`}> */}
              {/* <Link to={`${location.pathname}`}> */}
              <button
                type="button"
                className="btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleDiscardChange}
              >
                <span aria-hidden="false">×</span>
              </button>
              {/* </Link> */}
              {/* </Link> */}
            </div>
            {/* ----------add--------------- */}
            <div
              className="add-details-wizard ml-5 add-details-wizard-responsive"
              style={{ margin: "30px", marginTop: "15px" }}
            >
              <ul
                id="progressbar"
                className="progress-bar-wizard ml-5"
                style={{ cursor: "pointer" }}
              >
                <li
                  className={props.fieldOne ? "active" : ""}
                  onClick={() => handleBack("fieldOne")}
                >
                  <span>
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <div className="multi-step-info">
                    <h6>Basic Info</h6>
                  </div>
                </li>
                <li
                  className={fieldTwo ? "active" : ""}
                  onClick={() => handleBack("fieldTwo")}
                >
                  <span>
                    <i class="fa-solid fa-file-invoice"></i>
                  </span>
                  <div className="multi-step-info">
                    <h6>Employment Details</h6>
                  </div>
                </li>
                <li
                  className={fieldThree ? "active" : ""}
                  onClick={() => handleBack("fieldThree")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-address-card"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Account Details</h6>
                  </div>
                </li>
                <li
                  className={props?.fieldFour ? "active" : ""}
                  onClick={() => handleBack("fieldFour")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-award"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Qualification</h6>
                  </div>
                </li>
                <li
                  className={fieldFive ? "active" : ""}
                  onClick={() => handleBack("fieldFive")}
                >
                  <div className="multi-step-icon">
                    <span>
                      <i class="fa-solid fa-location-dot"></i>
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Address</h6>
                  </div>
                </li>
              </ul>
            </div>
            {/* 
              ---------------close------------- */}
            <div className="modal-body">
              <fieldset
                id="first-field"
                style={{ display: props.fieldOne ? "block" : "none" }}
              >
                <form onSubmit={basicsEmployeeDetailHandler} method="post">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          onChange={inputHandleChnage}
                          value={employeeData?.first_name}
                        />
                        {basicsDetailsError.first_name && (
                          <span className="text-danger">
                            *{basicsDetailsError.first_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Middle Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="middle_name"
                          onChange={inputHandleChnage}
                          value={employeeData?.middle_name}
                        />
                         {basicsDetailsError.middle_name && (
                          <span className="text-danger">
                            *{basicsDetailsError.middle_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          onChange={inputHandleChnage}
                          value={employeeData?.last_name}
                        />
                        {basicsDetailsError.last_name && (
                          <span className="text-danger">
                            *{basicsDetailsError?.last_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Personal Email <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="personal_email"
                          onChange={inputHandleChnage}
                          value={employeeData?.personal_email}
                        />
                        {basicsDetailsError.personal_email && (
                          <span className="text-danger">
                            *{basicsDetailsError.personal_email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Official Email <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="official_email"
                          onChange={inputHandleChnage}
                          value={employeeData?.official_email}
                        />
                         {basicsDetailsError?.official_email ? (
                          <span className="text-danger">
                            *{basicsDetailsError.official_email}
                          </span>
                        )  : isUniqueEmail && (<span className="text-danger">
                          *{isUniqueEmail}
                        </span>)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Gender</label>
                        {/* <Select
                          options={gender}
                          placeholder="Select"
                          styles={customStyles}
                          name="gender"
                          value={genderHandler}
                          onChange={(gender) => setGenderHandler(gender.label)}
                        /> */}
                        <select
                          className="form-select"
                          styles={customStyles}
                          placeholder="Select"
                          name="nationality"
                          value={
                            genderHandler ? genderHandler : employeeData?.gender
                          }
                          onChange={async(e) => {
                            setGenderHandler()
                            setEmployeeData({
                              ...employeeData,
                              gender: e.target.value,
                            });
                            const newEmployeeDetail = {
                              ...employeeData,
                              gender: e.target.value,
                            };
                            // console.log("selected Date", selectedDate1);
                            try {
                              await validationSchema.validateAt(
                                "gender",
                                newEmployeeDetail
                              );
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["gender"]: "",
                              }));
                            } catch (err) {
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["gender"]: err.message,
                              }));
                            }
                          }}
                        >
                          <option value="" > Select Gender</option>
                          {gender.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {basicsDetailsError.gender && (
                          <span className="text-danger">
                            *{basicsDetailsError?.gender}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">DOB</label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={
                              selectedDob
                                ? selectedDob
                                : employeeData?.date_of_birth
                            }
                            // onChange={(dob) => setSelectedDob(formatDate(dob))}
                            onChange={async(date) => {
                              setSelectedDob(formatDate(date))
                              if(!validateDateFields(date)){
                                setBasicsDetailsError((prevErrors) => ({
                                  ...prevErrors,
                                  ["date_of_birth"]: "Invalid Date Of Birth!",
                                }));
                                console.log("dob calling.." , basicsDetailsError)
                                return;
                              }
                              else{
                                setEmployeeData({
                                  ...employeeData,
                                  date_of_birth: formatDate(date),
                                });
                                const newEmployeeDetail = {
                                  ...employeeData,
                                  date_of_birth: formatDate(date),
                                };
                                // console.log("selected Date", selectedDate1);
                                try {
                                  await validationSchema.validateAt(
                                    "date_of_birth",
                                    newEmployeeDetail
                                  );
                                  setBasicsDetailsError((prevErrors) => ({
                                    ...prevErrors,
                                    ["date_of_birth"]: "",
                                  }));
                                } catch (err) {
                                  setBasicsDetailsError((prevErrors) => ({
                                    ...prevErrors,
                                    ["date_of_birth"]: err.message,
                                  }));
                                }
                              }
                            }}
                            className="form-control floating datetimepicker"
                            type="date"
                            dateFormat="dd-MM-yyyy"
                            name="date_of_birth"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={45}
                            // maxDate={new Date()}
                          />
                          {basicsDetailsError.date_of_birth && (
                          <span className="text-danger">
                            *{basicsDetailsError?.date_of_birth}
                          </span>
                        )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Guardian Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="guardian_name"
                          onChange={inputHandleChnage}
                          value={employeeData?.guardian_name}
                        />
                        {basicsDetailsError.guardian_name && (
                          <span className="text-danger">
                            *{basicsDetailsError?.guardian_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Marital Status</label>
                        {/* <Select
                          options={marital_status}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(slot) => {
                            setMaritalStatus(slot.label);
                          }}
                          selected={
                            maritalStatus
                              ? maritalStatus
                              : employeeData.marital_status
                          }
                        /> */}
                        <select
                          className="form-select"
                          styles={customStyles}
                          placeholder="Select"
                          name="marital_status"
                          value={
                            maritalStatus
                              ? maritalStatus
                              : employeeData?.marital_status
                          }
                          onChange={async(e) => {
                            setMaritalStatus(e.target.value)
                            setEmployeeData({
                              ...employeeData,
                              marital_status: e.target.value,
                            });
                            const newEmployeeDetail = {
                              ...employeeData,
                              marital_status: e.target.value,
                            };
                            // console.log("selected Date", selectedDate1);
                            try {
                              await validationSchema.validateAt(
                                "marital_status",
                                newEmployeeDetail
                              );
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["marital_status"]: "",
                              }));
                            } catch (err) {
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["marital_status"]: err.message,
                              }));
                            }
                          }}
                        >
                          <option value="" > Select Marital Status</option>
                          {marital_status.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {basicsDetailsError.marital_status && (
                          <span className="text-danger">
                            *{basicsDetailsError.marital_status}
                          </span>
                        )}
                      </div>
                    </div>

                    {maritalStatus == "Married" && (
                      <>
                        <div className="col-sm-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Spouse Name
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="spouse_name"
                              onChange={inputHandleChnage}
                              value={employeeData?.spouse_name}
                            />
                            {basicsDetailsError.spouse_name&& (
                          <span className="text-danger">
                            *{basicsDetailsError.spouse_name}
                          </span>
                        )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Married Date
                            </label>
                            <div className="cal-icon">
                              <DatePicker
                                selected={
                                  marriedDate
                                    ? marriedDate
                                    : employeeData?.married_date
                                }
                                onChange={async(date) =>
                                  {
                                    setMarrieddate(formatDate(date))
                                    setEmployeeData({
                                      ...employeeData,
                                      married_date: formatDate(date),
                                    });
                                    const newEmployeeDetail = {
                                      ...employeeData,
                                      married_date: formatDate(date),
                                    };
                                    // console.log("selected Date", selectedDate1);
                                    try {
                                      await validationSchema.validateAt(
                                        "married_date",
                                        newEmployeeDetail
                                      );
                                      setBasicsDetailsError((prevErrors) => ({
                                        ...prevErrors,
                                        ["married_date"]: "",
                                      }));
                                    } catch (err) {
                                      setBasicsDetailsError((prevErrors) => ({
                                        ...prevErrors,
                                        ["married_date"]: err.message,
                                      }));
                                    }
                                  }
                                }
                                className="form-control floating datetimepicker"
                                type="date"
                                dateFormat="dd-MM-yyyy"
                                name="married_date"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={45}
                                maxDate={new Date()}
                              />
                              {basicsDetailsError.married_date && (
                          <span className="text-danger">
                            *{basicsDetailsError.married_date}
                          </span>
                        )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Phone Number<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="number"
                          onChange={inputHandleChnage}
                          value={employeeData?.number}
                        />
                        {basicsDetailsError.number && (
                          <span className="text-danger">
                            *{basicsDetailsError.number}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Alternate Number{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="alternate_number"
                          onChange={inputHandleChnage}
                          value={employeeData?.alternate_number}
                        />
                        {basicsDetailsError.alternate_number && (
                          <span className="text-danger">
                            *{basicsDetailsError.alternate_number}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Nationality</label>

                        {/* <Select
                          options={nationality}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(slot) => {
                            setNationalityLabel(slot.label);
                          }}
                        /> */}
                        <select
                          className="form-select"
                          styles={customStyles}
                          placeholder="Select"
                          name="nationality"
                          value={
                            nationalityLabel
                              ? nationalityLabel
                              : employeeData?.nationality
                          }
                          onChange={async(e) => {
                            setNationalityLabel(e.target.value)
                            setEmployeeData({
                              ...employeeData,
                              nationality: e.target.value,
                            });
                            const newEmployeeDetail = {
                              ...employeeData,
                              nationality: e.target.value,
                            };
                            // console.log("selected Date", selectedDate1);
                            try {
                              await validationSchema.validateAt(
                                "nationality",
                                newEmployeeDetail
                              );
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["nationality"]: "",
                              }));
                            } catch (err) {
                              setBasicsDetailsError((prevErrors) => ({
                                ...prevErrors,
                                ["nationality"]: err.message,
                              }));
                            }
                          }}
                        >
                          <option value=""> Select Nationality</option>
                          {nationality.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {basicsDetailsError.nationality && (
                          <span className="text-danger">
                            *{basicsDetailsError.nationality}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Profile Photo</label>
                        <input
                          className="form-control"
                          type="file"
                          name="photo"
                          accept=".jpeg, .jpg, .png"
                          onChange={inputHandleChnage}
                        />
                         <p className="upload_image_file_status"> *Uploaded image must be 50KB or less</p>
                        {basicsDetailsError.photo && (
                          <span className="text-danger">
                            *{basicsDetailsError.photo}
                          </span>
                        )}
                        {employeeData.photo && (
                          <p>
                            {employeeData.photo.name
                              ? employeeData.photo.name
                              : employeeData.photo}
                          </p>
                        )}

                        {/* {employeeData.photo && <img src={`${config.IMAGE_URL}${employeeData.photo}`}/> } */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Aadhar</label>
                        <input
                          className="form-control"
                          type="file"
                          name="aadhar"
                          accept=".jpeg, .jpg, .png"
                          onChange={inputHandleChnage}
                        />
                         <p className="upload_image_file_status"> *Uploaded image must be 50KB or less</p>
                        {basicsDetailsError.aadhar && (
                          <span className="text-danger">
                            *{basicsDetailsError.aadhar}
                          </span>
                        )}
                        {employeeData.aadhar && (
                          <p>
                            {employeeData.aadhar.name
                              ? employeeData.aadhar.name
                              : employeeData.aadhar}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">PAN</label>
                        <input
                          className="form-control"
                          type="file"
                          name="pan"
                           accept=".jpeg, .jpg, .png"
                          onChange={inputHandleChnage}
                        />
                         <p className="upload_image_file_status"> *Uploaded image must be 50KB or less</p>
                        {basicsDetailsError.pan && (
                          <span className="text-danger">
                            *{basicsDetailsError.pan}
                          </span>
                        )}
                        {employeeData.pan && (
                          <p>
                            {employeeData.pan.name
                              ? employeeData.pan.name
                              : employeeData.pan}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-12 mt-5 mb-3">
                      <h3>Work Experience</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Experience Letter
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name="experience_letter"
                          onChange={inputHandleChnage}
                          accept=".pdf"
                        />
                         <p className="upload_image_file_status"> *Uploaded file must be 50KB or less</p>
                        {employeeData.experience_letter && (
                          <p>
                            {employeeData.experience_letter.name
                              ? employeeData.experience_letter.name
                              : employeeData.experience_letter}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Relieving Letter
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name="resignation_letter"
                          onChange={inputHandleChnage}
                          accept=".pdf"
                        />
                         <p className="upload_image_file_status"> *Uploaded file must be 50KB or less</p>
                        {employeeData.resignation_letter && (
                          <p>
                            {employeeData.resignation_letter.name
                              ? employeeData.resignation_letter.name
                              : employeeData.resignation_letter}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      {/* <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        style={{ marginRight: "10px" }}
                        onClick={() => FieldsetTwo()}
                      >
                        Skip
                      </button> */}
                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        // onClick={handleSbubmiteInBasics}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldFive ? "block" : "none" }}>
                <form
                  method="post"
                  // onClick={employeeAddressDetailHandler}
                >
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Current Address
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_address"
                            value={addressData?.current_address}
                            onChange={addressDataHandler}
                          />
                          {addressDetailsError.current_address && (
                            <span className="text-danger">
                              *{addressDetailsError.current_address}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Country <span className="text-danger">*</span>
                          </label>

                          {/* <Select
                            className="select"
                            options={countrylist}
                            placeholder="Select"
                            styles={customStyles}
                            selected={selectedCurrentCountry ? selectedCurrentCountry : addressData.current_country}
                            // value={selectedCurrentCountry ? selectedCurrentCountry : addressData.current_country}
                            onChange={(country) => {
                              setSelectedCurrentCountry(country);
                            }}
                          /> */}

                          <select
                            className="form-select"
                            styles={customStyles}
                            placeholder="Select"
                            name="current_country"
                            value={addressData?.current_country}
                            onChange={
                              addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            <option value="" > Select Country</option>
                            {countries.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.country_name}
                              </option>
                            ))}
                          </select>
                          {addressDetailsError.current_country && (
                            <span className="text-danger">
                              *{addressDetailsError.current_country}
                            </span>
                          )}
                        </div>
                      </div>
                      
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            State <span className="text-danger"> *</span>
                          </label>
                          {/* <input
                            className="form-control"
                            type="text"
                            name="current_state"
                            value={addressData?.current_state}
                            onChange={addressDataHandler}
                          /> */}

                          <select
                            className="form-select"
                            styles={customStyles}
                            placeholder="Select"
                            name="current_state"
                            value={addressData?.current_state}
                            onChange={
                              addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            <option value="" > Select State</option>
                            {states.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.state}
                              </option>
                            ))}
                          </select>
                          {addressDetailsError.current_state && (
                            <span className="text-danger">
                              *{addressDetailsError.current_state}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            City
                            <span className="text-danger"> *</span>
                          </label>
                          {/* <input
                            className="form-control"
                            type="text"
                            name="current_city"
                            value={addressData?.current_city}
                            onChange={addressDataHandler}
                          /> */}
                          <select
                            className="form-select"
                            styles={customStyles}
                            placeholder="Select"
                            name="current_city"
                            value={addressData?.current_city}
                            onChange={
                              addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            <option value="" > Select City</option>
                            {cities.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.city_name}
                              </option>
                            ))}
                          </select>
                          {addressDetailsError.current_city && (
                            <span className="text-danger">
                              *{addressDetailsError.current_city}
                            </span>
                          )}
                        </div>
                      </div>
                      
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Pincode <span className="text-danger"> *</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="current_pincode"
                            value={addressData?.current_pincode}
                            onChange={addressDataHandler}
                          />
                          {addressDetailsError.current_pincode && (
                            <span className="text-danger">
                              *{addressDetailsError.current_pincode}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <input
                            type="checkbox"
                            onChange={handleCheckBoxChanged}
                          />{" "}
                          Permanent Address and Current Address are the same.
                        </div>
                      </div>
                    </div>
                    {!check && (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Permanent Address
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="permanent_address"
                              value={addressData?.permanent_address}
                              onChange={addressDataHandler}
                              disabled={addressData.isSameAddress}
                            />
                            {addressDetailsError.permanent_address && (
                              <span className="text-danger">
                                *{addressDetailsError.permanent_address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Country <span className="text-danger">*</span>
                            </label>
                            {/* 
                          <Select
                            
                            className="select"
                            options={countrylist}
                            placeholder="Select"
                            styles={customStyles}
                            value={
                              addressData.isSameAddress == true
                                ? selectedCurrentCountry
                                : selectedPermanentCountry
                            }
                            onChange={(country) =>
                              setSelectedPermanentCountry(country)
                            }
                            isdisabled={addressData.isSameAddress}
                          /> */}
                            <select
                              className="form-select"
                              styles={customStyles}
                              placeholder="Select"
                              name="permanent_country"
                              value={
                                addressData.isSameAddress == true
                                  ? addressData?.current_country
                                  : addressData?.permanent_country
                              }
                              onChange={addressDataHandler}
                            >
                              <option value="" > Select Country</option>
                              {countries.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.country_name}
                              </option>
                            ))}
                            </select>
                            {addressDetailsError.permanent_country && (
                              <span className="text-danger">
                                *{addressDetailsError.permanent_country}
                              </span>
                            )}
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              State <span className="text-danger"> *</span>
                            </label>
                            {/* <input
                              className="form-control"
                              type="text"
                              name="permanent_state"
                              value={addressData?.permanent_state}
                              onChange={addressDataHandler}
                              disabled={addressData.isSameAddress}
                            /> */}
                            <select
                            className="form-select"
                            styles={customStyles}
                            placeholder="Select"
                            name="permanent_state"
                            value={addressData?.permanent_state}
                            onChange={
                              addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            <option value="" > Select State</option>
                            {permanentStates.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.state}
                              </option>
                            ))}
                          </select>
                            {addressDetailsError.permanent_state && (
                              <span className="text-danger">
                                *{addressDetailsError.permanent_state}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              City
                              <span className="text-danger"> *</span>
                            </label>
                            {/* <input
                              className="form-control"
                              type="text"
                              name="permanent_city"
                              value={addressData?.permanent_city}
                              onChange={addressDataHandler}
                              disabled={addressData.isSameAddress}
                            /> */}
                            <select
                            className="form-select"
                            styles={customStyles}
                            placeholder="Select"
                            name="permanent_city"
                            value={addressData?.permanent_city}
                            onChange={
                              addressDataHandler}
                            // onChange={(slot) => {
                            //     setSlotShift(slot.label);
                            //   }}
                          >
                            <option value="" > Select City</option>
                            {permanentCities.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.city_name}
                              </option>
                            ))}
                          </select>
                            {addressDetailsError.permanent_city && (
                              <span className="text-danger">
                                *{addressDetailsError.permanent_city}
                              </span>
                            )}
                          </div>
                        </div>
                        
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Pincode <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="permanent_pincode"
                              value={addressData?.permanent_pincode}
                              onChange={addressDataHandler}
                              disabled={addressData.isSameAddress}
                            />
                            {addressDetailsError.permanent_pincode && (
                              <span className="text-danger">
                                *{addressDetailsError.permanent_pincode}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      {/* <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                        
                          handleBasicsBack("fieldOne")
                        }
                      >
                        Previous
                      </button> */}
                      {/* <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => FieldsetThree()}
                      >
                        Skip
                      </button> */}
                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        data-bs-dismiss="modal"
                        aria-label={closeModal && "Close"}
                        onClick={employeeAddressDetailHandler}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset
                style={{ display: props?.fieldFour ? "block" : "none" }}
              >
                <form onSubmit={employeeQualificationDetailHandler}>
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-lg-12 text-end form-wizard-button">
                      <span
                          className="text-danger font-weight-bold"
                          type="button"
                          onClick={handleAddQualification}
                        >
                          <i class="fa-solid fa-plus"></i> {" "}
                           Add More 
                        </span>
                      </div>
                    </div>
                    {qualifications?.map((qual, index) => (
                      <div className="row mt-4 mb-4" key={index}
                      style={{border :"1px solid #e6e5e3" , borderRadius:"20px"}} >
                        <div className="row">
                          <div className="col-lg-12 text-end form-wizard-button mt-2">
                            <i
                              class="fa-solid fa-trash"
                              style={{ color: "red", fontSize: "15px", cursor:"pointer" }}
                              onClick={() =>
                                handleRoveQualification(
                                  index,
                                  qual?.employee_id,
                                  qual?.id
                                )
                              }
                            ></i>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Qualification
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="qualification"
                              value={qual?.qualification}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "qualification",
                                  e.target.value
                                )
                              }
                            />
                            {qualificationError[index]?.qualification && (
                              <span className="text-danger">
                                {qualificationError[index]?.qualification}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              University / Board
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="university"
                              value={qual?.university}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "university",
                                  e.target.value
                                )
                              }
                            />
                            {qualificationError[index]?.university && (
                              <span className="text-danger">
                                {qualificationError[index].university}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Passing Year
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="passing_year"
                              value={qual?.passing_year}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "passing_year",
                                  e.target.value
                                )
                              }
                            />
                            {qualificationError[index]?.passing_year && (
                              <span className="text-danger">
                                {qualificationError[index].passing_year}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Percentage <span className="text-danger"> *</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="percentage"
                              value={qual?.percentage}
                              onChange={(e) =>
                                handleChangeQualification(
                                  index,
                                  "percentage",
                                  e.target.value
                                )
                              }
                            />
                            {qualificationError[index]?.percentage && (
                              <span className="text-danger">
                                {qualificationError[index]?.percentage}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Document</label>
                            <input
                              className="form-control"
                              type="file"
                              name="document"
                              accept=".pdf"
                              onChange={(e) =>
                                handleFileChange(
                                  index,
                                  "document",
                                  e.target.files[0]
                                )
                              }
                            />
                             <p className="upload_image_file_status"> *Uploaded file must be 50KB or less</p>
                            {qualificationError[index]?.document && (
                              <span className="text-danger">
                                {qualificationError[index]?.document}
                              </span>
                            )}
                            {qual.document && (
                              <p>
                                {qual?.document?.name
                                  ? qual?.document?.name
                                  : qual?.document}{" "}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* {qualifications.length > 1 && <hr />} */}
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-lg-12 text-end form-wizard-button">
                        {/* <button
                          className="btn btn-primary submit-btn"
                          type="button"
                          style={{ marginRight: "10px" }}
                          onClick={() => handlePreviousAddress()}
                        >
                          Previous
                        </button> */}
                        {/* <button
                          className="btn btn-primary submit-btn"
                          type="button"
                          style={{ marginRight: "10px" }}
                          onClick={() => FieldsetFive()}
                        >
                          Skip
                        </button> */}
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                          // onClick={employeeQualificationDetailHandler}
                        >
                          Save &amp; Next
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldTwo ? "block" : "none" }}>
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Joining Date <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <DatePicker
                            selected={
                              selectedDate1
                                ? selectedDate1
                                : employmentData?.joining_date
                            }
                            onChange={handleDateChange1}
                            className="form-control floating datetimepicker"
                            type="date"
                            dateFormat="dd-MM-yyyy"
                            name="joining_date"
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={45}
                            // maxDate={new Date()} 
                          />
                          {employmentDetailsError.joining_date && (
                            <span className="text-danger">
                              {employmentDetailsError.joining_date}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Work Experience <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="experience"
                          onChange={employmentDetailsHandler}
                          value={employmentData?.experience}
                        />
                        {employmentDetailsError.experience && (
                          <span className="text-danger">
                            *{employmentDetailsError.experience}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={department}
                          placeholder="Select"
                          styles={customStyles}
                          
                          onChange={(department) =>
                            setDepartmentID(department.value)
                          }
                        /> */}

                        <select
                          name="department_id"
                          className="form-select"
                          styles={customStyles}
                          placeholder="Select Department"
                          onChange={(event) =>
                            inputChangeHandlerDepartment(
                              event,
                              event.target.selectedOptions[0].getAttribute(
                                "data-id"
                              )
                            )
                          }
                          value={
                            departmentName
                              ? departmentName
                              : employmentData?.department_name
                          }
                        >
                          <option value = "">Select Department</option>
                          {department.map((department) => (
                            <option
                              value={department.label}
                              key={department.value}
                              data-id={department.value}
                            >
                              {department.label}
                            </option>
                          ))}
                        </select>
                        {employmentDetailsError.department_id && (
                          <span className="text-danger">
                            *{employmentDetailsError.department_id}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Designation <span className="text-danger">*</span>
                        </label>
                        {/* <Select
                          options={designation}
                          placeholder="Select"
                          styles={customStyles}
                     
                          onChange={(designation) =>
                            setDesignationID(designation.value)
                          }
                        /> */}
                        <select
                          name="designation_id"
                          className="form-select"
                          styles={customStyles}
                          placeholder="select department"
                          onChange={(event) =>
                            inputChangeHandlerDesignation(
                              event,
                              event.target.selectedOptions[0].getAttribute(
                                "data-id"
                              )
                            )
                          }
                          value={
                            designationName
                              ? designationName
                              : employmentData?.designation_name ? employmentData?.designation_name : employmentData?.designation_id
                          }
                        >
                          {" "}
                          <option value = "">Select Designation</option>
                          {designation.map((designation) => (
                            <option
                              value={designation.label}
                              key={designation.value}
                              data-id={designation.value}
                            >
                              {designation.label}
                            </option>
                          ))}
                        </select>
                        {employmentDetailsError.designation_id && (
                          <span className="text-danger">
                            {employmentDetailsError.designation_id}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Time Slot <span className="text-danger">*</span></label>
                        {/* <Select
                          options={slot}
                          placeholder="Select"
                          styles={customStyles}
                          selected={slotShift ? slotShift : employmentData.slot}
                          onChange={(slot) => {
                            setSlotShift(slot.label);
                          }}
                        /> */}

                        <select
                          name="slot"
                          className="form-select"
                          styles={customStyles}
                          onChange={async (e) => {
                            setSlotShift(e.target.value);
                            setEmployementData({
                              ...employmentData,
                              slot: e.target.value,
                            });
                            const newEmploymentDetail = {
                              ...employmentData,
                              slot: e.target.value,
                            };

                            try {
                              await validateEmploymentDetail.validateAt(
                                "slot",
                                newEmploymentDetail
                              );
                              setEmploymentDetailError((prevErrors) => ({
                                ...prevErrors,
                                ["slot"]: "",
                              }));
                            } catch (err) {
                              setEmploymentDetailError((prevErrors) => ({
                                ...prevErrors,
                                ["slot"]: err.message,
                              }));
                            }
                          }}
                          value={slotShift ? slotShift : employmentData?.slot}
                        >
                          <option value ="">Select Shift</option>
                          {slot.map((slot) => (
                            <option value={slot.label} key={slot.value}>
                              {slot.label}
                            </option>
                          ))}
                        </select>
                        {employmentDetailsError.slot && (
                          <span className="text-danger">
                            *{employmentDetailsError.slot}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Salary <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          name="salary"
                          onChange={employmentDetailsHandler}
                          value={employmentData?.salary}
                        />
                        {employmentDetailsError.salary && (
                          <span className="text-danger">
                            *{employmentDetailsError?.salary}
                          </span>
                        )}
                      </div>
                    </div>

                    {/*<div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">CV <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="file"
                          name="cv"
                          onChange={employmentDetailsHandler}
                        />
                        {employmentDetailsError.cv && (
                          <span className="text-danger">
                            *{employmentDetailsError?.cv}
                          </span>
                        )}
                      </div>
                      {employmentData?.cv && (
                        <p>
                          {employmentData?.cv?.name
                            ? employmentData?.cv?.name
                            : employmentData?.cv}
                        </p>
                      )}
                    </div> */}

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Select Reportee
                        </label>
                        <select
                        
                          className="form-select"
                          styles={customStyles}
                          placeholder="Select"
                          name="marital_status"
                          value={
                            selectedReportee
                              ? selectedReportee
                              : employmentData?.reporter_id
                          }
                          onChange={(e) => {
                            setSelectedReportee(e.target.value);
                          }}
                        >
                          <option value="">
                            Select Reportee
                          </option>
                          {allEmployees.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item?.first_name}
                              {item?.middle_name} {item?.last_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      {/* <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                          handleBasicsBack("feildOne")
                        }
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => FieldsetThree()}
                      >
                        Skip
                      </button> */}
                      <button
                        className="btn btn-primary submit-btn "
                        type="submit"
                        onClick={employeeEmploymentDetailHandler}
                      >
                        Save &amp; Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>

              <fieldset style={{ display: fieldThree ? "block" : "none" }}>
                <form method="post">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Account Holder Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_holder_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails?.account_holder_name}
                        />
                        {accountDetailError.account_holder_name && (
                          <span className="text-danger">
                            *{accountDetailError.account_holder_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="account_number"
                          onChange={accountDetailsHandler}
                          value={accountDetails?.account_number}
                        />
                        {accountDetailError.account_number && (
                          <span className="text-danger">
                            *{accountDetailError.account_number}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          IFSC Code <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="ifsc_code"
                          value={accountDetails?.ifsc_code}
                          onChange={accountDetailsHandler}
                        />
                        {accountDetailError.ifsc_code && (
                          <span className="text-danger">
                            *{accountDetailError?.ifsc_code}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Bank Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="bank_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails?.bank_name}
                        />
                        {accountDetailError.bank_name && (
                          <span className="text-danger">
                            *{accountDetailError?.bank_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Branch Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="branch_name"
                          onChange={accountDetailsHandler}
                          value={accountDetails?.branch_name}
                        />
                        {accountDetailError.branch_name && (
                          <span className="text-danger">
                            *{accountDetailError.branch_name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Passbook Picture
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          name="passbook_photo"
                          accept=".jpeg, .jpg, .png"
                          onChange={accountDetailsHandler}
                        />
                         <p className="upload_image_file_status"> *Uploaded image must be 50KB or less</p>

                        {accountDetails?.passbook_photo && (
                          <p>
                            {accountDetails?.passbook_photo?.name
                              ? accountDetails?.passbook_photo?.name
                              : accountDetails?.passbook_photo}
                          </p>
                        )}
                        {accountDetailError.passbook_photo && (
                          <span className="text-danger">
                            *{accountDetailError?.passbook_photo}
                          </span>
                        )}
                      </div>
                    </div>
                    <hr />

                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Provident Fund (UAN Number)
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="uan_number"
                          onChange={accountDetailsHandler}
                          value={accountDetails?.uan_number}
                        />
                        {accountDetailError.uan_number && (
                          <span className="text-danger">
                            *{accountDetailError.uan_number}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="submit-section">
                    <div className="col-lg-12 text-end form-wizard-button">
                      {/* <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                          handlePreviousEmploymentDetail("fieldFour")
                        }
                      >
                        Previous
                      </button>

                      <button
                        className="btn btn-primary submit-btn"
                        type="button"
                        style={{ marginRight: "10px" }}
                        onClick={() => FieldsetFour()}
                      >
                        Skip
                      </button> */}

                      <button
                        className="btn btn-primary submit-btn"
                        type="submit"
                        // data-bs-dismiss="modal"
                        // aria-label={closeModal && "Close"}
                        onClick={employeeAccountDetails}
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCandidatesModal;
