export const calculateSalaryBreakdown = (salary, config) => {
    const basic = salary * (config.basic_percentage / 100);
    const hra = basic * (config.hra_percentage / 100);
    const medicalAllowance =
      basic * (config.medical_allowance_percentage / 100);
    const statutoryBonus = basic * (config.statutory_bonus_percentage / 100);
    const otherAllowance = basic * (config.other_allowance_percentage / 100);
    const totalPay =
      basic + hra + medicalAllowance + statutoryBonus + otherAllowance;

    return {
      basic,
      hra,
      medicalAllowance,
      statutoryBonus,
      otherAllowance,
      totalPay,
    };
  };