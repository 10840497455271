import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './Loader.css';

const Loader = ({ show, loadingMessage = "" }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      className={loadingMessage ? "modal-with-bg" : "modal-transparent"}
    >
      <Modal.Body className="text-center">
      <Spinner animation="border" variant="primary" />
        {loadingMessage && <output className="mt-3">{loadingMessage}</output>}
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
