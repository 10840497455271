export function onLoginSuccess(employeeId,start_time,end_time) {
    if (window.AndroidInterface) {
        window.AndroidInterface.startLocationCapture(employeeId,start_time,end_time);
        ;  // Start location tracking
        // alert("Tracking : Android interface available.");
    }
    else {
        // alert("Tracking : Android interface not available.");
    }
}

export function onLogoutSuccess() {
    if (window.AndroidInterface) {
        window.AndroidInterface.stopLocationCapture();  // Stop location tracking
        // alert("Tracking : Android interface available.");
    }
    else {
        // alert("Tracking : Android interface not available.");
    }
}


