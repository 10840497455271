import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Pagination, Select } from "antd";
import EmployeeCard from "./EmployeeCard";
import EmployeeLocationModal from "./EmployeeLocationModal";
import { companyID } from "../../../../auth";
import config from "../../../../config";
import axios from "axios";

const EmployeeLocations = () => {
  const company_id = companyID();
  let token = localStorage.getItem("token");
  const employeeID = localStorage.getItem("employeeID");
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}employees/employeeDetails/${company_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmployees(response?.data?.filter((item) => item.id != employeeID));
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    if (company_id) {
      fetchEmployees();
    }
  }, []);


  const paginatedEmployees = employees.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleCardClick = (employee) => {
    setSelectedEmployee(employee);
    setModalVisible(true);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className="page-wrapper">
      <Container className="mt-4">
      <h2 className="text-center mb-4">Employee Location Status</h2>
        <Row>
          {paginatedEmployees.map((employee) => (
            <Col key={employee.id} xs={12} sm={6} md={4} lg={3}style={{ cursor: "pointer" }}>
              <EmployeeCard employee={employee} onClick={handleCardClick} />
            </Col>
          ))}
        </Row>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={employees.length}
            showSizeChanger
            onChange={handlePageChange}
            pageSizeOptions={["5", "10", "15", "20"]}
          />
        </div>

        {selectedEmployee && (
          <EmployeeLocationModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            employee={selectedEmployee}
          />
        )}
      </Container>
    </div>
  );
};

export default EmployeeLocations;
