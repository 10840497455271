import React, { useEffect, useState } from "react";
import { getData } from "../../../utils/api"; // Import your custom getData function
import Breadcrumbs from "../../../components/Breadcrumbs";
import AddandEditPolicy from "../../../components/modelpopup/AddandEditPolicy";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import "../../../assets/css/policies.css";
import config from "../../../config";
import axios from "axios";
import { companyID } from "../../../auth";

const MyPolicies = () => {
  const [policyData, setPolicyData] = useState([]);
  const [filteredPolicyData, setFilteredPolicyData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const company_id  = companyID();
    const user = localStorage.getItem("user");
    const parseUSer = JSON.parse(user);
    const depart_id = parseUSer?.department_id;
    console.log("depart_id" , depart_id);
    const desig_id = parseUSer?.designation_id;
    console.log("desig_id" , desig_id)

    const fetchPolicyData = async () => {
      try {
        // const res = await getData("hr/policy-data");
        const res = await axios.get(
          `${config.API_BASE_URL}hr/policy-data/${company_id}/${depart_id}/${desig_id}`,
          configuration
        );
        console.log("data response received -->", res);
        const filteredData = res?.data?.results?.filter(
          (policy) => policy?.status === 1
        );
        setPolicyData(filteredData);
        setFilteredPolicyData(filteredData);
        const userManualCount = filteredData?.filter(
          (policy) => policy?.policy_type === "User Manual"
        ).length;
        const allPoliciesCount = filteredData.length;
        const companyPoliciesCount = filteredData?.filter(
          (policy) => policy?.policy_type === "Company Policies"
        ).length;

        setStatsData([
          {
            id: 1,
            title: "All Policies",
            value: allPoliciesCount.toString(),
            active: true,
          },
          {
            id: 2,
            title: "User Manual",
            value: userManualCount.toString(),
            active: false,
          },
          {
            id: 3,
            title: "Company Policies",
            value: companyPoliciesCount.toString(),
            active: false,
          },
        ]);
      } catch (error) {
        console.error("Error fetching policy data:", error);
      }
    };

    fetchPolicyData();
  }, []);

  const filterPolicies = (policyType) => {
    if (policyType === "All Policies") {
      setFilteredPolicyData(policyData);
    } else {
      const filteredPolicies = policyData?.filter(
        (policy) => policy?.policy_type === policyType
      );
      setFilteredPolicyData(filteredPolicies);
    }

    const updatedStatsData = statsData.map((stat) => ({
      ...stat,
      active: stat?.title === policyType,
    }));
    setStatsData(updatedStatsData);
  };

  const handleDownload = async (filePath) => {
    try {
      const a = document.createElement("a");
      a.href = filePath;
      a.download = "policy_document.jpg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const openModal = (imageUrl) => {
    console.log("calling................");
    setModalImage(imageUrl);
    setShowModal(true);
  };
  console.log("set show mail state-->", showModal);

  const closeModal = () => {
    setShowModal(false);
    setModalImage("");
  };
  console.log("filteredPolicyData:", filteredPolicyData);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="My Policies"
            title="Dashboard"
            subtitle="My Policies"
            modal="#add_policy"
            name="Add Policy"
          />

          <div className="row">
            {statsData?.map((stat) => (
              <div
                className={`col-md-4 d-flex`}
                key={stat?.id}
                onClick={() => filterPolicies(stat?.title)}
                style={{cursor:'pointer'}}
              >
                <div
                  className={`stats-info w-100 ${
                    stat?.active ? "orange-bg" : ""
                  }`}
                >
                  <h6 className={` ${stat.active ? "text-white" : ""}`}>{stat?.title}</h6>
                  <h4 className={` ${stat.active ? "text-white" : ""}`}>{stat?.value}</h4>
                </div>
              </div>
            ))}
          </div>

          {filteredPolicyData?.map((item) => (
            <div
              className={`card shadow p-3 mb-5 bg-white rounded  blue-left-border ${
                item.status === 1 ? "orange-bg" : ""
              }`}
              key={item.id}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card-text">
                      <div className="name">{item?.policy_name}</div>
                      <div className="class mt-2 mb-2">
                        <span
                          className="p-1"
                          style={{
                            backgroundColor:
                              item?.status === 1 ? "orange" : "initial",
                          }}
                        >
                          {item?.policy_type}
                        </span>
                      </div>
                      <div className="date text-muted">
                        Last update on : {formatDateInTimezone(item.created_at)}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Options"
                    >
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                          handleDownload(
                            `${config.IMAGE_URL}${item?.policy_file}`
                          )
                        }
                      >
                        <i className="fas fa-download"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                          handleDownload(
                            `${config.IMAGE_URL}${item?.policy_file}`
                          )
                        }
                      >
                        <i className="fas fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for viewing image */}
      {/* {showModal && (
        <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modal title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary">Save changes</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      )} */}

      <AddandEditPolicy />
    </>
  );
};

export default MyPolicies;
