import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddandEditPolicy from "../../../../components/modelpopup/AddandEditPolicy";
import DeleteModal from "../../../../components/modelpopup/deletePopup";
import { getData } from "../../../../utils/api";
import { formatDateInTimezone } from "../../../../utils/formatDateInTimezone";
import config from "./../../../../config";
import axios from "axios";
import { companyID } from "../../../../auth";

const Policies = () => {
  const company_id  = companyID();
  const employeeId = localStorage.getItem("employeeID");
  const [policyData, setPolicyData] = useState([]);
  const [filteredPolicyData, setFilteredPolicyData] = useState([]);
  const [policyEditData, setPlocyEditData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [deletedId, setDeletedID] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  // ------------------ fetching policies------------------------

  useEffect(() => {
    let token = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const user = localStorage.getItem("user");
    const parseUSer = JSON.parse(user);
    const depart_id = parseUSer?.department_id;
    console.log("depart_id" , depart_id);
    const desig_id = parseUSer?.designation_id;
    console.log("desig_id" , desig_id)
    try {
      const policyData = async (e) => {
        const res = await axios.get(
          `${config.API_BASE_URL}hr/policy-data/${company_id}/${depart_id}/${desig_id}?hr=${depart_id}&created_by=${employeeId}`,
          configuration
        );
        console.log("data respinse receiver-->", res);
        // const filteredData = res?.data?.results;
        const filteredData = res?.data?.results
          .map((policy, index) => ({ ...policy, index: index + 1 }))
          .filter((policy) => policy.status === 1);
        console.log("filteredData filteredData", filteredData);
        setPolicyData(filteredData);
        setFilteredPolicyData(filteredData);
        const userManualCount = filteredData.filter(
          (policy) => policy.policy_type === "User Manual"
        ).length;
        const allPoliciesCount = filteredData.length;
        const companyPoliciesCount = filteredData.filter(
          (policy) => policy.policy_type === "Company Policies"
        ).length;

        setStatsData([
          {
            id: 1,
            title: "All Policies",
            value: allPoliciesCount.toString(),
            active: true,
          },
          {
            id: 2,
            title: "User Manual",
            value: userManualCount.toString(),
            active: false,
          },
          {
            id: 3,
            title: "Company Policies",
            value: companyPoliciesCount.toString(),
            active: false,
          },
          // {
          //   id: 4,
          //   title: "Pending Requests",
          //   value: "12",
          // },
        ]);
      };
      policyData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  //----------------------------------------------------------------------------------------------

  const handleDownload = async (filePath) => {
    try {
      const a = document.createElement("a");
      a.href = filePath;
      a.download = "policy_document.jpg";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      sorter: (a, b) => a.index.length - b.index.length,
    },
    {
      title: "Policy Name",
      dataIndex: "policy_name",
      sorter: (a, b) => a.policy_name.length - b.policy_name.length,
    },
    {
      title: "Category",
      dataIndex: "policy_type",
      sorter: (a, b) =>
        a.policy_type.length - b.policy_type.length,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      render: (text, record) => formatDateInTimezone(record?.created_at),
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() =>
                handleDownload(`${config.IMAGE_URL}${record.policy_file}`)
              }
            >
              <i className="fa fa-download m-r-5" /> Download
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleEdit(record.id)
                openEditModal();
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDelete(record.id)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const [selectedDepartmentId , setSelectedDepartmentsId] = useState([]);

  const [selectedDesignationtId , setSelectedDesignationId] = useState([]);

  const handleEdit = (id) => {
   try{
    const policyeditData = async () => {
      const res = await getData(`hr/policy-data-update/${id}`);
      console.log("fetch Particular id data", res);
      setPlocyEditData(res?.data?.result);
      const response = await getData(`hr/department-desdignation/${id}`);
      if( response?.data?.success == true){
        const selectededDepartments = response?.data?.result?.map((item)=> item.department_id);
        const selectededDesignation = response?.data?.result?.map((item)=> item.designation_id);
        setSelectedDepartmentsId(selectededDepartments)
        setSelectedDesignationId(selectededDesignation)
        console.log("selectededDepartments" , selectededDepartments , "selectededDesignation" , selectededDesignation )
      }
    };
    policyeditData();
   }
   catch(err){

   }
  };
  //-------------------- delete handler ----------------------

  const handleDelete = (id) => {
    setDeletedID(id);
  };
  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const confirmDelete = async () => {
    if (deletedId) {
      const res = await axios.put(
        `${config.API_BASE_URL}hr/policy-data/${deletedId}/${employeeId}`,
        {},
        configuration
      );
      console.log("Deleted data response", res);
      navigate(`${location.pathname}`);
      // const updatedData = await getData("hr/policy-data");
      // const filteredData = updatedData?.data?.results.filter(policy => policy.status === 1);
      // setPolicyData(filteredData);
      setDeletedID(null); // Reset the delete ID
    }
  };

  const filterPolicies = (policyType) => {
    if (policyType === "All Policies") {
      setFilteredPolicyData(policyData);
    } else {
      const filteredPolicies = policyData.filter(
        (policy) => policy?.policy_type === policyType
      );
      setFilteredPolicyData(filteredPolicies);
    }

    const updatedStatsData = statsData.map((stat) => ({
      ...stat,
      active: stat.title === policyType,
    }));
    setStatsData(updatedStatsData);
  };

  const { modalOpen } = location.state || {};

  const [isModalOpen, setIsModalOpen] = useState(modalOpen||false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen||isEditModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen,isEditModalOpen]);

  const openModal = () => {
    navigate(`../policies`,{
      state: {
        modalOpen:true
      }})
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Policies"
            title="Dashboard"
            subtitle="Policies"
            // modal="#add_policy"
            name="Add Policy"
            page='policies'
            action='create'
            openModal={openModal}
          />

          <div className="row">
            {statsData?.map((stat, index) => (
              <div
                className="col-md-4 d-flex"
                key={index}
                onClick={() => filterPolicies(stat?.title)}
                style={{cursor:'pointer'}}
              >
                <div
                  className={`stats-info w-100 ${
                    stat.active ? "orange-bg" : ""
                  }`}
                >
                  <h6 className={` ${stat.active ? "text-white" : ""}`}>{stat?.title}</h6>
                  {stat?.subText ? (
                    <h4 className={` ${stat.active ? "text-white" : ""}`}>
                      {stat?.value} <span>{stat?.subText}</span>
                    </h4>
                  ) : (
                    <h4 className={` ${stat.active ? "text-white" : ""}`}>{stat?.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={filteredPolicyData}
                  rowKey={(record) => record?.id}
                   scroll={{ x: true }} 
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddandEditPolicy policy={policyEditData} setPolicyData={setPolicyData} selectedDepartmentId= {selectedDepartmentId} selectedDesignationtId ={selectedDesignationtId} isModalOpen={isModalOpen} isEditModalOpen={isEditModalOpen} setIsModalOpen={setIsModalOpen} setIsEditModalOpen={setIsEditModalOpen}/>
      <DeleteModal Name="Delete Policy" confirmDelete={confirmDelete} />
      {(isModalOpen||isEditModalOpen) && <div className="modal-backdrop fade show"></div>}

    </>
  );
};

export default Policies;
