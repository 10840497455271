/* eslint-disable jsx-a11y/img-redundant-alt */
// Pending : 1- Location check when ckeck In and Out, workstatus when checkout.
import React, { useEffect, useState } from "react";
import User from "../../../assets/img/icons/user3.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import config from "../../../config";
import axios from "axios";
import { formatDate } from "../../../validation";
import { capitalizeFirstLetter } from "../../../utils/basicFunctions";
import EditCandidatesModal from "../../../components/Administration/Jobs/CandidatesModal/EditCandidatesModal";
import ImageEditor from "../../../components/ImageEditor";

const Profile = () => {
  const { id } = useParams();
  const [basicDetails, setBasicsDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState([]);
  const [qualificationDetails, setQualificationDetails] = useState([]);
  const [accountDetails, seAccountDetails] = useState({});
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [editTrue, setEditedTrue] = useState(false);
  const [res, setRes] = useState({});
  const [employeeID, setEmployeeID] = useState("");
  const [fieldOne, setFieldOne] = useState(true);
  // const [fieldThree, setFieldThree] = useState(false);
  const [fieldFour, setFieldFour] = useState(false);
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isEditModalOpen]);

  const employeeAttendance=()=>{
    axios
      .get(`${config.API_BASE_URL}api/attendance/${id}`)
      .then((res) => {
        const today = new Date();
        const todayDate = today.toISOString().split('T')[0];
        const attendance = res?.data?.filter(record => record.Date == todayDate);
        setAttendance(attendance);
      })
      .catch((err) => console.error("Error fetching attendance data:", err));
  }

  useEffect(() => {
    employeeAttendance();
  }, []);

  const navigate = useNavigate();
  console.log("address from db : ", addressDetails);
  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(" employeeID : ", employeeID);
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}employees/${id}`, configuration)
      .then((res) => {
        console.log("data from res nitesh : ", res);
        setRes(res);
        setBasicsDetails(res?.data?.result?.basicDetails);
        setAddressDetails(res?.data?.result?.addresses);
        setQualificationDetails(res?.data?.result?.qualifications);
        setEmploymentDetails(res?.data?.result?.employmentDetails[0]);
        seAccountDetails(res?.data?.result?.accountDetails[0]);
      })
      .catch((err) => console.log(err));
  }, [editTrue, employeeID]);
  console.log("employment fetails--->", employmentDetails);

  const navigateHandler = () => {
    console.log("clicking");
    // navigate(`/edit-employee/${id}`);
  };

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}employees/${id}`, configuration)
      .then((res) => {
        console.log("data from res", res);
        setRes(res);
      })
      .catch((err) => console.log(err));
  }, [employeeID]);
  console.log(" res : nit", res);
  console.log("Employee id", employeeID);

  const handlePunchOut = (e) => {
    // add loader
    // something went wrong message popup
    e.preventDefault();
    const token = localStorage.getItem("token");
    const companyName = localStorage.getItem("companyName");

    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }

    const configuration = {
      headers: {
        Authorization: `Bearer ${token}`,
        CompanyName: companyName,
      },
    };

    axios
      .post(`${config.API_BASE_URL}auth/logout`, {
        'employeeId': id
      }, configuration)
      .then((res) => {
        console.log("Logout successful:", res.data.success);
        if( res.data.success === true){
          employeeAttendance();
        }
      })
      .catch((err) => {
        console.error("Error during logout:", err);
      });
  };

  const handlePunchIn= (e) =>{
    e.preventDefault();
    axios.post(`${config.API_BASE_URL}api/attendance/punch-in`,{"employeeId": id})
    .then(()=>{
      employeeAttendance();
    })
    .catch(err=>{
      console.error(err)
    })
    .finally(()=>{
      // loader close
      // message marked punch in
    })
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsMainModalOpen(true);
                          }}
                        >
                          <img
                            src={
                              basicDetails?.photo
                                ? `${config.IMAGE_URL}${basicDetails?.photo}`
                                : User
                            }
                            alt="User Image"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {capitalizeFirstLetter(basicDetails?.first_name)}{" "}
                              {""}
                              {capitalizeFirstLetter(
                                basicDetails?.middle_name
                              )}{" "}
                              {capitalizeFirstLetter(basicDetails?.last_name)}
                            </h3>
                            <h6 className="text-muted">
                              {employmentDetails?.department_name}
                            </h6>
                            <small className="text-muted">
                              {employmentDetails?.designation_name}
                            </small>
                            <div className="staff-id">
                              Employee ID : {basicDetails?.id}
                            </div>
                            <div className="small doj text-muted">
                              Date of Join :
                              {employmentDetails?.joining_date &&
                                formatDate(employmentDetails?.joining_date)}
                            </div>
                            {/* <div style={{display:'flex', gap:'10px'}}> */}
                            {/* <div className="staff-msg">
                              <Link
                                
                                className="btn btn-custom" 
                                to="#"
                                onClick={(e)=>{
                                  e.preventDefault();
                                  setIsEditModalOpen(true);
                                }}
                              >
                                Edit Profile
                              </Link>
                            </div> */}
                            {attendance.length !=0 &&<div className="staff-msg title">
                              <Link
                                className="btn btn-custom" 
                                style={{background:`${!attendance[0]?.punch_status ? 'red':'green'}`, color:'white'}}
                                to="#"
                                onClick={(e)=>{
                                  e.preventDefault();
                                  !attendance[0]?.punch_status ? handlePunchOut(e) : handlePunchIn(e);
                                }}
                              >
                                {!attendance[0]?.punch_status ? 'Check Out' : 'Check In'}
                              </Link>
                            </div>}
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <Link to={`tel:${basicDetails?.number}`}>
                                  {basicDetails?.number}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Official Email:</div>
                              <div className="text">
                                <Link
                                  to={`mailto:${basicDetails?.official_email}}`}
                                >
                                  {basicDetails?.official_email}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">
                                {basicDetails?.date_of_birth &&
                                  formatDate(basicDetails?.date_of_birth)}
                              </div>
                            </li>
                            {/* <li>
                            <div className="title">Address:</div>
                            <div className="text">
                              {capitalizeFirstLetter(
                                addressDetails[0]?.address
                              )}
                            </div>
                          </li> */}
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">{basicDetails?.gender}</div>
                            </li>
                            {/* <li>
                              <div className="title">Reports to:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    <img src={Avatar_16} alt="User Image" />
                                  </div>
                                </div>
                                <Link to="profile">
                                  {userData.supervisor.name}
                                </Link>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pro-edit">
                      <Link
                        data-bs-target="#edit_employee"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        // to={`../edit-employee/${id}`}
                        onClick={(e) => {
                          setEmployeeID(id);
                        }}
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Bank &amp; Statutory
                      <small className="text-danger ms-1">(Admin Only)</small>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Assets
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <ProfileTab
            basicDetails={basicDetails}
            addressDetails={addressDetails}
            employmentDetails={employmentDetails}
            qualificationDetails={qualificationDetails}
            accountDetails={accountDetails}
            setBasicsDetails={setBasicsDetails}
            seAccountDetails={seAccountDetails}
            setAddressDetails={setAddressDetails}
            setQualificationDetails={setQualificationDetails}
            setEditedTrue={setEditedTrue}
            editTrue={editTrue}
            // setFieldThree={setFieldThree}
            setFieldFour={setFieldFour}
            id={id}
            setFieldOne={setFieldOne}
          />
          <EditCandidatesModal
            employID={id}
            setEditedTrue={setEditedTrue}
            // fieldThree={fieldThree}
            // setFieldThree={setFieldThree}
            fieldFour={fieldFour}
            setFieldFour={setFieldFour}
            fieldOne={fieldOne}
            setFieldOne={setFieldOne}
            res={res}
            isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          />
        </div>
        {(isEditModalOpen) && <div className="modal-backdrop fade show"></div>}
      </div>
      <ImageEditor
        photo={basicDetails?.photo}
        dummyPhoto={User}
        isMainModalOpen={isMainModalOpen}
        setIsMainModalOpen={setIsMainModalOpen}
        employeeId={id}
        setEditedTrue={setEditedTrue}
        editTrue={editTrue}
      />
    </>
  );
};

export default Profile;
