import React, { useEffect, useState } from "react";
import "../../../assets/css/iconsoffunction.css";
import Taskbox from "../../../assets/img/icons/taskbox.png";
import Employees from "../../../assets/img/icons/staff.png";
import Dashboard from "../../../assets/img/icons/dashboard2.png";
import Timeoff from "../../../assets/img/icons/leave.png";
import Calendar from "../../../assets/img/icons/calendar.png";
import Reimbursement from "../../../assets/img/icons/reimbursement.png";
import MyPay from "../../../assets/img/icons/mypay.png";
import Documents from "../../../assets/img/icons/document.png";
import Linkdin from "../../../assets/img/icons/linkedin.png";
import Attendance from "../../../assets/img/icons/attendance.png";
import { Link, useNavigate } from "react-router-dom";

const IconsOfFunctionalities = () => {

  const role_id = localStorage.getItem("employee_role");

  const [companyName, setCompanyName] = useState("");
  const Navigate = useNavigate();

  const images = [
    {
      src: Dashboard,
      alt: "dashboard",
      link: "../employee-dashboard",
      text: "Dashboard",
    },
    ...(role_id !=3 ? [{
      src: Employees,
      alt: "employees",
      link: "../employees",
      text: "Employees",
    }]:[]),
    { src: Taskbox, alt: "taskbox", link: "../taskbox", text: "Task Box" },
    {
      src: Documents,
      alt: "documents",
      link: "../my-document",
      text: "Documents",
    },
    {
      src: Attendance,
      alt: "Attendance",
      link: "../attendance-employee",
      text: "Attendance",
    },
    {
      src: Timeoff,
      alt: "timeoff",
      link: "../leave-dashboard",
      text: "Time Off",
    },
    { src: Calendar, alt: "calendar", link: "../events", text: "Calendar" },
    {
      src: Reimbursement,
      alt: "reimbursement",
      link: "../reimbursement",
      text: "Reimbursement",
    },
    { src: MyPay, alt: "mypay", link: "../mypay", text: "My Pay" },
    // {
    //   src: Linkdin,
    //   alt: "linkedin",
    //   link: "../employee-dashboard",
    //   text: "Linkedin",
    // },
  ];

  useEffect(() => {
    const userToken = localStorage.getItem("user");
    if (userToken) {
      const user = JSON.parse(userToken);
      if (user && user.company) {
        setCompanyName(user.company.name);
      }
    }
  }, []);

  const handlenavigation = (link) => {
    Navigate(`${companyName}/${link}`);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid d-flex align-items-center justify-content-center min-vh-100 top-margin ">
        <div className="row mt-5 ">
          {images.map((image, index) => (
            <div className="col-4 col-md-4 mb-4" key={index}>
              <Link to={`${image.link}`}>
                <div className="icon-container">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="img-fluid icon-image"
                    style={{ background: "none" }} // Ensure no background
                  />
                  <p>{image.text}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IconsOfFunctionalities;
