import { Flex } from "antd";
import React from "react";

const DateSelector = ({ selectedDate, onDateChange }) => {
  const formatDate = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    if (value) {
      onDateChange(new Date(value));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <p style={{ fontSize: "16px", fontWeight: "bold" }}>Choose Date </p>
      <input
        style={{ cursor: "pointer", width: "auto" }}
        type="date"
        value={formatDate(selectedDate)}
        onChange={handleDateChange}
        className="form-control mb-3"
      />
    </div>
  );
};

export default DateSelector;
