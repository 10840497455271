import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const CompanyCoordinatesError = ({ errorMessage, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Location Issue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMessage || "You're not within the Company location."}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Retry
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyCoordinatesError;
