import React, { useEffect, useState } from "react";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
} from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Pagination, Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import axios from "axios";
import config from "../../../config";
import { getData } from "./../../../utils/api";
import EditCandidatesModal from "../../../components/Administration/Jobs/CandidatesModal/EditCandidatesModal";
import { useRefresh } from "../../../context/RefreshContext";
import User from "../../../assets/img/icons/user3.png";
import { companyID } from './../../../auth';
import ProtectedButton from "../../../Routes/Approuter/ProtectedButton";

const EmployeeList = () => {
  const [paginationValue , setPaginationValue] = useState(10)
  const [employeeData, setEmployeeData] = useState([]);
  const [deletedId, setDeletedID] = useState("");
  const [renderState, setRenderState] = useState(false);
  const [res, setRes] = useState({});
  // const [fieldThree, setFieldThree] = useState();
  const [fieldFour, setFieldFour] = useState(false);
  const [fieldOne, setFieldOne] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeletedTrue , setIsDeletedTrue] = useState(false);

  const { refresh } = useRefresh();

  useEffect(() => {
    if (isModalOpen||isEditModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isModalOpen,isEditModalOpen]);

  let token = localStorage.getItem("token");
  const configuration = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleDelete = (id) => {
    setDeletedID(id);
  };

  const employeeID = localStorage.getItem("employeeID");
  const company_id  = companyID();

  useEffect(() => {
   
    axios
      .get(
        `${config.API_BASE_URL}employees/employeeDetails/${company_id}`,
        configuration
      )
      .then((res) => {
        const filteredData = res?.data?.filter((item) => item.id != employeeID).map((item) => {
          return {
              ...item,
              first_name: item.first_name || "",
              middle_name: item.middle_name || "",
              last_name: item.last_name || "",
              official_email: item.official_email || "--------",
              photo: item.photo || "",
              number: item.number || "--------",
              department_id: item.department_id || "",
              designation_id: item.designation_id ||"",
              department_name: item.department_name || "--------",
              designation_name: item.designation_name || "--------"
              // Add other fields as needed
          };
      });
        setEmployeeData(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("employee list--.", employeeData);
  }, [refresh, renderState , isDeletedTrue ]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to={`profile/${record.id}`} className="avatar">
          <span>
            <img
              alt=""
              src={record.photo ? `${config.IMAGE_URL}${record.photo}` : User}
              style={{borderRadius:'50%'}}
            />
          </span>
          </Link>
          <Link to={`profile/${record.id}`}>
          <span>
            {record?.first_name} {record?.middle_name} {record?.last_name}
          </span>
          </Link>
        </span>
      ),
      // sorter: (a, b) => a.first_name.length - b.first_name.length,
    },

    {
      title: "Email",
      dataIndex: "official_email",
      // sorter: (a, b) => a.official_email.length - b.official_email.length,
    },

    {
      title: "Mobile",
      dataIndex: "number",
      // sorter: (a, b) => a.number.length - b.number.length,
    },
    {
      title: "Department",
      dataIndex: "department_name",
      // sorter: (a, b) => a.department_name.length - b.department_name.length,
    },
    {
      title: "Designation",
      dataIndex: "designation_name",
      // sorter: (a, b) => a.designation_name.length - b.designation_name.length,
    },
    {
      title: "Action",
      // sorter: true,
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <ProtectedButton  page="employees" action="edit">
          <Link
              className="dropdown-item"
              // to={`../edit-employee/${record.id}`}
              to="#"
              onClick={(e) => {
                e.preventDefault();
                editHandler(record.id);
                setIsEditModalOpen(true);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
        </ProtectedButton>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
              onClick={() => handleDelete(record.id)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const [employID, setEmployId] = useState("");
  const configure = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const editHandler = async (id) => {
    console.log("id found", id);
    setEmployId(id);
    const res = await axios.get(
      `${config.API_BASE_URL}employees/${id}`,
      configure
    );
    setRes(res);
  };

  const confirmDelete = async () => {
    if (deletedId) {
      const res = await axios.put(
        `${config.API_BASE_URL}employees/delete-employee/${deletedId}`,
        {},
        configuration
      );
      // if(res?.data){
      //   setIsDeletedTrue(true);
      // }
      console.log("Deleted data response", res);
      const updatedData = await axios.get(
        `${config.API_BASE_URL}employees/employeeDetails/${company_id}`,
        configuration
      )
      if(updatedData?.data){
        const filteredData = updatedData?.data?.filter((item) => item.id != employeeID).map((item) => {
          return {
              ...item,
              first_name: item.first_name || "",
              middle_name: item.middle_name || "",
              last_name: item.last_name || "",
              official_email: item.official_email || "--------",
              photo: item.photo || "",
              number: item.number || "--------",
              department_id: item.department_id || "",
              designation_id: item.designation_id ||"",
              department_name: item.department_name || "--------",
              designation_name: item.designation_name || "--------"
              // Add other fields as needed
          };
        });
        setEmployeeData(filteredData);
        // console.log("filtered data --->", filteredData);
        // setEmployeeData(filteredData);
        setDeletedID(null);
      }
      // Reset the delete ID
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  console.log( 'isModalOpen nit : ',isModalOpen);

  const [listView, setListView] =useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * paginationValue;
  const endIndex = startIndex + paginationValue;
  const currentEmployees = employeeData?.slice(startIndex, endIndex);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPaginationValue(pageSize);
  };

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            name="Add Employee"
            // Linkname="employees"
            // Linkname1="employees"
            openModal={openModal}
            page='employees'
            canAdd='create'

          />
          {/* /Page Header */}
          {/* <EmployeeListFilter /> */}
          <SearchBox  setPaginationValue={setPaginationValue} setListView={setListView} listView={listView}/>

          {listView ? 
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={employeeData}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: paginationValue }}
                  scroll={{ x: true }} 
                  tableLayout="auto"
                  // style={{overflowX :"hidden"}}   
                />
              </div>
            </div>
          </div>
          :
          <>
          <div className="row">
            {currentEmployees.map((employee) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={employee.id}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to={`profile/${employee.id}`} className="avatar">
                      <img src={employee.photo ? `${config.IMAGE_URL}${employee.photo}` : User} alt="" />
                    </Link>
                  </div>
                  <div className="dropdown profile-action">
                  <Link
                    to="#"
                    className="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="material-icons">more_vert</i>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                  <ProtectedButton  page="employees" action="edit">
                  <Link
                      className="dropdown-item"
                      // to={`../edit-employee/${employee.id}`}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        editHandler(employee.id);
                        setIsEditModalOpen(true);
                      }}
                    >
                      <i className="fa fa-pencil m-r-5" /> Edit
                    </Link>
                </ProtectedButton>
                    <Link
                      className="dropdown-item"
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_employee"
                      onClick={() => handleDelete(employee.id)}
                    >
                      <i className="fa fa-trash m-r-5" /> Delete
                    </Link>
                  </div>
                </div>
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to={`/profile/${employee.id}`}>{employee?.first_name} {employee?.middle_name} {employee?.last_name}</Link>
                  </h4>
                  <div className="small text-muted">{employee?.designation_name ? employee?.designation_name : '-'}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end mb-4">
          <Pagination
            current={currentPage}
            pageSize={paginationValue}
            total={employeeData.length}
            // showSizeChanger
            onChange={handlePageChange}
            // pageSizeOptions={['5', '10', '15', '20']}
          />
        </div>
          </>}
        </div>
        {/* /Page Content */}
        <EditCandidatesModal
          employID={employID}
          setRenderState={setRenderState}
          fieldOne={fieldOne}
          setFieldOne={setFieldOne}
          fieldFour={fieldFour}
          setFieldFour={setFieldFour}
          res={res}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
        />
        <AllEmployeeAddPopup setEmployeeData={setEmployeeData} 
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        />
        <DeleteModal Name="Delete Employee" confirmDelete={confirmDelete} />
      </div>
      {(isModalOpen||isEditModalOpen) && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default EmployeeList;
