import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import CalendarModal from "../../../../../components/modelpopup/CalendarModal";
import { getAllLeaves } from "../../../../../services/leaveService";
import { getLabelByValue } from "../../../../../utils/getLabelByValue";
import axios from "axios";
import config from "../../../../../config";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "./calendar.css";
import { formatDateInTimezone } from "../../../../../utils/formatDateInTimezone";
import { addOneDayInDate } from "../../../../../utils/addOneDayInDate";
import { companyID } from "../../../../../auth";

const Calendar = () => {
  const [weekendsVisible] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [holidays, setHolidays] = useState([]);

  console.log("calender leaves : ", leaves);

  const employeeId = localStorage.getItem("employeeID");

  const leavetype = [
    { value: "", label: "Select leave type" },
    { value: 1, label: "Sick Leave" },
    { value: 2, label: "Casual Leave" },
    { value: 3, label: "Vacation" },
    { value: 4, label: "Unpaid Leave" },
  ];

  const handleEventDidMount = (info) => {
    tippy(info.el, {
      content: `<strong>${info.event.extendedProps.name}</strong>`,
      allowHTML: true,
    });
  };

  const generateEventColor = (id, data) => {
    const idColorMap = {};
    const colors = [
      "bg-success",
      "bg-pink",
      "bg-yellow",
      "bg-cyan",
      "bg-indigo",
      "bg-warning",
      "bg-primary",
      "bg-secondary",
      "bg-purple",
      "bg-teal",
    ];
    data.forEach((leave, index) => {
      if (!idColorMap.hasOwnProperty(leave.id)) {
        idColorMap[leave.id] = colors[index % colors.length];
      }
    });
    return idColorMap[id];
  };

  // useEffect(() => {
  //   const fetchLeaves = async () => {
  //     try {
  //       setLoading(true);
  //       const data = await getAllLeaves(employeeId);
  //       const leave = data.map((leave) => ({
  //         title: getLabelByValue(leavetype, leave.leave_type),
  //         start: isoToEpoch(leave.start_date),
  //         end: isoToEpoch(leave.end_date),
  //         className: "bg-success",
  //       }));
  //       setLeaves(leave);
  //     } catch (err) {
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchLeaves();
  // }, []);
  const company_id = companyID();
  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    axios.get(`${config.API_BASE_URL}holidays/${company_id}/${currentYear}`).then((res) => {
      const transformedHolidays = res.data.map((holiday) => ({
        title: holiday.occasion,
        date: holiday.date,
        allDay: true,
        extendedProps: {
          name: "Gazetted Holidays",
        },
      }));
      setHolidays(transformedHolidays);
    }).catch(err=> console.log("err" , err))
  }, []);

  const [runState , setRunState] = useState(false);

  useEffect(() => {
    const employee_id = localStorage.getItem("employeeID");
    async function fetchData() {
      try {
        let combinedData = [];
        setLoading(true);
        const response = await axios.get(
          `${config.API_BASE_URL}leaveApplications/${employee_id}`
        );

        const response2 = await axios.get(
          `${config.API_BASE_URL}leave-for-approval/${employee_id}`
        );

        const response3 = await axios.get(
          `${config.API_BASE_URL}employeseApproval/${employee_id}`
        );

        const leave = response.data.map((leave, index) => ({
          title: getLabelByValue(leavetype, leave.leave_type),
          start: formatDateInTimezone(leave.start_date),
          end: formatDateInTimezone(addOneDayInDate(leave.end_date)),
          allDay: true,
          className: generateEventColor(leave.id, response.data),
          extendedProps: {
            name: leave.first_name + " " + leave.last_name,
          },
        }));

        const approvalData = response2.data.result.map((leave, index) => ({
          title: getLabelByValue(leavetype, leave.leave_type),
          start: formatDateInTimezone(leave.start_date),
          end: formatDateInTimezone(addOneDayInDate(leave.end_date)),
          allDay: true,
          className: generateEventColor(leave.id, response.data),
          extendedProps: {
            name: leave.first_name + " " + leave.last_name,
          },
        }));

        console.log("approvalData" , approvalData);

        const approvalData3 = response3.data.result.map((leave, index) => ({
          title: getLabelByValue(leavetype, leave.leave_type),
          start: formatDateInTimezone(leave.start_date),
          end: formatDateInTimezone(addOneDayInDate(leave.end_date)),
          allDay: true,
          className: generateEventColor(leave.id, response.data),
          extendedProps: {
            name: leave.first_name + " " + leave.last_name,
          },
        }));

          if (leave.length > 0) {
            combinedData = [...combinedData, ...leave];
          }
          if (approvalData.length > 0) {
            combinedData = [...combinedData, ...approvalData];
          }
          if (approvalData3.length > 0) {
            combinedData = [...combinedData, ...approvalData3];
          }
        setLeaves(combinedData);
        setRunState(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Leaves:", error);
        throw error;
      }
    }
    fetchData();
  }, []);


  // useEffect(()=>{
  //   const handlerApprovalStatus = async () => {
  //     try {
  //       const employeeID = localStorage.getItem("employeeID");
  //       const response = await axios.get(
  //         `${config.API_BASE_URL}employeseApproval/${employeeID}`
  //       );
  //       const approvalData = response.data.result.map((leave, index) => ({
  //         title: getLabelByValue(leavetype, leave.leave_type),
  //         start: formatDateInTimezone(leave.start_date),
  //         end: formatDateInTimezone(addOneDayInDate(leave.end_date)),
  //         allDay: true,
  //         className: generateEventColor(leave.id, response.data),
  //         extendedProps: {
  //           name: leave.first_name + " " + leave.last_name,
  //         },
  //       }));
     
  //       const response2 = await axios.get(
  //         `${config.API_BASE_URL}leave-for-approval/${employeeID}`
  //       );
  //       const approvalData2 = response2.data.result.map((leave, index) => ({
  //         title: getLabelByValue(leavetype, leave.leave_type),
  //         start: formatDateInTimezone(leave.start_date),
  //         end: formatDateInTimezone(addOneDayInDate(leave.end_date)),
  //         allDay: true,
  //         className: generateEventColor(leave.id, response.data),
  //         extendedProps: {
  //           name: leave.first_name + " " + leave.last_name,
  //         },
  //       }));
  //       console.log("approvalData" , response);
        
  //         let combinedData = [];
        
  //         if (approvalData.length > 0) {
  //           combinedData = [...combinedData, ...approvalData];
  //         }
  //         if (approvalData2.length > 0) {
  //           combinedData = [...combinedData, ...approvalData2];
  //         }
          
  //         if (combinedData.length > 0) {
  //           setLeaves(combinedData);
  //           setLoading(false);
  //         }
    
      
  //     } catch (err) {
  //       console.log("err");
  //     }
  //   };
  //   handlerApprovalStatus();

  // },[runState])

  console.log("leaves data from calendar",leaves);

  // const holidays = [
  //   {
  //     title: "New Year's Day",
  //     date: "2024-07-28",
  //     extendedProps: {
  //       employeeName: "National Holidays",
  //     },
  //   },
  //   {
  //     title: "Independence Day",
  //     date: "2024-07-04",
  //     extendedProps: {
  //       employeeName: "National Holidays",
  //     },
  //   },
  //   {
  //     title: "Christmas Day",
  //     date: "2024-07-25",
  //     extendedProps: {
  //       employeeName: "National Holidays",
  //     },
  //   },
  //   // Add more holidays as needed
  // ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Calendar</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="../">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Calendar</li>
              </ul>
            </div>
            {/* <div className="col-auto float-end ms-auto">
              <Link
                to="#"
                className="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_event"
              >
                <i className="fa-solid fa-plus" /> Add Event
              </Link>
            </div> */}
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* Calendar */}
                    <div id="calendar" />
                    {!loading && (
                      <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={weekendsVisible}
                        events={holidays}
                        initialEvents={leaves}
                        displayEventTime={false}
                        eventDidMount={handleEventDidMount}
                        height="auto" 
                        
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CalendarModal />
    </div>
  );
};

export default Calendar;
