import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem, ProjectDetails } from "./ProfileContent";
import { formatDate } from "../../../validation";
import "../../../assets/css/profileTab.css";
import axios from "axios";
import config from "../../../config";
// import styled from 'styled-components';

// const NoBorderInput = styled.input`
//   border: none;
//   outline: none;
// `;

const ProfileTab = ({
  basicDetails,
  addressDetails,
  employmentDetails,
  qualificationDetails,
  accountDetails,
  setBasicsDetails,
  seAccountDetails,
  setAddressDetails,
  setQualificationDetails,
  setEditedTrue,
  editTrue,
  setFieldFour,
  setFieldOne,
  id
}) => {
  const [editable, setEditable] = useState(false);
  // const [employmentEditable, setEmploymentEditable] = useState(false);
  const [bankInfo, setBankInfo] = useState(false);
  const [editAddressDetails, setEditAddressDetails] = useState(false);
  const [dob, setDob] = useState(formatDate(basicDetails?.date_of_birth));
  const [editEducationInfo, setEditEducationInfo] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [token, setToken] = useState(null);
  // const [id, setId] = useState(null);

  const navigate =useNavigate()

  useEffect(()=>{
    setToken(localStorage.getItem("token"));
    // setId(localStorage.getItem("employeeID"));
 },[])

const configuration = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
};

  // console.log(" qualificationDetails : ", qualificationDetails);
  // console.log(" addressDetails : ", addressDetails);
  // console.log(" accountDetails : ", accountDetails);
  // console.log(" isSame : ", isSame);
  // console.log(" setId : ", id);
  // console.log(" setToken : ", token);

  const inputRef = useRef(null);
  // const employmentDetailsInputRef = useRef(null);
  const bankInfoInputRef = useRef(null);
  const addressInputRef = useRef(null);
  const educationInputRef = useRef(null);

  const addressInput = () => {
    if (addressInputRef?.current) {
      addressInputRef?.current.focus();
    }
  };

  const educationInput = () => {
    if (educationInputRef?.current) {
      educationInputRef?.current.focus();
    }
  };

  useEffect(() => addressInput(), [editAddressDetails]);
  useEffect(() => educationInput(), [editEducationInfo]);

  const editPersonalInfo = async () => {
    try {
      const { data } = await axios.put(
        `${config.API_BASE_URL}employees/${id}`,
        basicDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("updated data : ", data);
      setEditable(false);
    } catch (error) {
      console.log("updated error : ", error);
    }
  };

  const marital_status = [
    { value: 1, label: "Select status" },
    { value: 2, label: "Single" },
    { value: 3, label: "Married" },
    { value: 4, label: "Unmarried" },
  ];

  const gender = [
    { value: 1, label: "Select Gender" },
    { value: 2, label: "Male" },
    { value: 3, label: "Female" },
    { value: 3, label: "Other / Prefer not to say" },
  ];

  const nationality = [
    { value: 1, label: "Indian" },
    { value: 2, label: "Albanian" },
    { value: 3, label: "Algerian" },
    { value: 4, label: "American" },
    { value: 5, label: "Andorran" },
    { value: 6, label: "Angolan" },
    { value: 7, label: "Antiguans" },
    { value: 8, label: "Argentinean" },
    { value: 9, label: "Armenian" },
    { value: 10, label: "Australian" },
    { value: 11, label: "Austrian" },
    { value: 12, label: "Azerbaijani" },
    { value: 13, label: "Bahamian" },
  ];

  const countrylist = [
    { value: 1, label: "Select Country" },
    { value: 2, label: "India" },
    { value: 3, label: "China" },
    { value: 4, label: "America" },
    { value: 5, label: "Japan" },
  ];

  const editAccountDetails = async (employeeId) => {
    try {
      if (employeeId) {
        const AccountData = await axios.put(
          `${config.API_BASE_URL}employees/account/${id}`,
          accountDetails,
          configuration
        );
        console.log("updated AccountData : ", AccountData);
        if (AccountData.data.success === true) setBankInfo(false);
      } else {
        const accountData = { ...accountDetails, employee_id: id };

        const account_data = await axios.post(
          `${config.API_BASE_URL}employees/account_details`,
          accountData,
          configuration
        );
        console.log("Post account :", account_data);
        if (account_data.data.success === true) {
          setBankInfo(false);
          setEditedTrue(true);
        }
      }
    } catch (error) {
      console.log("updated account error : ", error);
    }
  };

  const editAddress = (id, value) => {
    console.log("id : ", id, "value : ", value);
    setAddressDetails((prev) =>
      prev.map((address) =>
        address.id === id ? { ...address, ...value } : address
      )
    );
  };

  const addressInputChange = (e, id) => {
    const { name, value } = e.target;
    if (addressDetails[0]?.id && addressDetails[1]?.id) {
      editAddress(id, { [name]: value });
    } else {
      setAddressDetails((prevDetails) =>
        prevDetails.map((address, i) =>
          i === id ? { ...address, [name]: value } : address
        )
      );
    }
  };

  const editAddressinfo = async () => {
    let updatedAddresses;
    if (isSame) {
      const currentAddress = addressDetails.find(
        (address) => address.type === "current_address"
      );
      updatedAddresses = addressDetails.map((address) =>
        address.type === "permanent_address"
          ? {
              ...address,
              address: currentAddress.address,
              state: currentAddress.state,
              pincode: currentAddress.pincode,
              city: currentAddress.city,
              country: currentAddress.country,
            }
          : address
      );
      setAddressDetails(updatedAddresses);
      console.log("same updatedAddresses : ", updatedAddresses);
    } else {
      updatedAddresses = addressDetails;
      console.log("not same updatedAddresses : ", updatedAddresses);
    }
    try {
      if (addressDetails[0]?.id || addressDetails[1]?.id) {
        const addressData = await axios.put(
          `${config.API_BASE_URL}employees/address/${id}`,
          [updatedAddresses[0], updatedAddresses[1]],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEditAddressDetails(false);
        setIsSame(false);
        navigate(`../employees/profile/${id}`)
        console.log(" Address update  : ", addressData);
      } else {
        const addressData = await axios.post(
          `${config.API_BASE_URL}employees/address`,
          [updatedAddresses[0], updatedAddresses[1]],
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEditAddressDetails(false);
        console.log(" Address post  : ", addressData);
        if (addressData.data.success === true) {
          setEditedTrue(true);
          setIsSame(false);
          navigate(`../employees/profile/${id}`)
        }
      }
    } catch (error) {
      console.log(
        " Address error : ",
        error,
        " : ",
        addressDetails,
        addressDetails[1]
      );
    }
  };

  const editEducationinfo = async () => {
    try {
      const qualificationsData = { qualifications: qualificationDetails };
      const qualificationData = await axios.put(
        `${config.API_BASE_URL}employees/qualification/${id}`,
        qualificationsData,
        configuration
      );
      setEditEducationInfo(false);
      console.log(" Update qualification : ", qualificationData);
    } catch (error) {
      console.log(
        " Update qualification error : ",
        error,
        " : ",
        qualificationDetails
      );
    }
  };

  const editEducation = (id, value) => {
    setQualificationDetails((prev) =>
      prev.map((edu) => (edu.id === id ? { ...edu, ...value } : edu))
    );
  };

  const educationInputChange = (e, id) => {
    const { name, value } = e.target;
    editEducation(id, { [name]: value });
  };

  const sameAddress = (e) => {
    const { checked } = e.target;
    setIsSame(checked);
    const currentAddress = addressDetails.find(
      (address) => address.type === "current_address"
    );
    if (checked) {
      const updatedAddresses = addressDetails.map((address) =>
        address.type === "permanent_address"
          ? {
              ...address,
              address: currentAddress.address,
              state: currentAddress.state,
              pincode: currentAddress.pincode,
              city: currentAddress.city,
              country: currentAddress.country,
            }
          : address
      );
      setAddressDetails(updatedAddresses);
    } else {
      const updatedAddresses = addressDetails.map((address) =>
        address.type === "permanent_address"
          ? {
              ...address,
              address: "",
              state: "",
              pincode: "",
              city: "",
              country: "",
            }
          : address
      );
      setAddressDetails(updatedAddresses);
    }
  };
  const [countries, setCountries] = useState([]);
  const [selectedCountryID, setSelectedCountryID] = useState("");
  const [selectedStateID, setSelectedStateID] = useState("");
  const [selectedCityID, setSelectedCityID] = useState("");
  const [states, setSatates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedPermanentCountryID, setSelectedPermanentCountryID] =
  useState("");
const [selectedPermanentStateID, setSelectedPermanentStateID] = useState("");
const [selectedPermanentCityID, setSelectedPermanentCityeID] = useState("");
const [permanentStates, setPermanentSatates] = useState([]);
  const [permanentCities, setPermanentCities] = useState([]);

  console.log('selectedStateID||addressDetails[0]?.state',addressDetails[0]?.country)

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}countries`)
      .then((res) => setCountries(res?.data?.result))
      .catch((err) => console.log(err));
  }, [addressDetails]);

  useEffect(() => {
    setSelectedCityID("");
    setSelectedStateID("");
    setCities([]);
    if(selectedCountryID||addressDetails[0]?.country)
      {
      axios
      .get(`${config.API_BASE_URL}states/${selectedCountryID||addressDetails[0]?.country}`)
      .then((res) => setSatates(res?.data?.result))
      .catch((err) => console.log(err))
    }
  }, [selectedCountryID,addressDetails]);

  useEffect(() => {
    if(selectedStateID||addressDetails[0]?.state)
      {
        axios
      .get(`${config.API_BASE_URL}city/${selectedStateID||addressDetails[0]?.state}`)
      .then((res) => setCities(res?.data?.result))
      .catch((err) => console.log(err))
    }
  }, [selectedStateID,addressDetails]);

  useEffect(() => {
    setSelectedPermanentCityeID("");
    setSelectedPermanentStateID("");
    setPermanentCities([]);
    if(selectedPermanentCountryID||addressDetails[1]?.country){axios
      .get(`${config.API_BASE_URL}states/${selectedPermanentCountryID||addressDetails[1]?.country}`)
      .then((res) => setPermanentSatates(res?.data?.result))
      .catch((err) => console.log(err));}
  }, [selectedPermanentCountryID,addressDetails]);

  useEffect(() => {
    if(selectedPermanentStateID||addressDetails[1]?.state){axios
      .get(`${config.API_BASE_URL}city/${selectedPermanentStateID||addressDetails[1]?.state}`)
      .then((res) => setPermanentCities(res?.data?.result))
      .catch((err) => console.log(err));}
  }, [selectedPermanentStateID,addressDetails]);

  return (
    <>
      <div className="tab-content profileSection">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Personal Details
                    <Link
                      to="#"
                      className={!editable ? "edit-icon" : "active-icon"}
                      onClick={(e) => {
                        e.preventDefault();
                        setEditable(!editable);
                        // setEmploymentEditable(false);
                        setBankInfo(false);
                        setEditAddressDetails(false);
                        setEditEducationInfo(false);
                        setEditedTrue(!editTrue);
                      }}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    <ListItem
                      editable={editable}
                      name="personal_email"
                      title="Personal Email"
                      text={basicDetails?.personal_email}
                      inputRef={inputRef}
                      setData={setBasicsDetails}
                      data={basicDetails}
                    />
                    <ListItem
                      editable={editable}
                      name="number"
                      title="Phone number"
                      text={basicDetails?.number}
                      setData={setBasicsDetails}
                      data={basicDetails}
                    />

                    <ListItem
                      editable={editable}
                      name="alternate_number"
                      title="Alternate Number"
                      text={basicDetails?.alternate_number}
                      setData={setBasicsDetails}
                      data={basicDetails}
                    />
                    <li>
                      <div className="title">Nationality</div>
                      {!editable && (
                        <div className="text">
                          {basicDetails?.nationality ||
                            "------------------------------"}{" "}
                        </div>
                      )}
                      {editable && (
                        <select
                          value={basicDetails?.nationality}
                          className=" border-0 border-bottom"
                          name="nationality"
                          style={{
                            border: "none",
                            outline: "none",
                            color: "black",
                          }}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setBasicsDetails({
                              ...basicDetails,
                              [name]: value,
                            });
                          }}
                        >
                          {nationality.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </li>

                    {/* <ListItem
                      editable={editable}
                      title="Gender"
                      text={basicDetails?.gender}
                    /> */}
                    <li>
                      <div className="title">Gender</div>
                      {!editable && (
                        <div className="text">
                          {basicDetails?.gender ||
                            "------------------------------"}{" "}
                        </div>
                      )}
                      {editable && (
                        <select
                          value={basicDetails?.gender}
                          className="border-0 border-bottom"
                          name="gender"
                          style={{
                            border: "none",
                            outline: "none",
                            color: "black",
                          }}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setBasicsDetails({
                              ...basicDetails,
                              [name]: value,
                            });
                          }}
                        >
                          {gender.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </li>
                    {/* <ListItem
                      editable={editable}
                      title="DOB"
                      text={formatDate(basicDetails?.date_of_birth)}
                    /> */}
                    <li>
                      <div className="title">DOB</div>
                      {!editable && (
                        <div className="text">
                          {formatDate(basicDetails?.date_of_birth) ||
                            "------------------------------"}
                        </div>
                      )}
                      {editable && (
                        <input
                          type="date"
                          name="date_of_birth"
                          value={
                            dob ? dob : formatDate(basicDetails?.date_of_birth)
                          }
                          className="border-0 border-bottom"
                          style={{
                            border: "none",
                            outline: "none",
                            color: "black",
                          }}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setBasicsDetails({
                              ...basicDetails,
                              [name]: value,
                            });
                            setDob(value);
                          }}
                        ></input>
                      )}
                    </li>
                    {/* <ListItem
                      editable={editable}
                      title="Marital Status"
                      text={basicDetails?.marital_status}
                    /> */}
                    <li>
                      <div className="title">Marital Status</div>
                      {!editable && (
                        <div className="text">
                          {basicDetails?.marital_status ||
                            "------------------------------"}
                        </div>
                      )}
                      {editable && (
                        <select
                          value={basicDetails?.marital_status}
                          className="border-0 border-bottom"
                          style={{
                            border: "none",
                            outline: "none",
                            color: "black",
                          }}
                          name="marital_status"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setBasicsDetails({
                              ...basicDetails,
                              [name]: value,
                            });
                          }}
                        >
                          {marital_status.map((item) => (
                            <option value={item.label} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </li>
                    <ListItem
                      editable={editable}
                      name="guardian_name"
                      title="Guardian"
                      text={basicDetails?.guardian_name}
                      setData={setBasicsDetails}
                      data={basicDetails}
                    />
                    {basicDetails?.marital_status == "Married" && (
                      <>
                        <ListItem
                          editable={editable}
                          name="spouse_name"
                          title="Spouse Name"
                          text={basicDetails?.spouse_name}
                          setData={setBasicsDetails}
                          data={basicDetails}
                        />
                        {/* <ListItem // fix me
                          editable={editable}
                          title="Married Date"
                          text={basicDetails?.married_date}
                          setData={setBasicsDetails}
                          data={basicDetails}
                        /> */}
                        <li>
                          <div className="title">Married Date</div>
                          {!editable && (
                            <div className="text">
                              {formatDate(basicDetails?.married_date) ||
                                "------------------------------"}
                            </div>
                          )}
                          {editable && (
                            <input
                              type="date"
                              name="married_date"
                              value={basicDetails?.married_date}
                              className="border-0 border-bottom"
                              style={{
                                border: "none",
                                outline: "none",
                                color: "black",
                              }}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setBasicsDetails({
                                  ...basicDetails,
                                  [name]: value,
                                });
                              }}
                            ></input>
                          )}
                        </li>
                      </>
                    )}
                    {/* {personalInfoData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))} */}
                  </ul>
                  <div className="d-flex justify-content-end staff-msg">
                    {editable && (
                      <button
                        className="btn"
                        style={{ backgroundColor: "#1f214b", color: "white" }}
                        onClick={() => editPersonalInfo()}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Address Details
                    <Link
                      to="#"
                      className={
                        !editAddressDetails ? "edit-icon" : "active-icon"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        addressInput();
                        setEditable(false);
                        // setEmploymentEditable(false);
                        setBankInfo(false);
                        setEditAddressDetails(!editAddressDetails);
                        setEditEducationInfo(false);
                        setIsSame(false);
                        // setEditedTrue(!editTrue); fix me
                        if (!addressDetails.length) {
                          setAddressDetails([
                            {
                              employee_id: id,
                              address: "",
                              state: "",
                              pincode: "",
                              city: "",
                              country: "",
                              type: "current_address",
                            },
                            {
                              employee_id: id,
                              address: "",
                              state: "",
                              pincode: "",
                              city: "",
                              country: "",
                              type: "permanent_address",
                            },
                          ]);
                        } else {
                          setEditedTrue(!editTrue);
                        }
                      }}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="table-responsive">
                    <h4>Current Address</h4>
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Pincode</th>
                          <th>Country</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {!editAddressDetails && (
                          <tr>
                            <td className="table-data">
                              {addressDetails[0]?.address || "----------"}
                            </td>
                            <td className="table-data">
                            {cities.find(city => city.id === addressDetails[0]?.city)?.city_name || "----------"}
                            </td>
                            <td className="table-data">
                              {states.find(state => state.id === addressDetails[0]?.state)?.state || "----------"}
                            </td>
                            <td className="table-data">
                              {addressDetails[0]?.pincode || "----------"}
                            </td>
                            <td className="table-data">
                              {countries.find(country => country.id === addressDetails[0]?.country)?.country_name || "----------"}
                            </td>
                          </tr>
                        )}
                        {editAddressDetails && (
                          <tr>
                            <td>
                              <input
                                value={addressDetails[0]?.address}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                ref={addressInputRef}
                                name="address"
                                onChange={(e) => {
                                  if (addressDetails[0]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[0]?.id
                                    );
                                  } else {
                                    addressInputChange(e, 0);
                                  }
                                }}
                              ></input>
                            </td>
                            <td>
                              <select
                                value={addressDetails[0]?.city}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="city"
                                onChange={(e) => {
                                  if (addressDetails[0]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[0]?.id
                                    );
                                  } else {
                                    addressInputChange(e, 0);
                                  }
                                }}
                              ><option value=""> Select City</option>
                                {cities.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                  {item?.city_name}
                                </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <select
                                value={addressDetails[0]?.state}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="state"
                                onChange={(e) => {
                                  if (addressDetails[0]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[0]?.id
                                    );
                                    setSelectedStateID(e.target.value)
                                  } else {
                                    addressInputChange(e, 0);
                                    setSelectedStateID(e.target.value)
                                  }
                                }}
                              ><option value=""> Select State</option>
                                {states.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                  {item?.state}
                                </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                value={addressDetails[0]?.pincode}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="pincode"
                                onChange={(e) => {
                                  if (addressDetails[0]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[0]?.id
                                    );
                                  } else {
                                    addressInputChange(e, 0);
                                  }
                                }}
                              ></input>
                            </td>
                            <td>
                              <select
                                value={addressDetails[0]?.country}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="country"
                                onChange={(e) => {
                                  if (addressDetails[0]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[0]?.id
                                    );
                                    setSelectedCountryID(e.target.value)
                                  } else {
                                    addressInputChange(e, 0);
                                    setSelectedCountryID(e.target.value)
                                  }
                                }}
                              ><option value=""> Select Country</option>
                                {countries.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                  {item?.country_name}
                                </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {editAddressDetails && (
                      <div>
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          onClick={sameAddress}
                        />
                        <label
                          style={{
                            margin: "0 10px 10px 10px",
                            color: "orange",
                          }}
                        >
                          Permanent address and present address are same.
                        </label>
                      </div>
                    )}
                    {!isSame && (
                      <>
                        <h4>Permanent Address</h4>
                        <table className="table table-nowrap">
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Pincode</th>
                              <th>Country</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {/* {addressDetails[0].map((item) => ( */}
                            {!editAddressDetails && (
                              <tr>
                                <td className="table-data">
                                  {addressDetails[1]?.address || "----------"}
                                </td>
                                <td className="table-data">
                                  {permanentCities.find(city => city.id === addressDetails[1]?.city)?.city_name || "----------"}
                                </td>
                                <td className="table-data">
                                  {permanentStates.find(state => state.id === addressDetails[1]?.state)?.state || "----------"}
                                </td>
                                <td className="table-data">
                                  {addressDetails[1]?.pincode || "----------"}
                                </td>
                                <td className="table-data">
                                {
                              countries.find(country => country.id === addressDetails[1]?.country)?.country_name || "----------"
                                   }
                                </td>
                              </tr>
                            )}
                            {editAddressDetails && (
                              <tr>
                                <td>
                                  <input
                                    value={addressDetails[1]?.address}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="address"
                                    onChange={(e) => {
                                      if (addressDetails[1]?.id) {
                                        addressInputChange(
                                          e,
                                          addressDetails[1]?.id
                                        );
                                      } else {
                                        addressInputChange(e, 1);
                                      }
                                    }}
                                  ></input>
                                </td>
                              <td>
                              <select
                                value={addressDetails[1]?.city}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="city"
                                onChange={(e) => {
                                  if (addressDetails[1]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[1]?.id
                                    );
                                  } else {
                                    addressInputChange(e, 1);
                                  }
                                }}
                              ><option value=""> Select City</option>
                                {permanentCities.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                  {item?.city_name}
                                </option>
                                ))}
                              </select>
                            </td>
                              <td>
                              <select
                                value={addressDetails[1]?.state}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  color: "black",
                                }}
                                name="state"
                                onChange={(e) => {
                                  if (addressDetails[1]?.id) {
                                    addressInputChange(
                                      e,
                                      addressDetails[1]?.id
                                    );
                                    setSelectedPermanentStateID(e.target.value)
                                  } else {
                                    addressInputChange(e, 1);
                                    setSelectedPermanentStateID(e.target.value)
                                  }
                                }}
                              ><option value=""> Select State</option>
                                {permanentStates.map((item) => (
                                  <option value={item?.id} key={item?.id}>
                                  {item?.state}
                                </option>
                                ))}
                              </select>
                            </td>
                                <td>
                                  <input
                                    value={addressDetails[1]?.pincode}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="pincode"
                                    onChange={(e) => {
                                      if (addressDetails[1]?.id) {
                                        addressInputChange(
                                          e,
                                          addressDetails[1]?.id
                                        );
                                      } else {
                                        addressInputChange(e, 1);
                                      }
                                    }}
                                  ></input>
                                </td>
                                <td>
                                  <select
                                    value={addressDetails[1]?.country}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="country"
                                    onChange={(e) => {
                                      if (addressDetails[1]?.id) {
                                        addressInputChange(
                                          e,
                                          addressDetails[1]?.id
                                        );
                                        setSelectedPermanentCountryID(e.target.value)
                                      } else {
                                        addressInputChange(e, 1);
                                        setSelectedPermanentCountryID(e.target.value)
                                      }
                                    }}
                                  ><option value=""> Select Country</option>
                                    {countries.map((item) => (
                                      <option value={item?.id} key={item?.id}>
                                      {item?.country_name}
                                    </option>
                                    ))}
                                  </select>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-end staff-msg mt-2">
                    {editAddressDetails && (
                      <button
                        className="btn"
                        style={{ backgroundColor: "#1f214b", color: "white" }}
                        onClick={() => editAddressinfo()}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* ------------- employment Details----------------- */}
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Employment Details
                    {/* <Link
                      to="#"
                      className={
                        !employmentEditable ? "edit-icon" : "active-icon"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setEmploymentEditable(!employmentEditable);
                        setEditable(false);
                        setBankInfo(false);
                      }}
                    >
                      <i className="fa fa-pencil" />
                    </Link> */}
                  </h3>
                  <ul className="personal-info">
                    {/* {bankInfoData.map((item, index) => ( */}
                    {/* <ListItem
                        title="Department "
                        text={accountDetails.bank_name}
                      /> */}
                    {/* <ListItem
                      title="Official Email"
                      text={basicDetails?.official_email}
                      editable={employmentEditable}
                      inputRef={employmentDetailsInputRef}
                    /> */}
                    <ListItem
                      title="Department"
                      text={employmentDetails?.department_name}
                    />
                    <ListItem
                      title="Designation"
                      text={employmentDetails?.designation_name}
                    />
                    <ListItem
                      title="Joining Date"
                      text={
                        employmentDetails?.joining_date &&
                        formatDate(employmentDetails?.joining_date)
                      }
                    />
                    <ListItem title="Shift" text={employmentDetails?.slot} />
                    {/* ))} */}
                  </ul>
                  {/* <div className="d-flex justify-content-end staff-msg">
                    {employmentEditable && (
                      <button
                        className="btn"
                        style={{ backgroundColor: "#1f214b", color: "white" }}
                      >
                        Save
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Details
                    {qualificationDetails.length ? (
                      <Link
                        to="#"
                        className={
                          !editEducationInfo ? "edit-icon" : "active-icon"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setEditEducationInfo(!editEducationInfo);
                          setBankInfo(false);
                          setEditable(false);
                          // setEmploymentEditable(false);
                          setEditAddressDetails(false);
                          setEditedTrue(!editTrue);
                        }}
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                    ) : (
                      <Link
                        data-bs-target="#edit_employee"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        // to={`../edit-employee/${id}`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldFour(true);
                          setFieldOne(false);
                        }}
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    )}
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {qualificationDetails.map((item, index) => (
                        <li key={item?.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <label>University -</label>
                              <Link
                                to="#"
                                className="name"
                                onClick={(e) => e.preventDefault()}
                              >
                                {!editEducationInfo && item?.university}
                                {editEducationInfo && (
                                  <input
                                    value={item?.university}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    ref={index === 0 ? educationInputRef : null}
                                    name="university"
                                    onChange={(e) => {
                                      educationInputChange(e, item?.id);
                                    }}
                                  ></input>
                                )}
                              </Link>
                              <div>
                                <label>Qualification -</label>
                                {!editEducationInfo && item?.qualification}
                                {editEducationInfo && (
                                  <input
                                    value={item?.qualification}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="qualification"
                                    onChange={(e) => {
                                      educationInputChange(e, item?.id);
                                    }}
                                  ></input>
                                )}
                              </div>
                              <span className="time">
                                <label>Passing year -</label>
                                {!editEducationInfo && item?.passing_year}
                                {editEducationInfo && (
                                  <input
                                    value={item?.passing_year}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="passing_year"
                                    onChange={(e) => {
                                      educationInputChange(e, item?.id);
                                    }}
                                  ></input>
                                )}
                              </span>
                              <span className="time">
                                Percentage -
                                {!editEducationInfo && item?.percentage}
                                {editEducationInfo && (
                                  <input
                                    value={item?.percentage}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      color: "black",
                                    }}
                                    name="percentage"
                                    onChange={(e) => {
                                      educationInputChange(e, item?.id);
                                    }}
                                  ></input>
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-end staff-msg mt-2 gap-2">
                      {editEducationInfo && (
                        <>
                          <button
                            data-bs-target="#edit_employee"
                            data-bs-toggle="modal"
                            className="btn"
                            style={{
                              backgroundColor: "#1f214b",
                              color: "white",
                            }}
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldFour(true);
                              setFieldOne(false);
                            }}
                          >
                            <i className="fa-solid fa-plus" /> Add more
                          </button>

                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#1f214b",
                              color: "white",
                            }}
                            onClick={() => editEducationinfo()}
                          >
                            Save
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Bank Details
                    <Link
                      to="#"
                      className={!bankInfo ? "edit-icon" : "active-icon"}
                      onClick={(e) => {
                        e.preventDefault();
                        setBankInfo(!bankInfo);
                        setEditable(false);
                        // setEmploymentEditable(false);
                        setEditAddressDetails(false);
                        setEditEducationInfo(false);
                        setEditedTrue(!editTrue);
                      }}
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>

                  <ul className="personal-info">
                    {/* {bankInfoData.map((item, index) => ( */}
                    <ListItem
                      title="Account Holder"
                      text={accountDetails?.account_holder_name}
                      editable={bankInfo}
                      inputRef={bankInfoInputRef}
                      name="account_holder_name"
                      setData={seAccountDetails}
                      data={accountDetails}
                    />
                    <ListItem
                      title="Bank Name"
                      text={accountDetails?.bank_name}
                      editable={bankInfo}
                      name="bank_name"
                      setData={seAccountDetails}
                      data={accountDetails}
                    />
                    <ListItem
                      title="Account Number"
                      text={accountDetails?.account_number}
                      editable={bankInfo}
                      name="account_number"
                      setData={seAccountDetails}
                      data={accountDetails}
                    />
                    <ListItem
                      title="IFSC Code"
                      text={accountDetails?.ifsc_code}
                      editable={bankInfo}
                      name="ifsc_code"
                      setData={seAccountDetails}
                      data={accountDetails}
                    />
                    <ListItem
                      title="Branch Name"
                      text={accountDetails?.branch_name}
                      editable={bankInfo}
                      name="branch_name"
                      setData={seAccountDetails}
                      data={accountDetails}
                    />
                    {/* ))} */}
                  </ul>
                  <div className="d-flex justify-content-end staff-msg">
                    {bankInfo && (
                      <button
                        className="btn"
                        style={{ backgroundColor: "#1f214b", color: "white" }}
                        onClick={() => editAccountDetails(accountDetails?.id)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {experienceData.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.name}
                              </Link>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <ProjectDetails />
        {/* Bank Statutory Tab */}

        {/* Bank Statutory Tab */}
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      <PersonalInformationModelPopup />
      {/* <EditCandidatesModal
        employID={id}
        setEditedTrue={setEditedTrue}
        // fieldThree={true} // fix me 1:08
        setFieldThree={setFieldThree}
      /> */}
    </>
  );
};

export default ProfileTab;
