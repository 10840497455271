import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import EmployeeLeaveModelPopup from "../../../components/modelpopup/EmployeeLeaveModelPopup";
import config from "../../../config";
import axios from "axios";
import "../../../assets/css/employeeLeave.css";
import Holidays from "./Holidays";
import { companyID } from "../../../auth";
import { formatDecimal } from "../../../utils/formatDecimal";

function LeaveDashboard() {
  const [leaveType, setLeaveType] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState([]);
  const [leave, setLeave] = useState({
    employee_id: "",
    start_date: "",
    end_date: "",
    leave_type: "",
    message: "",
    leave_reason: "",
    leave_days: "",
    leave_apply_period: "1",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  const employeeId = localStorage.getItem("employeeID");

  console.log("employeeId localStorage.getItem : ", employeeId);

  const leaveTypes = [
    // {
    //   type: "Compassionate Leave - UAE & Remote",
    //   currentlyAvailable: 5,
    //   accrued: 5,
    //   credited: 0,
    //   utilized: 0,
    //   annualAllotment: 5,
    // },
    {
      type: leaveType[0]?.name,
      currentlyAvailable:
        leaveBalance.length > 0
          ? leaveBalance[0]?.remaining_days
          : leaveType[0]?.total_days,
      credited: leaveBalance[0]?.carry_forward_days || 0,
      utilized: leaveType[0]?.total_days - leaveBalance[0]?.remaining_days || 0,
      annualAllotment: leaveType[0]?.total_days || 0,
    },
    {
      type: leaveType[1]?.name,
      currentlyAvailable:
        leaveBalance.length > 0
          ? leaveBalance[1]?.remaining_days
          : leaveType[1]?.total_days,
      credited: leaveBalance[1]?.carry_forward_days || 0,
      utilized: leaveType[1]?.total_days - leaveBalance[1]?.remaining_days || 0,
      annualAllotment: leaveType[1]?.total_days || 0,
    },
    {
      type: leaveType[2]?.name,
      currentlyAvailable:
        leaveBalance.length > 0
          ? leaveBalance[2]?.remaining_days
          : leaveType[2]?.total_days,
      credited: leaveBalance[2]?.carry_forward_days || 0,
      utilized: leaveType[2]?.total_days - leaveBalance[2]?.remaining_days || 0,
      annualAllotment: leaveType[2]?.total_days || 0,
    },
    {
      type: "Unpaid Leave",
      currentlyAvailable: 0,
      credited: 0,
      utilized: 0, //fix me
      annualAllotment: 0,
    },
  ];

  const mergedData = leaveType.map((leave) => {
    const balance =
      leaveBalance.find((b) => b.leave_type_id === leave.leave_id) || {};
    return {
      name: leave?.leave_name,
      type: leave.leave_id,
      currentlyAvailable:
        balance.remaining_days !== undefined
          ? balance.remaining_days
          : leave.total_days,
      credited:
        balance.carry_forward_days !== undefined
          ? balance.carry_forward_days
          : 0,
      utilized: balance?.remaining_days ? leave.total_days - (balance.remaining_days || 0):0,
      annualAllotment: leave.total_days,
    };
  });

  console.log("leaveType state : ", leaveType);
  // console.log("leaveType obj : ", leaveTypes);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  useEffect(() => {
    const company_id = companyID();
    axios
      .get(`${config.API_BASE_URL}leaveTypes/${company_id}`)
      .then((res) => {
        console.log("leaveTypes : ", res);
        // const leaveTypes = res.data.map((type) => ({
        //   value: type.id,
        //   label: type.name,
        // }));
        console.log("leaveTypes : ", res.data);
        setLeaveType(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}leaveBalance/${employeeId}`)
      .then((res) => {
        console.log("leaveBalance : ", res);
        // const leaveTypes = res.data.map((type) => ({
        //   value: type.id,
        //   label: type.name,
        // }));
        console.log("leaveBalance : ", res.data);
        setLeaveBalance(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="main-wrapper leave-dashboard">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Container>
              <Row className="mb-4">
                <Col className="d-flex align-items-center">
                  {/* <h4>Your Time Off Balance as of 17-Jun-2024</h4> */}
                  <h4>{show ? "Holidays" : "Your Time Off Balance"}</h4>
                  {/* <Badge variant="warning" className="mr-3">
                  Pending Requests (0)
                </Badge> */}
                </Col>
                <Col className="d-flex justify-content-end align-items-center gap-2">
                  <Button
                    variant="outline-primary"
                    className="mr-2"
                    style={{
                      backgroundColor: `${show ? "#FF902F" : ""}`,
                      color: `${show ? "white" : ""}`,
                    }}
                    onClick={() => {
                      handleShow();
                    }}
                  >
                    <Link
                      onClick={(e)=>e.preventDefault()}
                      style={{
                        color: `${show ? "white" : ""}`,
                      }}
                    >
                      List of Holidays
                    </Link>
                  </Button>

                  <Button
                    variant="outline-success"
                    onClick={() => navigate(`../leaves-employee`,{
                      state: {
                        modalOpen:true
                      },
                    })}
                  >
                    Apply Leave
                  </Button>
                </Col>
              </Row>
              {!show && (
                <Row>
                  {mergedData.map((leave, index) => (
                    <Col md={4} className="mb-4" key={index}>
                      <Card>
                        <Card.Body>
                          <Card.Title>{leave?.name}</Card.Title>
                          <Card.Text>
                            <>
                              <h3>{formatDecimal(leave?.currentlyAvailable)}</h3>
                              <p>Currently Available</p>
                            </>

                            <>
                              <p>{leave?.credited} Credited from last year</p>
                              <p>
                                {leave?.utilized !== undefined
                                  ? leave?.utilized
                                  : 0}{" "}
                                Utilized so far this year
                              </p>
                              <p>{leave?.annualAllotment} Annual Allotment</p>
                            </>

                            {leave?.totalAllotment !== undefined && (
                              <p>{leave?.totalAllotment} Total Allotment</p>
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </Container>

            {/* <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Holidays />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary">Understood</Button>
            </Modal.Footer>
          </Modal> */}
            {show && <Holidays />}
          </div>
        </div>
        {isModalOpen && <div className="modal-backdrop fade show"></div>}
      </div>

      {/* <EmployeeLeaveModelPopup /> */}
      <EmployeeLeaveModelPopup
        leave={leave}
        setLeave={setLeave}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

export default LeaveDashboard;
