import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar_02, Avatar_09 } from "../../../Routes/ImagePath";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SearchBox from "../../../components/SearchBox";
import AddOverTime from "../../../components/modelpopup/AddOverTime";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { getAllLeaves } from "../../../services/leaveService";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import { getLabelByValue } from "../../../utils/getLabelByValue";
import { getAllExpenses } from "../../../services/reimbursementService";
import axios from "axios";
import config from "../../../config";
import { companyID } from "../../../auth";

const Taskbox = () => {
  const [paginationValue, setPaginationValue] = useState(10);
  const [leaves, setLeaves] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [reimbursementId, setReimbursementId] = useState(null);
  const [acknowledge, setAcknowledge] = useState(false);
  const [acknowledgeAttachment, setAcknowledgeAttachment] = useState(null);
  const [updatedRemainingDaya, setUpdatedRemainingDays] = useState({});

  const employeeId = localStorage.getItem("employeeID");

  console.log("Task box leaves : ", leaves);
  console.log("Task box expenses : ", expenses);

  const fetchLeaves = async () => {
    const employee_id = localStorage.getItem("employeeID");
    try {
      setLoading(true);

      const response = await axios.get(
        `${config.API_BASE_URL}api/reimbursement-for-approval/${employee_id}`
      );

      const response2 = await axios.get(
        `${config.API_BASE_URL}employeseApproval/${employee_id}`
      );
      const response3 = await axios.get(
        `${config.API_BASE_URL}leave-for-approval/${employee_id}`
      );
      const response4 = await axios.get(
        `${config.API_BASE_URL}api/reimbursementsForApproval/${employee_id}`
      );
      console.log("response4", response4);
      let combinedData = [];

      if (response?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response?.data?.result];
      }
      if (response2?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response2?.data?.result];
      }
      if (response3?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response3?.data?.result];
      }
      if (response4?.data?.result?.length > 0) {
        combinedData = [...combinedData, ...response4?.data?.result];
      }

      setLeaves(combinedData);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchLeaves();
  }, []);

  const company_id = companyID();
  console.log("taskbocapproval", leaves);

  const leavetype = [
    { value: "", label: "Select leave type" },
    { value: 1, label: "Sick Leave" },
    { value: 2, label: "Casual Leave" },
    { value: 3, label: "Vacation" },
    { value: 4, label: "Unpaid Leave" },
  ];

  const leaveElements = leaves
    .map((data, index) => ({
      key: index + 1,
      name: data?.first_name + " " + data?.last_name,
      type: getLabelByValue(leavetype, data.leave_type) || data.expense_type,
      leave_type_id : data?.leave_type,
      leave_days : data?.leave_days,
      appliedOn: formatDateInTimezone(data.created_at),
      appliedfor: data.start_date
        ? `${formatDateInTimezone(data.start_date)} - ${formatDateInTimezone(
            data.end_date
          )}`
        : `${formatDateInTimezone(data.expense_date)}`,
      status: data.updated_status || data.expense_status,
      task_type: data.expense_type ? "REIMBURSEMENT" : "LEAVE",
      employee_id: data.employee_id,
      task_id: data.id,
      updated_status: data?.updated_status || 1,
      attachment: data?.attachment,
    }))
    .sort((a, b) => new Date(b.appliedOn) - new Date(a.appliedOn));

  const actionStatus = [
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ];
  //------------------------ approval action handling------------------------

  const actionStatusHandler = async (task_id, task_type, status_id) => {
    const employeeId = localStorage.getItem("employeeID");
    // console.log( "task_is" ,response );
    if (task_type == "LEAVE") {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}leave/${task_id}/approve/`,
          {
            company_id:company_id,
            approver_id: employeeId,
            status_id: status_id,
            leave_employee_id: updatedRemainingDaya?.leave_employee_id,
            no_of_days: updatedRemainingDaya?.no_of_days,
            leave_type_id: updatedRemainingDaya?.leave_type_id
          }
        );
        if (response) {
          fetchLeaves();
        }
      } catch (error) {
        console.error("Error updating leave:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}api/reimbursementsForApprova/${task_id}`,
          {
            approver_id: employeeId,
            status_id: status_id,
            company_id:company_id
          }
        );
        if (response) {
          setCurrentStatus(status_id);
          fetchLeaves();
        }
      } catch (error) {
        console.error("Error approving reimbursement:", error);
      }
    }
  };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "key",
    //   sorter: (a, b) => a.id.length - b.id.length,
    // },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span className="table-avatar">{text}</span>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.otdate.length - b.otdate.length,
    },

    {
      title: "Applied on",
      dataIndex: "appliedOn",
      sorter: (a, b) => a.ottype.length - b.ottype.length,
    },

    {
      title: "Applied for",
      dataIndex: "appliedfor",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          style={{
            color:
              record.updated_status === 1
                ? "rgb(255 223 0)"
                : record.updated_status === 2
                ? "green"
                : "red",
          }}
        >
          {record.updated_status === 1
            ? "Pending"
            : record.updated_status === 2
            ? "Approved"
            : "Rejected"}
        </span>
      ),

      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {record.task_type === "REIMBURSEMENT" ? (
              <a
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreen"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentStatus(record?.updated_status);
                  setReimbursementId(record?.task_id);
                  setAcknowledgeAttachment(record?.attachment);
                  console.log("record for id of leave : ", record.key);
                }}
              >
                <i className={`fa-regular fa-eye`} /> Attachment
              </a>
            ) : (
              actionStatus.map((action, index) => (
                <a
                  className="dropdown-item"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("record for id of leave : ", record.key);
                    actionStatusHandler(
                      record.task_id,
                      record.task_type,
                      action.value
                    );
                    setUpdatedRemainingDays({
                      leave_employee_id: record?.employee_id,
                      no_of_days: record?.leave_days,
                      leave_type_id: record?.leave_type_id
                    });
                  }}
                >
                  <i
                    className={`far fa-dot-circle ${
                      action.label == "Approved"
                        ? "text-success"
                        : " text-danger"
                    } `}
                  />{" "}
                  {action.label}
                </a>
              ))
            )}
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Task Box"
            title="Dashboard"
            subtitle="Task Box"
            // modal="#add_overtime"
            // name="Add Overtime"
          />

          <div
            className="modal fade"
            id="exampleModalFullscreen"
            tabIndex={-1}
            aria-labelledby="exampleModalFullscreenLabel"
            aria-hidden="true"
            style={{ display: "none", zIndex: "99999" }}
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalFullscreenLabel">
                    Uploaded Attachment
                  </h4>
                  <button
                    style={{
                      color: "red",
                      border: "0px",
                      backgroundColor: "transparent",
                      fontSize: "24px",
                    }}
                    type="button"
                    onClick={() => {
                      setAcknowledge(false);
                    }}
                    data-bs-dismiss="modal"
                  >
                    <i class="far fa-window-close"></i>
                  </button>
                </div>
                <div className="modal-body d-flex justify-content-center">
                  <img
                    // style={{ width: "50%",  }}
                    src={`${config.IMAGE_URL}${acknowledgeAttachment}`}
                    alt="attachment"
                  />
                </div>
                <div className="modal-footer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "4px",
                    }}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={acknowledge}
                      onClick={(e) => {
                        setAcknowledge(e.target.checked);
                      }}
                    />
                    <label>
                      I have reviewed and acknowledge this attachment.
                    </label>
                  </div>

                  {acknowledge && (
                    <div className="dropdown action-label text-center">
                      <Link
                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                        to="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className={
                            currentStatus === 1
                              ? "far fa-dot-circle text-info"
                              : currentStatus === 2
                              ? "far fa-dot-circle text-success"
                              : "far fa-dot-circle text-danger"
                          }
                        />{" "}
                        {currentStatus === 1
                          ? "Pending"
                          : currentStatus === 2
                          ? "Approved"
                          : "Rejected"}
                      </Link>

                      <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item" to="#">
                          <i className="far fa-dot-circle text-info" /> Pending
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            actionStatusHandler(
                              reimbursementId,
                              "REIMBURSEMENT",
                              2
                            );
                          }}
                        >
                          <i className="far fa-dot-circle text-success" />{" "}
                          Approve
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            actionStatusHandler(
                              reimbursementId,
                              "REIMBURSEMENT",
                              3
                            );
                          }}
                        >
                          <i className="far fa-dot-circle text-danger" /> Reject
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* /Page Header */}
          {/* <div className="row">
            {statsData.map((data, index) => (
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={index}>
                <div className="stats-info">
                  <h6>{data.title}</h6>
                  <h4>
                    {data.value} <span>{data.month}</span>
                  </h4>
                </div>
              </div>
            ))}
          </div> */}
          {/* /Overtime Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox setPaginationValue={setPaginationValue} />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={leaveElements}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: paginationValue }}
                  scroll={{ x: true }}
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddOverTime />
      <DeleteModal Name="Delete Overtime" />
    </>
  );
};

export default Taskbox;
