import React, { useState, useEffect } from "react";
import { Modal,Button, Spin } from "antd";
import DateSelector from "./DateSelector";
import EmployeeLocationTable from "./EmployeeLocationTable";
import axios from "axios";
import config from "../../../../config";

const EmployeeLocationModal = ({ visible, onClose, employee }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && employee) {
      fetchLocations(new Date().toISOString().slice(0, 10));
    }
  }, [visible, employee]);

  const fetchLocations = async (date) => {
    setLoading(true);
    try {
      const data = await axios.get(
        `${config.API_BASE_URL}tracking/${employee.id}?date=${date}`
      );
      setLocations(data?.data?.locations);
    } catch (error) {
      setLocations([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchLocations(date.toISOString().slice(0, 10));
  };

  return (
    <Modal
      title={`Location Data for ${employee.first_name} ${employee.last_name}`}
      visible={visible}
      onCancel={() => {
        onClose();
        setSelectedDate(new Date());
      }}
      footer={null}
      width={1000}
      zIndex={99999999}
    >
      <Button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '9px',
          right: '7px',
          border: 'none',
          background: 'transparent',
          fontSize: '18px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
      >
        ×
      </Button>
      <DateSelector
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
      />
      {loading ? (
        <Spin style={{display:'flex',justifyContent:'center',alignItems:'center'}} size="large" />
      ) : (
        <EmployeeLocationTable locations={locations} />
      )}
    </Modal>
  );
};

export default EmployeeLocationModal;
