import React, { useState } from "react";
import { Link } from "react-router-dom";

const SearchBox = ({setPaginationValue, setListView, listView}) => {

  return (
    <>
      <div className={`row ${setListView ? 'mb-2': ''}`}>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length d-flex">
            <label className="d-flex">
              Show{" "}
              <select
                style={{cursor:'pointer'}}
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="custom-select custom-select-sm form-control form-control-sm me-1 ms-1 mb-2"
                onChange={(e)=> setPaginationValue(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{" "}
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
        {setListView && <div className="view-icons">
            <Link
              onClick={(e)=>{
                e.preventDefault()
                setListView(false)
              }}
              className={`grid-view btn btn-link ${!listView ? 'active':''} mx-2`}
            >
              <i className="fa fa-th" />
            </Link>
            <Link 
            onClick={(e)=>{
              e.preventDefault()
              setListView(true)
            }}
            className={`list-view btn btn-link ${listView ? 'active':''}`}>
              <i className="fa fa-bars" />
            </Link>
          </div>}
        </div>
      </div>
    </>
  );
};

export default SearchBox;
