import React, { useEffect, useState } from "react";
import { Select, Table, message, Empty } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import "../assets/css/paySlips.css";
import config from "../config";
import { companyID } from "../auth";
import { calculateSalaryBreakdown } from "../utils/calculateSalaryBreakdown";
import { formatDecimal } from "../utils/formatDecimal";

const { Option } = Select;

function PaySlips({salaryConfig}) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [financialYear, setFinancialYear] = useState(currentYear);
  const [salaryData, setSalaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeSalary,setEmployeeSalary]=useState(0);
  const companyId = companyID(); 
  const employeeId = localStorage.getItem("employeeID");
  const [grossProration, setGrossProration] = useState({});

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (text) => new Date(financialYear, text - 1).toLocaleString("default", { month: "long" }),
    },
    {
      title: "Total Working Days",
      dataIndex: "total_working_days",
      key: "total_working_days",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Attendance",
      dataIndex: "total_attendance",
      key: "total_attendance",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Total Leave",
      dataIndex: "total_leave",
      key: "total_leave",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Company holidays",
      dataIndex: "total_holidays",
      key: "total_holidays",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "Salary Eligible Days",
      dataIndex: "salary_eligible_days",
      key: "salary_eligible_days",
      render:(text)=> formatDecimal(text)
    },
    {
      title: "View",
      key: "payslip",
      render: (_, record) => {
         return <Link to="#" className="btn btn-sm btn-primary" onClick={(e) => {
          e.preventDefault();
          const dailySalary = employeeSalary / record.total_working_days;
          const salary = dailySalary * record.salary_eligible_days;
          const roundedSalary = Math.round((salary + Number.EPSILON) * 100) / 100;
          const salarySlipMonth = new Date(financialYear, record.month - 1).toLocaleString("default", { month: "short" })
          generateSalarySlip({...record, slipMonth:salarySlipMonth+' '+financialYear, currentMonthSalary:roundedSalary})
          console.log('record for month', record)
          }}>
          Generate Slip
        </Link>
        // const dailySalary = employeeSalary / record.total_working_days;
        // const salary = dailySalary * record.salary_eligible_days;

        // const roundedSalary = Math.round((salary + Number.EPSILON) * 100) / 100;
    
        // return <>{roundedSalary}</>;
      },
    },
    
  ];

  useEffect(() => {
    fetchSalaryData();
  }, [financialYear]);

  useEffect(() => {
      axios
        .get(
          `${config.API_BASE_URL}employees/companies/${companyId}/employees/${employeeId}/salary-breakdown`
        )
        .then((response) => {
          console.log("response config ", response.data.config);
          // setSalaryConfig(response.data.config);
          setEmployeeSalary(response?.data?.employmentData[0]?.salary);
          setGrossProration(calculateSalaryBreakdown(response?.data?.employmentData[0]?.salary,salaryConfig));
          // console.log("response calculateSalaryBreakdown ", calculateSalaryBreakdown(response?.data?.employmentData[0]?.salary,salaryConfig));

        })
        .catch((error) => {
          console.log(error)
        });
  }, []);

  const handleFinancialYearChange = (value) => {
    setFinancialYear(value);
  };

  const fetchSalaryData = async () => {
    setLoading(true);
    try {
      const monthsToShow = financialYear == currentYear ? currentMonth-1 : 12;
      const monthlyData = [];

      // console.log('financialYear : ',financialYear, ' currentYear : ',currentYear, 'monthsToShow : ',monthsToShow, "currentMonth : ",currentMonth);

      for (let month = 1; month <= monthsToShow; month++) {
      
        const workingDaysResponse = await axios.get(`${config.API_BASE_URL}working-days/${companyId}/${month}/${financialYear}`);
        const totalWorkingDays = workingDaysResponse.data.total_working_days;

        const salaryResponse = await axios.post(`${config.API_BASE_URL}calculate-salary`, {
          employee_id: employeeId,
          company_id: companyId,
          month,
          year: financialYear,
        });

        if (salaryResponse.data.salaryDetails.length === 0) {
          // message.warning("Salary details not found for the selected employee and company.");
          // monthlyData.push({
          //   month,
          //   total_working_days: totalWorkingDays,
          //   ...{
          //     "employee_id": 1,
          //     "company_id": 1,
          //     "salary_eligible_days": 0,
          //     "total_attendance": 0,
          //     "total_leave": 0,
          //     "total_holidays": 0
          // },
          // });
          continue;
        }

        const salaryDetails = salaryResponse.data.salaryDetails[0];
        if(salaryDetails?.total_attendance!=0 || salaryDetails?.total_leave!=0){monthlyData.push({
          month,
          total_working_days: totalWorkingDays,
          ...salaryDetails,
        });}
      }

      setSalaryData(monthlyData);
    } catch (error) {
      message.error("Error fetching salary data.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateSlip = (record) => {
    // Handle the payslip generation logic here

    console.log("Generate payslip for:", record);
  };

  const generateSalarySlip=(employeeRecord)=>{
    setLoading(true)
    axios.post(`${config.API_BASE_URL}generate-pdf`,{
      ...grossProration,
      ...employeeRecord,
      employee_id:employeeId
    })
    .then(()=>{
    axios.get(`${config.API_BASE_URL}download-pdf`,{
      responseType: "blob"
    })
    .then((res) => {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      // if (option === "view") 
        window.open(pdfUrl, "_blank");
      // else
      //   saveAs(
      //     pdfBlob,
      //     `${traineeDetail.first_name +
      //       " " +
      //       traineeDetail.last_name}_Fee_Receipt.pdf`
      //   );
    })
    })
    .catch((error)=>{
     console.log(error);
    })
    .finally(()=>{
      setLoading(false)
    })
  }

  return (
    <div className="pay-slips">
      <h2>Pay slips for {financialYear}</h2>
      <div className="financialYearDropdown mb-2">
        <label>Select Financial Year:</label>
        <Select value={financialYear} style={{ width: 120 }} onChange={handleFinancialYearChange}>
          <Option value="2023">2023</Option>
          <Option value="2024">2024</Option>
          {/* <Option value="2025-26">2025-26</Option> */}
        </Select>
      </div>
      <div className="row">
        <div className="table-responsive">
          {salaryData.length > 0 ? (
            <Table
              dataSource={salaryData}
              columns={columns}
              rowKey="month"
              loading={loading}
              pagination={false}
            />
          ) : (
            !loading ? <Empty description="No salary details available." />:<Empty description="Fetching salary details..." />
          )}
        </div>
      </div>
    </div>
  );
}

export default PaySlips;
