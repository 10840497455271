import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import createCustomSchema from '../../../fieldValidator/fieldValidator';
import * as Yup from "yup";
import axios from 'axios';
import config from '../../../config';
import { companyID } from '../../../auth';
import CompanyCoordinatesError from '../Authentication/CompanyCoordinatesError';
import Loader from '../../../components/Loader';

const DailyWorkStatusModal = ({ show,handleShowModal, handleClose, handleLogout }) => {
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [allowSkip, setAllowSkip] = useState(null);
  const id = localStorage.getItem("employeeID");
  const work_location = localStorage.getItem("work_location");
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const company_id = companyID();
 
  const handleSubmit = async(e) => {
    setLoading(true)
    console.log({status:status, employee_id:id})
    let inLocation;
      if(work_location==1){
      inLocation =await getEmployeeCurrentPosition(company_id);
      }
    if(inLocation||work_location!=1){
      setLoading(false)
    axios.post(`${config.API_BASE_URL}daily-work-status`,{work_status:status, employee_id:id})
    .then((res)=>{
        console.log('handleLogout(e); : ',res?.data)
        if(res?.data){
        handleLogout(e);
        }
    })
    .catch((err)=>console.log(err?.response?.data))
  }else{
    setLoading(false);
    handleShowModal(false)
  }
  };

  const handleSkip = async(e) => {
    setLoading(true);
    let inLocation;
      if(work_location==1){
      inLocation =await getEmployeeCurrentPosition(company_id);
      }
    if(inLocation||work_location!=1){
      setLoading(false)
      handleLogout(e); 
    }
    else{
      setLoading(false);
      handleShowModal(false)
    }
  };
 
  const validationSchema = Yup.object().shape({
    status: createCustomSchema("paragraph", {
      message: "Enter Work Status!", min:3
    }),
  });

  const onSubmit = async (e) => {
    try {
      await validationSchema.validate({status:status}, {abortEarly: false});
      handleSubmit(e);
      setStatusError({});
    } catch (error) {
      const newError = {};
      error.inner.forEach(err => {
        newError[err.path] = err.message;
      });
      setStatusError(newError);
    }
  };

  useEffect(()=>{
    const getAllowSkipConfig=()=>{
      if(company_id){
        axios.get(`${config.API_BASE_URL}company/${company_id}/allow-skip`)
      .then((res)=>{
        console.log('setAllowSkip',res?.data,'res?.data?.allow_skip', res?.data?.config?.allow_skip,)
        setAllowSkip(res?.data?.config?.allow_skip);
      })
      .catch((err)=>{
        console.log(err);
        setAllowSkip(null);
      })
      }
    }

    getAllowSkipConfig();
  },[]);

  // useEffect(() => {
  //   if(company_id){
  //   getEmployeeCurrentPosition(company_id);
  //   }
  // }, []);

  const handleCompanyCoordinatesErrorModal = () => {
    setShowModal(false);
  };

  const getEmployeeCurrentPosition = async (company_id) => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
            const locationAllowed = await checkEmployeeLocation(latitude, longitude, company_id); 
            // const locationAllowed = await checkEmployeeLocation('26.85338427114405', '81.0168830277534', company_id);
            resolve(locationAllowed);  // Return true or false based on checkEmployeeLocation
          },
          (error) => {
            setShowModal(true)
            setLocationAllowed(true);
            setLocationErrorMessage('Failed to retrieve location. Turn on location and give access to the app.');
            console.log('Location error:', error);
            setIsLoading(false);
            resolve(false);  // Return false on error
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,  // Max time to wait for location
            maximumAge: 0    // Don't use a cached location
          }
        );
      } else {
        setLocationErrorMessage('Geolocation is not supported by your browser.');
        setIsLoading(false);
        resolve(false);  // Return false if geolocation is not supported
      }
    });
  };
  
  const checkEmployeeLocation = async (latitude, longitude, company_id) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}auth/company_coordinates`, { latitude, longitude, company_id });
      if (response.data.allowed) {
        setShowModal(false);
        setLocationAllowed(false);
        return true;  // Return true if location is allowed
      } else {
        setShowModal(true);
        setLocationAllowed(true);
        setLocationErrorMessage('You are not within the allowed location. Try to locate near company radius.');
        return false;  // Return false if location is not allowed
      }
    } catch (error) {
      setLocationErrorMessage('Error checking location.');
      setIsLoading(false);
      return false;  // Return false on error
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Work Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="workStatus">
            <Form.Label>Enter your work status</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Describe what you've worked on today..."
              value={status}
              onChange={(e) => {
                setStatusError({status:null})
                setStatus(e.target.value)
            }}
            />
          </Form.Group>
          {statusError?.status &&<span style={{color:'red'}}>* {statusError?.status}</span>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {allowSkip?<Button variant="secondary" onClick={(e)=>handleSkip(e)}>
          Skip
        </Button>:<></>}
        <Button variant="primary" onClick={(e)=>onSubmit(e)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
    <CompanyCoordinatesError
     errorMessage={locationErrorMessage}
     show={showModal}
     handleClose={handleCompanyCoordinatesErrorModal}
    />
    <Loader show={loading} loadingMessage="" />
    </>
  );
};

export default DailyWorkStatusModal;
