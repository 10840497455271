import React, { useEffect, useState } from "react";
import axios from "axios";
import TableAvatar from "./TableAvatar";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import config from "./../../../config";
import "../../../assets/css/pagination.css";
import {
  getFirstDateOfCurrentMonth,
  getMonthOfCurrentMonth,
} from "../../../utils/formatDateInTimezone";
import { companyID } from "./../../../auth";
import { months } from "../../../utils/data";
import SearchBox from "../../../components/SearchBox";
import { attendanceByColor } from "../../../utils/basicFunctions";
import { Badge, Col, Row } from "antd";

const AttendenceAdmin = () => {
  const [paginationValue, setPaginationValue] = useState(10);
  const [modalData, setModalData] = useState(null);
  const [employeeAttendance, setEmployeeAttendance] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getMonthOfCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [roleId, setRoleId] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [renderAttendanceData, setRenderAttendanceData] = useState(false);
  console.log("current month  is here--->", selectedMonth);
  console.log("current year  is here--->", selectedYear);
  const employeeID = localStorage.getItem("employeeID");
  useEffect(() => {
    const employee_role = localStorage.getItem("employee_role");
    const employee_id = localStorage.getItem("employeeID");
    setRoleId(employee_role);
    setEmployeeId(employee_id);
  }, []);
  // TODO -> Hard Coded Month
  const month = `${selectedYear}-${selectedMonth}`;
  console.log("current month", month);
  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() - i
  );

  useEffect(() => {
    console.log("working----");
    const attendaceData = async () => {
      let start_date = `${selectedYear}-${selectedMonth}-01`;
      const company_id = companyID();
      console.log("starting  date is here ", start_date);
      const employee_role = localStorage.getItem("employee_role");
      if (employee_role !== "3") {
        try {
          const response = await axios.get(
            `${config.API_BASE_URL}api/attendance/employee-attendance/${start_date}/${company_id}`
          );
          console.log("response fron attendance--->", response);
          if (response?.data?.success === true) {
            setEmployeeAttendance(response?.data?.result);
            setRenderAttendanceData(false);
          }
        } catch (err) {
          console.log("api 1 err", err);
        }
      } else {
        try {
          const employee_id = localStorage.getItem("employeeID");
          console.log("employee_id from id", employee_id);
          const response = await axios.get(
            `${config.API_BASE_URL}api/attendance/employee-attendance-reportee/${start_date}/${company_id}/${employee_id}`
          );
          console.log("response fron attendance--->", response);
          if (response?.data?.success === true) {
            setEmployeeAttendance(response?.data?.result);
            console.log("transformedData", response?.data?.result);
            setRenderAttendanceData(false);
          }
        } catch (err) {
          console.log("api 2 err", err);
        }
      }
    };
    attendaceData();
  }, [selectedMonth, selectedYear, renderAttendanceData]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const transformData = (data) => {
    console.log("data transformData", data);
    return data.reduce((acc, curr) => {
      const found = acc.find((item) => item.employee_id === curr.employee_id);

      const color = attendanceByColor(curr?.punchIn, curr?.punchOut, curr.date);
      if (found) {
        found.dateDescription.push({
          date: curr.date,
          status: curr.status,
          punchIn: curr?.punchIn,
          punchOut: curr?.punchOut,
          color: color,
          isHideAprroval:
            color == "#2aad40" || color == "#0000FF" ? true : false,
          approved_full_day: curr?.approved_full_day,
          updated_at : curr?.updated_at
        });
      } else {
        acc.push({
          employee_id: curr.employee_id,
          first_name: curr.first_name,
          last_name: curr.last_name,
          photo: curr.photo,
          dateDescription: [
            {
              date: curr.date,
              status: curr.status,
              punchIn: curr?.punchIn,
              punchOut: curr?.punchOut,
              color: color,
              approved_full_day: curr?.approved_full_day,
              isHideAprroval: color == "#2aad40" ? true : false,
              updated_at : curr?.updated_at
            },
          ],
        });
      }
      return acc;
    }, []);
  };

  const filterDataByMonth = (data, month) => {
    return data.map((employee) => {
      const filteredDateDescription = employee.dateDescription.filter((entry) =>
        entry.date.startsWith(month)
      );
      return { ...employee, dateDescription: filteredDateDescription };
    });
  };

  const transformedData = transformData(employeeAttendance);
  console.log("transformedData", transformedData);
  // const month = '2024-07';
  const filteredDataForMonth = filterDataByMonth(transformedData, month);

  const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );

  const filteredData = transformedData.map((item) => ({
    ...item,
    dateDescription: item.dateDescription.filter(
      (entry) =>
        new Date(entry.date) >= startDate && new Date(entry.date) <= new Date()
    ),
  }));

  console.log("filtered data --->", filteredData);

  //------------------- all dates from databse --------------------------
  function getUniqueDatesForMonth(data, month) {
    const datesSet = new Set();

    data.forEach((employee) => {
      employee.dateDescription.forEach((entry) => {
        if (entry.date.startsWith(month)) {
          datesSet.add(entry.date);
        }
      });
    });

    return Array.from(datesSet).sort();
  }
  const uniqueDatesForMonth = getUniqueDatesForMonth(transformedData, month);

  console.log("uniques date", uniqueDatesForMonth);

  //------------------------- get date by month -------------------------------
  const getDatesForMonth = (month) => {
    const [year, monthNumber] = month.split("-");
    const startDate = new Date(year, monthNumber - 1, 1);
    const endDate = new Date(year, monthNumber, 0);

    const datesArray = [];
    const currentDatePointer = new Date(startDate);

    while (currentDatePointer <= endDate) {
      const year = currentDatePointer.getFullYear();
      const month = ("0" + (currentDatePointer.getMonth() + 1)).slice(-2);
      const day = ("0" + currentDatePointer.getDate()).slice(-2);
      datesArray.push(`${year}-${month}-${day}`);
      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }

    return datesArray;
  };
  const datesForMonth = getDatesForMonth(month);
  console.log("date of ana minth", datesForMonth);
  //--------------------- from starting month to till todays date -----------------

  const updateAttendanceStatus = (attendanceData) => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return attendanceData.map((employee) => {
      const updatedDateDescription = employee.dateDescription.map((entry) => {
        const entryDate = new Date(entry.date);
        if (entryDate > today) {
          return { ...entry, status: "Pending" };
        }
        return entry;
      });
      return {
        ...employee,
        dateDescription: updatedDateDescription,
      };
    });
  };

  const updatedData = updateAttendanceStatus(filteredDataForMonth);
  const attendance = updatedData.filter((item) => item.employee_id != employeeID);
  console.log('updatedData Test',attendance );

  // ------------- pagination----------------------
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(transformedData.length / paginationValue);

  const paginatedData = attendance.slice(
    (currentPage - 1) * paginationValue,
    currentPage * paginationValue
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Attendance"
            title="Dashboard"
            subtitle="Attendance"
          />
          {/* Dropdowns for Month and Year */}
          <div className="row mb-3">
            <div className="col-md-3">
              <select
                className="form-select"
                value={selectedYear}
                onChange={handleYearChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <SearchBox setPaginationValue={setPaginationValue} />
            <div className="col-lg-12">
              <div className="table-responsive">
                <TableAvatar
                  uniqueDatesForMonth={datesForMonth}
                  transformedData={attendance}
                  paginationValue={paginationValue}
                  paginatedData={paginatedData}
                  setRenderAttendanceData={setRenderAttendanceData}
                />
              </div>
            </div>
            {paginatedData.length !== 0 && (
              <div className="container  mt-3">
                <div className="row justify-content-start attendance-color-code-box">
                  <div className="col-6 col-sm-4 col-md-2 d-flex align-items-center mb-2 gap-1">
                    <div className="circle Violet "></div>
                    <span>Absent</span>
                  </div>
                  <div className="col-6 col-sm-4 col-md-2 d-flex align-items-center mb-2 gap-1">
                    <div className="circle Yellow "></div>
                    <span>Half Day</span>
                  </div>
                  <div className="col-6 col-sm-4 col-md-2 d-flex align-items-center mb-2 gap-1">
                    <div className="circle Green me-1"></div>
                    <span>Full Day</span>
                  </div>
                  <div className="col-6 col-sm-4 col-md-2 d-flex align-items-center mb-2 gap-1">
                    <div className="circle Blue me-2"></div>
                    <span>On Going </span>
                  </div>
                </div>
              </div>
            )}

            <div className="pagination-in-attendance">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            {paginatedData.length == 0 && (
              <div className="row">
                <h1 className="text-center mt-5" style={{ color: "#C7C8CC" }}>
                  {" "}
                  No Attendance Found
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>

      {modalData && <AttendenceModelPopup data={modalData} />}
    </>
  );
};

export default AttendenceAdmin;
